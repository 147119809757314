import styled, { css } from 'styled-components';
import { ILinkProps } from './interfaces';

const LINK_COLOR = (props: ILinkProps) => {
  const activeColor = props.activeColor || props.color;
  const activeMixin = css`
    color: ${activeColor};
    &::after {
      background-color: ${activeColor};
    }

    &:hover {
      &::after {
        background-color: ${activeColor};
      }
    }
  `;

  const hasColor = props.color;
  return hasColor
    ? css`
        color: ${props.color};
        ${props.active && activeMixin}
        ${props.activeClassName &&
          css`
            &.${props.activeClassName} {
              ${activeMixin}
            }
          `}
      `
    : css`
        &::after {
          background-color: transparent;
        }
      `;
};

export const LinkComponent = styled.a<ILinkProps>`
  ${LINK_COLOR};
  cursor: pointer;
  display: inline-block;
  font-size: ${(props) => props.fontSize};
  font-family: ${(props) => props.font};
  margin: 0 10px;
  text-decoration: none;

  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    margin-top: 2px;
  }
`;
