import * as React from 'react';
import { FlexWrapper, FlexAlignment, FlexWrap, FlexDirection, If, Map } from '../../helpers';
import { Text, Cursor } from '../Text';
import {
  FullscreenOverlay,
  MenuItemsPosition,
  MenuItemsContainer,
  MenuItemWrapper,
  MenuItemText,
  MenuHeader,
  MenuText,
  MenuIcon,
} from './components';
import { IBaseMenuItem, IMenuProps, IMenuItemProps } from './interfaces';

const MenuItem = <T extends IBaseMenuItem>({ item, onClick, disabled }: IMenuItemProps<T>) => {
  const handleClick = React.useMemo<React.MouseEventHandler<HTMLDivElement> | undefined>(() => {
    if (disabled) {
      return undefined;
    }

    return (evt) => {
      evt.stopPropagation();
      onClick(item);
    };
  }, [item, onClick, disabled]);

  return (
    <MenuItemWrapper onClick={handleClick} disabled={disabled}>
      <MenuItemText>{item.title}</MenuItemText>
    </MenuItemWrapper>
  );
};

export const Menu = <T extends IBaseMenuItem>({
  items,
  onMenuItemClick,
  title,
  hideArrow,
  inPopup,
  align: alignProp,
}: IMenuProps<T>) => {
  const align = inPopup ? 'left' : alignProp;
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const openMenu = React.useCallback(() => {
    setMenuOpen(true);
  }, []);
  const closeMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleItemClick = React.useCallback(
    (item: T) => {
      closeMenu();
      onMenuItemClick(item);
    },
    [closeMenu, onMenuItemClick]
  );

  return (
    <React.Fragment>
      <If cond={isMenuOpen}>
        <FullscreenOverlay onClick={closeMenu} />
      </If>
      <div>
        <FlexWrapper
          direction={FlexDirection.ROW}
          alignItems={FlexAlignment.START}
          wrap={FlexWrap.NOWRAP}
          onClick={openMenu}
        >
          <MenuHeader>
            <MenuText cursor={Cursor.POINTER} />
            <MenuText as="span" cursor={Cursor.POINTER}>
              <If cond={isMenuOpen && items.length > 0}>
                <MenuItemsPosition align={align}>
                  <MenuItemsContainer inPopup={inPopup} align={align}>
                    <Map items={items}>
                      {(item) => (
                        <MenuItem
                          key={item.id}
                          item={item}
                          onClick={handleItemClick}
                          disabled={item.disabled}
                        />
                      )}
                    </Map>
                  </MenuItemsContainer>
                </MenuItemsPosition>
              </If>
              <If cond={Boolean(title)}>
                <Text as="span" margin="-4px 0">
                  {title}
                </Text>
              </If>
              <If cond={!hideArrow}>
                <MenuIcon margin="-4px 6px 0" width="24px" height="24px" />
              </If>
            </MenuText>
          </MenuHeader>
        </FlexWrapper>
      </div>
    </React.Fragment>
  );
};
