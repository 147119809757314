import * as React from 'react';
import { EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  ImpersonationCookieModel,
  PageResouceTypeEnum,
  ProfilePlayerPersonalDataWithIdResponseModel,
} from '@usga/champadmin-api';
import { If, Expect } from '@usga/modules';
import { useHttpService } from './HttpServiceConsumer';
import { useAuth0Service } from '../appAuth';

/**
 * A simple hook for getting an impesonated profile (if exists) or `null`
 */
export function useImpersonationProfile() {
  const httpService = useHttpService();
  const [
    impersonationProfile,
    setImpersonationProfile,
  ] = React.useState<ImpersonationCookieModel | null>(null);
  React.useEffect(() => {
    const sub$ = httpService.observeImpersonation().subscribe(setImpersonationProfile);
    return () => {
      sub$.unsubscribe();
    };
  }, [httpService]);

  return impersonationProfile;
}

interface IImpersonationProps {
  children: (impersonationProfile: ImpersonationCookieModel | null) => React.ReactNode;
}

/**
 * A component that wraps the {@link useImpersonationProfile} and feeds children with this profile
 * @param param0 props
 */
export function ImpersonationProfile({ children }: IImpersonationProps) {
  const impersonationProfile = useImpersonationProfile();
  return <React.Fragment>{children(impersonationProfile)}</React.Fragment>;
}

/**
 * A component that renders children only if the impersonated profile exists (not null)
 * @param param0 props
 */
export function ImpersonationGuard({ children }: React.PropsWithChildren<object>) {
  const impersonationProfile = useImpersonationProfile();
  return <If cond={Boolean(impersonationProfile)}>{children}</If>;
}

interface IExpectImpersonationProfileProps {
  children: (impersonationProfile: ImpersonationCookieModel) => React.ReactNode;
}

/**
 * Expects the impersonated profile. Feeds children with the impersonated profile
 * or renders nothing otherwise
 * @param param0 props
 */
export function ExpectImpersonationProfile({ children }: IExpectImpersonationProfileProps) {
  return (
    <ImpersonationProfile>
      {(impersonationProfileOrNull) => (
        <Expect value={impersonationProfileOrNull}>
          {(impersonationProfile) => (
            <React.Fragment>{children(impersonationProfile)}</React.Fragment>
          )}
        </Expect>
      )}
    </ImpersonationProfile>
  );
}

function CheckImpersonationComponent(props: React.PropsWithChildren<object>) {
  const httpService = useHttpService();
  React.useEffect(() => {
    const sub$ = httpService.updateImpersonationStatus().subscribe();
    return () => {
      sub$.unsubscribe();
    };
  }, []);

  return <React.Fragment>{props.children}</React.Fragment>;
}

const RUN_IMPERSONATION_CHECK_FOR = [
  PageResouceTypeEnum.PlayerPage,
  PageResouceTypeEnum.ChampionshipEventPage,
];

export const ShouldRunImpersonationContext = React.createContext(false);

interface ICheckImpersonationProps {
  pageType: string;
}

function ShouldRunImpersonationCheckProvider(
  props: React.PropsWithChildren<ICheckImpersonationProps>
) {
  const shouldRunImpersonationCheck = React.useMemo(() => {
    return RUN_IMPERSONATION_CHECK_FOR.some((pageType) => {
      return pageType === props.pageType;
    });
  }, [props.pageType]);
  return (
    <ShouldRunImpersonationContext.Provider value={shouldRunImpersonationCheck}>
      {props.children}
    </ShouldRunImpersonationContext.Provider>
  );
}

/**
 * This component is checking the impersonated profile using the BE method call.
 * @param param0 props
 */
export function CheckImpersonation(props: React.PropsWithChildren<ICheckImpersonationProps>) {
  const auth = useAuth0Service();
  const httpService = useHttpService();

  React.useEffect(() => {
    if (!auth) {
      return;
    }
    auth.checkLogin().catch(() => {
      httpService.expireImpersonationCookie();
    });
  }, [auth]);

  return (
    <ShouldRunImpersonationCheckProvider pageType={props.pageType}>
      <ShouldRunImpersonationContext.Consumer>
        {(shouldRunImpersonationCheck) => {
          const Wrapper = shouldRunImpersonationCheck
            ? CheckImpersonationComponent
            : React.Fragment;
          return <Wrapper>{props.children}</Wrapper>;
        }}
      </ShouldRunImpersonationContext.Consumer>
    </ShouldRunImpersonationCheckProvider>
  );
}

/**
 * A simple hook for getting a manager's profile (if exists) or `null`
 */
export function useImpersonationManagerProfile() {
  const httpService = useHttpService();
  const [
    managerProfile,
    setManagerProfile,
  ] = React.useState<ProfilePlayerPersonalDataWithIdResponseModel | null>(null);

  React.useEffect(() => {
    const impersonatorSub$ = httpService
      .defaultApi('binChampadminPlayerProfileLinkImpersonatorGet')()
      .pipe(
        map(({ data }) => data),
        catchError((err) => {
          console.error(err);
          return EMPTY;
        })
      )
      .subscribe(setManagerProfile);
    return () => impersonatorSub$.unsubscribe();
  }, []);

  return managerProfile;
}
