import * as React from 'react';
import { BackButton, HiddenBlock, SCREEN } from '@usga/modules';
import { useHistory } from '../../services/ChangesTracker';
import { RouteResolver } from '../../services/route-resolver';

function resolveRelativeUrl(relativeUrl: string) {
  return RouteResolver.buildUrl(relativeUrl);
}

export default function BackToListButton() {
  const history = useHistory();
  const isOnConfirmationPage = history.location.pathname.match(/confirmation/i);

  // show back-to-list button in side rail on confirmation page for desktop only
  // for M/TP/TL breakpoints on confirmation this button will be added inside the main side
  // https://jira.ix.co/browse/USGACH-2319
  if (isOnConfirmationPage) {
    return (
      <HiddenBlock screen={SCREEN.L_DOWN}>
        <BackButton buildUrl={resolveRelativeUrl} />
      </HiddenBlock>
    );
  }

  return <BackButton buildUrl={resolveRelativeUrl} />;
}

export const REQUIRE_RENDERED_VIEWS_ID = 'HOME_APPIANCE_OR_REVIEW';
