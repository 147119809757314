import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, groupBy, mergeMap, tap } from 'rxjs/operators';
import { uniqBy } from 'lodash';
import { Color } from '../../helpers/Fonts';
import { QuickHit } from '../QuickHit';
import { Text } from '../Text';

const MAX_HEIGHT = '100px';
const SLIDE_IN_DURATION = 300;
const SLIDE_OUT_DURATION = 600;
const SHOW_DURATION = 8000;

export interface IBaseNotification {
  id: string;
  message: string;
  color?: Color;
}

interface INotification extends IBaseNotification {
  active: boolean;
}

export interface IAddNotification {
  (notification: IBaseNotification): void;
}

export const NotificationAddContext = React.createContext<IAddNotification>(() => {});

type IState = { notifications: INotification[] };

export type IWithNotificationProps = {
  showNotification(notification: IBaseNotification): void;
};

export const withNotification = <P extends IWithNotificationProps>(
  Component: React.ComponentType<P>
): ((props: Omit<P, keyof IWithNotificationProps>) => JSX.Element) => {
  return function WithNotification(props) {
    const add = React.useContext(NotificationAddContext);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const AnyComponent: React.ComponentType<any> = Component;
    return <AnyComponent {...props} showNotification={add} />;
  };
};

export function useNotification<T>(): IAddNotification {
  return React.useContext(NotificationAddContext);
}

export class NotificationContainer extends React.Component<{}, IState> {
  state: IState = {
    notifications: [],
  };

  private notification$ = new Subject<INotification>();

  private notificationSub?: Subscription;

  componentDidMount(): void {
    this.notificationSub = this.notification$
      .pipe(
        groupBy((n) => n.id),
        mergeMap((group) =>
          group.pipe(
            tap((n) => this.displayMessage(n)),
            debounceTime(SLIDE_IN_DURATION + SHOW_DURATION),
            tap((n) => this.hideMessage(n)),
            debounceTime(SLIDE_OUT_DURATION),
            tap((n) => this.deleteMessage(n))
          )
        )
      )
      .subscribe();
  }

  componentWillUnmount(): void {
    this.notificationSub?.unsubscribe();
  }

  render() {
    return (
      <NotificationAddContext.Provider value={this.addNotification}>
        <NotificationsWrapper>
          {this.state.notifications.map((n) => (
            <NotificationComponent key={n.id} {...n} />
          ))}
        </NotificationsWrapper>
        {this.props.children}
      </NotificationAddContext.Provider>
    );
  }

  private displayMessage(notification: INotification) {
    const currentNotifications = this.state.notifications;
    this.setState({
      notifications: uniqBy(currentNotifications.concat([notification]), (n) => n.id),
    });
  }

  private deleteMessage(notification: INotification) {
    const currentNotifications = this.state.notifications;
    this.setState({
      notifications: currentNotifications.filter((n) => notification.id !== n.id),
    });
  }

  private hideMessage(notification: INotification) {
    const currentNotifications = this.state.notifications;
    this.setState({
      notifications: currentNotifications.map((n) => {
        if (notification.id === n.id) {
          return {
            ...n,
            active: false,
          };
        }
        return n;
      }),
    });
  }

  private addNotification: IAddNotification = (notification: IBaseNotification) => {
    this.notification$.next({
      ...notification,
      active: true,
    });
  };
}

const NotificationComponent: React.FunctionComponent<INotification> = ({
  message,
  active,
  color,
}) => {
  return (
    <StyledNotificationComponent active={active} color={color}>
      <QuickHit borderColor={color}>
        <Text>{message}</Text>
      </QuickHit>
    </StyledNotificationComponent>
  );
};

const SlideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

const SlideOut = keyframes`
  0% {
    transform: translateX(0%);
    max-height: ${MAX_HEIGHT};
  }
  50% {
      transform: translateX(100%);
      max-height: ${MAX_HEIGHT};
  }
  100% {
      max-height: 0;
  }
`;

const slideInAnimation = css`
  ${SlideIn} ${SLIDE_IN_DURATION}ms linear
`;
const slideOutAnimation = css`
  ${SlideOut} ${SLIDE_OUT_DURATION}ms linear
`;

const StyledNotificationComponent = styled.div<{ active: boolean }>`
  transform: translateX(${({ active }) => (active ? '0' : '100%')});
  animation: ${({ active }) => (active ? slideInAnimation : slideOutAnimation)};
  color: ${({ color }) => color};
  display: inline-block;
  background: ${Color.WHITE};
  box-sizing: border-box;
  max-height: ${({ active }) => (active ? MAX_HEIGHT : '0')};
`;

const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 20px;
  z-index: 20;
`;
