import { BehaviorSubject, of, Subscription } from 'rxjs';
import { map, share, filter } from 'rxjs/operators';
import negate from 'lodash/negate';
import {
  ImpersonationCookieModel,
  ProfilePlayerPersonalDataWithIdResponseModel,
} from '@usga/champadmin-api';
import { IDisposable } from '@usga/modules';
import { ModernHTTPService } from './ModernHTTPService';
import { IAppAuthService } from '../appAuth';
import * as Utils from './utils';
import { impersonationCookie } from '../Cookies';
import { IHttpService } from './interfaces';

export class HttpService extends ModernHTTPService implements IHttpService, IDisposable {
  private readonly impersonatingProfile$ = new BehaviorSubject<ImpersonationCookieModel | null>(
    null
  );

  private readonly expirationCookie = impersonationCookie.getExpirableReactiveCookie();

  private readonly cookieRemoveCookieSubscription: Subscription;

  private readonly cookieSetSubscription: Subscription;

  private readonly cookieExpireSubscription: Subscription;

  private startImpersonationSubscription?: Subscription;

  private stopImpersonationSubscription?: Subscription;

  constructor(auth: IAppAuthService) {
    super(auth);
    this.cookieExpireSubscription = this.expirationCookie
      .observeExpiration()
      .subscribe(this.handleCookieRemove);
    this.cookieRemoveCookieSubscription = this.expirationCookie
      .observeChanges()
      .pipe(filter(negate(Boolean)))
      .subscribe(this.handleCookieRemove);
    this.cookieSetSubscription = this.expirationCookie
      .observeChanges()
      .pipe(filter(Boolean))
      .subscribe(this.handleCookieSet);
  }

  public dispose() {
    this.cookieRemoveCookieSubscription.unsubscribe();
    this.cookieExpireSubscription.unsubscribe();
    this.cookieSetSubscription.unsubscribe();
    this.startImpersonationSubscription?.unsubscribe();
    this.stopImpersonationSubscription?.unsubscribe();
    this.expirationCookie.dispose();
  }

  public startImpersonating(profile: ProfilePlayerPersonalDataWithIdResponseModel) {
    const startImpersonaton$ = this.defaultApi('binChampadminPlayerProfileLinkImpersonationPost')({
      profileId: profile.id,
    }).pipe(share());
    this.startImpersonationSubscription = startImpersonaton$
      .pipe(map(({ data }) => data))
      .subscribe((profile) => {
        this.impersonatingProfile$.next(profile);
      });
    return startImpersonaton$.pipe(map(Utils.suppressObservableResult));
  }

  public stopImpersonating() {
    const stopImpersonation$ = this.defaultApi(
      'binChampadminPlayerProfileLinkImpersonationStopPost'
    )().pipe(map(Utils.extractNavigationPath), share());
    this.stopImpersonationSubscription = stopImpersonation$.subscribe(() => {
      this.impersonatingProfile$.next(null);
    });
    return stopImpersonation$;
  }

  public observeImpersonation() {
    return this.impersonatingProfile$.asObservable();
  }

  public updateImpersonationStatus() {
    const updateImpersonationStatus$ = of(
      impersonationCookie.getExpirableReactiveCookie().getValue()
    ).pipe(map(Utils.extractImpersonationProfile), share());
    updateImpersonationStatus$.subscribe((profile) => {
      this.impersonatingProfile$.next(profile || null);
    });
    return updateImpersonationStatus$.pipe(map(Utils.suppressObservableResult));
  }

  public expireImpersonationCookie = () => {
    return of(impersonationCookie.getExpirableReactiveCookie().expire());
  };

  private handleCookieSet = () => {
    this.updateImpersonationStatus();
  };

  private handleCookieRemove = () => {
    this.impersonatingProfile$.next(null);
  };
}
