import * as React from 'react';
import moment from 'moment';
import {
  QualifyingSitePlayerFullModel,
  WaitListAlternateTypeEnum,
  StateTypeEnum,
} from '@usga/champadmin-api';
import { Text, Font, If } from '@usga/modules';
import { DATE_FORMAT } from './constants';
import { mapWaitlistAlternatesEnum } from '../../admin/shared/enum-mapping';
import { getQualifyingTwoDates } from '../../../services/qualifying-two-dates';

interface IItemTextProps extends QualifyingSitePlayerFullModel {
  alternateType?: WaitListAlternateTypeEnum;
  waitlisted?: boolean;
}

export function ItemText(props: IItemTextProps) {
  const { name: state, type } = props.state || {};
  const clubNameEntry = props.club
    .map((club) => club.name + (club.courseName ? ` (${club.courseName})` : ''))
    .join(' ');

  const waitlistedJsx = (
    <If cond={Boolean(props.waitlisted)}>
      <Text font={Font.BOLD}>Waitlisted:</Text>
    </If>
  );

  const alternatesJsx = (
    <If cond={Boolean(props.alternateType)}>
      <Text font={Font.BOLD}>{mapWaitlistAlternatesEnum(props.alternateType)}:</Text>
    </If>
  );

  const dateInfo = props.qualifyingDateIsTwoDays
    ? getQualifyingTwoDates(props.qualifyingDate, DATE_FORMAT)
    : moment(props.qualifyingDate).format(DATE_FORMAT);

  return (
    <React.Fragment>
      <If cond={type === StateTypeEnum.STATE}>
        <React.Fragment>
          {waitlistedJsx}
          {alternatesJsx} {clubNameEntry} {props.city}, {state ? `${state},` : ''} {dateInfo}
        </React.Fragment>
      </If>
      <If cond={type !== StateTypeEnum.STATE}>
        <React.Fragment>
          {waitlistedJsx}
          {alternatesJsx} {clubNameEntry}, {props.city}, {state ? `${state},` : ''}{' '}
          {props.country.name}, {dateInfo}
        </React.Fragment>
      </If>
    </React.Fragment>
  );
}
