const MOBILE_REGEXP = /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i;
const IOS_DEVICES_IDENTIFIERS = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
];

export const isMobile = (() => {
  let result: boolean | null = null;
  return () => {
    return result ?? (result = MOBILE_REGEXP.test(navigator.userAgent));
  };
})();

export const isIOS = (() => {
  let result: boolean | null = null;
  return () => {
    return (
      result ??
      (result =
        IOS_DEVICES_IDENTIFIERS.includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document))
    );
  };
})();

export const isClient = () => {
  try {
    return typeof window === 'object';
  } catch (e) {
    return false;
  }
};
