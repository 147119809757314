import * as React from 'react';
import { Image, Transformation, ICloudinaryImage } from 'cloudinary-react';
import styled from 'styled-components';
import { isClient } from '../../helpers';

export enum CropMode {
  FILL = 'fill',
  CROP = 'crop',
  THUMB = 'thumb',
  FIT = 'fit',
}

export enum GravityMode {
  CENTER = 'center',
  FACE = 'face',
}

const StyledImage = styled(Image)`
  object-fit: cover;
  max-width: 100%;
  font-size: 0;
`;

function getDpr() {
  if (!isClient()) {
    return 'auto';
  }

  return window.devicePixelRatio;
}

export function CloudinaryImage(props: ICloudinaryImage) {
  const dpr = getDpr();
  return (
    <StyledImage
      secure
      cloudName={props.cloudName ?? 'champ-admin'}
      publicId={props.id}
      className={props.className}
      alt={props.alt ?? props.id}
    >
      <Transformation
        crop={props.crop || 'fill'}
        gravity={props.gravity || 'center'}
        width={props.width}
        height={props.height}
        radius={props.radius}
        dpr={dpr}
      />
    </StyledImage>
  );
}
