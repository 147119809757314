import styled from 'styled-components';

export const RichEditorWrapper = styled.div`
  display: contents;
  @supports not (display: contents) {
    width: 100%;
  }
  .jodit-ui-group {
    width: 100%;
  }
`;
