import styled from 'styled-components';
import { ITextProps } from './interfaces';
import { Cursor } from './TextPreferences';
import { Color, Font } from '../../helpers/Fonts';
import { SCREEN } from '../../helpers/breakpoints';

export const Text = styled.div<ITextProps>`
  hyphens: ${({ hypenation }) => (hypenation ? 'auto' : 'none')};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin || '2px 0'};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ font }) => font};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  white-space: ${({ whiteSpace }) => whiteSpace};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textCase }) => textCase};
  line-height: ${({ lineHeight }) => lineHeight || '1.5'};
  cursor: ${({ cursor }) => cursor || Cursor.TEXT};
  text-decoration: ${({ textDecoration }) => textDecoration};
  word-break: ${({ wordBreak }) => wordBreak};
  text-overflow: ${({ textOverflow }) => textOverflow};
  display: ${({ display }) => display || 'inline-block'};

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  span {
    display: inline;
  }

  a {
    color: inherit;
  }

  &:empty {
    display: ${({ display }) => display || 'none'};
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  margin: 0 0 10px;
  color: ${Color.PRIMARY};
  font-family: ${Font.LIGHT};

  ${SCREEN.TP} {
    font-size: 36px;
    margin: 0 0 20px;
  }
`;

// TODO: Remove as
export const AdminTitle = styled(Text as React.ComponentType<React.ComponentProps<typeof Text>>)`
  font-size: ${({ fontSize }) => fontSize || '22px'};
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || '10px 0'};
`;
