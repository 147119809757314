import { WithdrawalReasonEnum } from '@usga/champadmin-api';

export const mapWithdrawalReasonEnum = (withdrawalreason: WithdrawalReasonEnum): string => {
  switch (withdrawalreason) {
    case WithdrawalReasonEnum.ILLNESS:
      return 'Illness';
    case WithdrawalReasonEnum.INJURY:
      return 'Injury';
    case WithdrawalReasonEnum.DUPLICATE:
      return 'Duplicate';
    case WithdrawalReasonEnum.GOLFCONFLICT:
      return 'Golf Conflict';
    case WithdrawalReasonEnum.BLACKLIST:
      return 'Performance Violation';
    case WithdrawalReasonEnum.WORKCONFLICT:
      return 'Work Conflict';
    case WithdrawalReasonEnum.SCHOOLCONFLICT:
      return 'School Conflict';
    case WithdrawalReasonEnum.OTHER:
      return 'Other';
    default:
      return '';
  }
};
