import * as React from 'react';
import styled from 'styled-components';
import { TooltipPosition } from './interfaces';
import { tooltipsByPosition } from './tooltips';

const TooltipWrapper = styled.div<{
  transitionDuration: string;
}>`
  transition-duration: ${({ transitionDuration }) => transitionDuration};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
`;

const RelativeWrapper = styled.div`
  position: relative;
  display: inline-flex;
  & ${TooltipWrapper} {
    opacity: 0;
  }

  &:hover ${TooltipWrapper} {
    opacity: 1;
  }
`;

interface ITooltipProps {
  transitionDuration?: string;
  position?: TooltipPosition;
  text: string;
}

export const Tooltip = ({
  text,
  children,
  position = 'bottom',
  transitionDuration = '.3s',
}: React.PropsWithChildren<ITooltipProps>) => {
  const TooltipByPosition = tooltipsByPosition[position];
  return (
    <RelativeWrapper>
      {children}
      <TooltipWrapper transitionDuration={transitionDuration}>
        <TooltipByPosition>{text}</TooltipByPosition>
      </TooltipWrapper>
    </RelativeWrapper>
  );
};
