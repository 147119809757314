export class RouteResolver {
  public static getAbsoluteUrl(href: string) {
    const a = document.createElement('a');
    a.href = href;
    return a.href;
  }

  public static get ext() {
    return window && /\.html$/i.test(window.location.pathname) ? '.html' : '';
  }

  public static get baseUrl() {
    const ctx = window && /\/content\/[^/]+\//i.exec(window.location.pathname);

    return ctx ? ctx[0] : '';
  }

  public static buildUrl(url: string) {
    return (this.baseUrl + url + this.ext).replace('//', '/');
  }
}
