import * as React from 'react';

interface IContextState {
  increment: (id: string) => void;
  decrement: (id: string) => void;
  getRenderedComponentsCount: (id: string) => number;
}

const RendersContext = React.createContext<IContextState>({
  increment: () => {},
  decrement: () => {},
  getRenderedComponentsCount: () => 0,
});

export const makeRenderSourceFor = (id: string) => ({
  children,
}: React.PropsWithChildren<object>) => {
  const { decrement, increment } = React.useContext(RendersContext);
  React.useEffect(() => {
    increment(id);
    return () => {
      decrement(id);
    };
  }, []);

  return <>{children}</>;
};

export const RendersProvider = ({ children }: React.PropsWithChildren<object>) => {
  const [componentsRendered, setComponentsRendered] = React.useState<Record<string, number>>({});
  const increment = React.useCallback(
    (id: string) => {
      const rendered = componentsRendered[id] ?? 0;
      setComponentsRendered({
        ...componentsRendered,
        [id]: rendered + 1,
      });
    },
    [componentsRendered]
  );

  const decrement = React.useCallback(
    (id: string) => {
      const rendered = componentsRendered[id] ?? 0;
      setComponentsRendered({
        ...componentsRendered,
        [id]: Math.max(rendered - 1, 0),
      });
    },
    [componentsRendered]
  );

  const getRenderedComponentsCount = React.useCallback(
    (id: string) => {
      return componentsRendered[id] ?? 0;
    },
    [componentsRendered]
  );

  const value = React.useMemo<IContextState>(
    () => ({
      decrement,
      increment,
      getRenderedComponentsCount,
    }),
    [increment, decrement, getRenderedComponentsCount]
  );

  return <RendersContext.Provider value={value}>{children}</RendersContext.Provider>;
};

export const useRendersCount = () => {
  const { getRenderedComponentsCount } = React.useContext(RendersContext);
  return getRenderedComponentsCount;
};

export interface IWIthRendersCount {
  getRenderedComponentsCount: IContextState['getRenderedComponentsCount'];
}

export const withRendersCount = <P extends {} = {}>(
  Component: React.ComponentType<P & IWIthRendersCount>
) => (props: P) => {
  const getRenderedComponentsCount = useRendersCount();

  return <Component {...props} getRenderedComponentsCount={getRenderedComponentsCount} />;
};
