import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';
import { Constants, IPageModel, ModelManager, PathUtils } from '@adobe/cq-spa-page-model-manager';
import { EMPTY, from, Observable, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { IUSGAApp } from '../helpers';
import styled from 'styled-components';
import { AEMUpdateRouteContext } from '@usga/modules';

export enum AEMEventType {
  /**
   * Event which indicates that the PageModelManager has been initialized
   */
  PAGE_MODEL_INIT = 'cq-pagemodel-init',

  /**
   * Event which indicates that the PageModelManager has loaded new content
   */
  PAGE_MODEL_LOADED = 'cq-pagemodel-loaded',

  /**
   * Event that indicates a request to update the page model
   */
  PAGE_MODEL_UPDATE = 'cq-pagemodel-update',

  /**
   * Event which indicates that ModelRouter has identified that model route has changed
   */
  PAGE_MODEL_ROUTE_CHANGED = 'cq-pagemodel-route-changed',
}

const TRANSITION_STR = '.3s';
const TRANSITION_NUM = 300;

const RouterWrapper = styled.div<{ isLoading: boolean }>`
  opacity: ${(props) => (props.isLoading ? '.6' : '1')};
  transition: opacity ${TRANSITION_STR};
`;

interface IProps extends RouteComponentProps {
  AppComponent: IUSGAApp;
  initialModel: IPageModel;
}
interface IState {
  isLoading: boolean;
  model: IPageModel;
}

class AppRouterComponent extends React.Component<IProps, IState> {
  private props$: Subject<IProps>;

  private model$: Observable<IPageModel>;

  private modelSubscription: Subscription;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      model: props.initialModel,
    };
    this.props$ = new Subject<IProps>();
    this.model$ = from(this.props$).pipe(
      startWith(props),
      map((p) => AppRouterComponent.getModelPath(p)),
      distinctUntilChanged(),
      skip(1),
      tap((path) => console.log(`Navigation to ${path}`)),
      tap(() => this.setState({ isLoading: true })),
      switchMap((path) => ModelManager.getData({ path })),
      catchError((err) => {
        // todo keep same url
        console.error(err);
        this.setState({
          isLoading: false,
        });
        return EMPTY;
      }),
      debounceTime(TRANSITION_NUM)
    );
    this.modelSubscription = this.model$.subscribe((model) => {
      this.setState({
        isLoading: false,
        model,
      });
      this.updateAEMRoute();
    });
  }

  public static getModelPath(props: IProps) {
    return PathUtils.sanitize(props.location.pathname);
  }

  updateAEMRoute = () => {
    PathUtils.dispatchGlobalCustomEvent(AEMEventType.PAGE_MODEL_ROUTE_CHANGED, {
      detail: {
        model: this.state.model,
      },
    });
  };

  componentDidUpdate() {
    this.props$.next(this.props);
  }

  render() {
    const App = this.props.AppComponent;
    const { model, isLoading } = this.state;
    return (
      <RouterWrapper isLoading={isLoading}>
        <AEMUpdateRouteContext.Provider value={this.updateAEMRoute}>
          <App
            cqChildren={model[Constants.CHILDREN_PROP]}
            cqItems={model[Constants.ITEMS_PROP]}
            cqItemsOrder={model[Constants.ITEMS_ORDER_PROP]}
            cqPath={model[Constants.PATH_PROP]}
            type={model[Constants.TYPE_PROP]}
            locationPathname={this.props.location.pathname}
            title={model['title']}
          />
        </AEMUpdateRouteContext.Provider>
      </RouterWrapper>
    );
  }

  componentWillUnmount(): void {
    this.modelSubscription.unsubscribe();
  }
}

export const AppRouter = withRouter(AppRouterComponent);
