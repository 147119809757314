import { areAllOfSingleStageType, getFinal, getLocal } from './utils';
import { ExemptionModel, QualifyingSitePlayerFullModel } from '@usga/champadmin-api';
import { makeUserChoicesItemComponent } from './UserChoicesItem';
import {
  ExemptionComponent,
  SectionalQualifyingItem,
  LocalQualifyingItem,
  SingleQualifyingItem,
} from './ItemsUI';

export const Exemptions = makeUserChoicesItemComponent<ExemptionModel>({
  finalTitle: 'Final exemption',
  firstTitle: 'Local exemption',
  singleTitle: 'Exemption',
  areAllOfSingleStageType,
  getFinal,
  getLocal,
  finalComponent: ExemptionComponent,
  firstComponent: ExemptionComponent,
  singleComponent: ExemptionComponent,
});

export const QualifyingSites = makeUserChoicesItemComponent<QualifyingSitePlayerFullModel>({
  finalTitle: 'Final qualifying choice',
  firstTitle: 'Local qualifying choice',
  singleTitle: 'Qualifying choice',
  areAllOfSingleStageType,
  getFinal,
  getLocal,
  finalComponent: SectionalQualifyingItem,
  firstComponent: LocalQualifyingItem,
  singleComponent: SingleQualifyingItem,
});
