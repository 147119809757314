import * as React from 'react';
import { useHistory } from 'react-router';
import { Button } from './index';

interface IBackButtonProps {
  buildUrl: (relativePath: string) => string;
}

export function BackButton({ buildUrl }: IBackButtonProps) {
  const history = useHistory();
  const onClick = React.useCallback(() => {
    const url = buildUrl('/player');

    history.push(url);
  }, [history, buildUrl]);

  return (
    <Button transparent onClick={onClick}>
      BACK TO LIST
    </Button>
  );
}
