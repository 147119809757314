import * as React from 'react';
import { FlexWrapper } from './FlexWrapper';
import { Text, Title } from '../components/Text';
import { Color, Font } from './Fonts';
import { TextAlign, TextCase } from '../../src/components/Text/TextPreferences';

import { Modal, PopupHeader } from '../components/Modal';
import { RichText } from '@usga/modules';

interface IProps {
  headerTitle?: string;
  champName?: string;
}

export function useOfficialModal({ headerTitle = 'Download reports', champName }: IProps) {
  const [isOpened, setIsOpened] = React.useState(false);
  const showModal = React.useCallback(() => setIsOpened(true), []);
  const hideModal = React.useCallback(() => setIsOpened(false), []);

  const DownloadModal = ({
    children,
    successMessage,
    errorMessage,
    trigger,
    headerMargin,
  }: {
    children: JSX.Element;
    successMessage?: string;
    errorMessage?: string;
    trigger?: (isOpen: boolean) => JSX.Element;
    headerMargin?: string;
  }) => (
    <Modal
      open={isOpened}
      onClose={hideModal}
      successMessage={successMessage}
      trigger={trigger}
      css={headerMargin ? `${PopupHeader} { margin: ${headerMargin} }` : undefined}
      headerContent={
        <FlexWrapper>
          <Title>{headerTitle}</Title>
          <Text color={Color.PRIMARY} font={Font.BOLD} margin={'0'}>
            {champName}
          </Text>
        </FlexWrapper>
      }
      footerContent={
        <FlexWrapper>
          <RichText
            color={Color.WHITE}
            background={Color.ALERT}
            textCase={TextCase.UPPERCASE}
            textAlign={TextAlign.CENTER}
            html={errorMessage ?? ''}
            margin={'0 0 -31px 0'}
            height={'60px'}
            padding={'3px 0 0 0'}
            lineHeight={'1.2'}
          />
        </FlexWrapper>
      }
    >
      {children}
    </Modal>
  );

  return {
    showModal,
    hideModal,
    DownloadModal,
  };
}
