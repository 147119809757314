import styled, { css } from 'styled-components';
import {
  FlexWrapper,
  SCREEN,
  FlexDirection,
  FlexAlignment,
  FlexWrap,
  LayoutTabs,
} from '@usga/modules';
import { IContainerProps } from './interfaces';

const DEFAULT_WIDTH = '1250px';

export const LayoutTwoColumnsWrapper = styled(FlexWrapper)<IContainerProps>`
  max-width: ${(props: IContainerProps) => props.width || DEFAULT_WIDTH};
  flex-direction: ${FlexDirection.ROW};
  align-items: ${FlexAlignment.START};
  flex-wrap: ${FlexWrap.NOWRAP};
  margin: 20px auto 0;
  padding: 0 10px;

  ${SCREEN.TL} {
    margin: 40px auto 0;
  }

  ${SCREEN.TL_DOWN} {
    flex-direction: ${FlexDirection.COLUMN};
    justify-content: ${FlexAlignment.START};
    flex-wrap: ${FlexWrap.WRAP};
  }
`;

const LDownHiddenMixin = css`
  ${SCREEN.TL_DOWN} {
    display: none;
  }
`;

export const LDownHiddenColumn = styled(FlexWrapper)<{ active: boolean; fullWidth: boolean }>`
  flex: ${({ fullWidth }) => (fullWidth ? '1' : 'initial')};
  justify-content: flex-start;
  ${({ active }) => (active ? '' : LDownHiddenMixin)};

  ${SCREEN.TL_DOWN} {
    flex: 1;
    width: 100%;
  }
`;

export const LayoutCustomTabs = styled(LayoutTabs)`
  margin-top: 20px;

  ${SCREEN.TL} {
    display: none;
  }
`;
