import {
  TwoColumnsLayoutModelMainSideEnum,
  TwoColumnsLayoutModelTabModeEnum,
} from '@usga/champadmin-api';
import { ITabsActivity } from './interfaces';

export function isVisibleLayoutSide(val: unknown): val is TwoColumnsLayoutModelMainSideEnum {
  return Object.values(TwoColumnsLayoutModelMainSideEnum).some((side) => side === val);
}

export function getTabActivity(
  activeTab: TwoColumnsLayoutModelMainSideEnum,
  tabMode: TwoColumnsLayoutModelTabModeEnum | undefined,
  mainSide: string
): ITabsActivity {
  switch (tabMode) {
    case TwoColumnsLayoutModelTabModeEnum.HIDDEN: {
      const isFirstTabActive = mainSide === TwoColumnsLayoutModelMainSideEnum.LEFT;
      const isSecondTabActive = mainSide === TwoColumnsLayoutModelMainSideEnum.RIGHT;

      return {
        showTabs: false,
        [TwoColumnsLayoutModelMainSideEnum.LEFT]: isFirstTabActive,
        [TwoColumnsLayoutModelMainSideEnum.RIGHT]: isSecondTabActive,
      };
    }
    case TwoColumnsLayoutModelTabModeEnum.MERGE: {
      return {
        showTabs: false,
        [TwoColumnsLayoutModelMainSideEnum.LEFT]: true,
        [TwoColumnsLayoutModelMainSideEnum.RIGHT]: true,
      };
    }
    case TwoColumnsLayoutModelTabModeEnum.SHOW: {
      return {
        showTabs: true,
        [TwoColumnsLayoutModelMainSideEnum.LEFT]:
          activeTab === TwoColumnsLayoutModelMainSideEnum.LEFT,
        [TwoColumnsLayoutModelMainSideEnum.RIGHT]:
          activeTab === TwoColumnsLayoutModelMainSideEnum.RIGHT,
      };
    }
    default: {
      console.warn('TabMode is not defined');

      return {
        showTabs: true,
        [TwoColumnsLayoutModelMainSideEnum.LEFT]:
          activeTab === TwoColumnsLayoutModelMainSideEnum.LEFT,
        [TwoColumnsLayoutModelMainSideEnum.RIGHT]:
          activeTab === TwoColumnsLayoutModelMainSideEnum.RIGHT,
      };
    }
  }
}
