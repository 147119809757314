import { NoteSubjectEnum } from '@usga/champadmin-api';

export const mapNoteSubject = (noteSubject: NoteSubjectEnum) => {
  switch (noteSubject) {
    case NoteSubjectEnum.AGA:
      return 'AGA';
    case NoteSubjectEnum.COMPLAINTS:
      return 'Complaints';
    case NoteSubjectEnum.GHINHANDICAP:
      return 'GHIN/Handicap';
    case NoteSubjectEnum.HOWTOAPPLY:
      return 'How to apply';
    case NoteSubjectEnum.MISDIRECT:
      return 'Misdirect';
    case NoteSubjectEnum.OTHER:
      return 'Other';
    case NoteSubjectEnum.PLAYERQUALIFYINGINFORMATION:
      return 'Player Qualifying Information';
    case NoteSubjectEnum.PUSHPULLCARTS:
      return 'Push/Pull Carts';
    case NoteSubjectEnum.QUALIFYINGSITES:
      return 'Qualifying Sites';
    case NoteSubjectEnum.REALLOTMENT:
      return 'Re-allotment';
    case NoteSubjectEnum.VOLUNTEERS:
      return 'Volunteers';
    default:
      return '';
  }
};
