import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  CheckboxCheckedGreyedIcon,
  CheckboxUncheckedGreyedIcon,
} from '../Icon';
import { FlexAlignment } from '../../helpers';

const Wrapper = styled.label<{ disabled?: boolean; alignItems?: FlexAlignment }>`
  display: inline-flex;
  align-items: ${({ alignItems }) => alignItems || FlexAlignment.CENTER};
  justify-content: ${FlexAlignment.CENTER};

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `};
`;

const HiddenCheckbox = styled.input`
  display: none;
`;

const preventPropagationHandler = (evt: React.MouseEvent<HTMLLabelElement>) => {
  evt.stopPropagation();
};

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  children?: React.ReactNode;
  alignItems?: FlexAlignment;
}

export const Checkbox = ({ checked, children, disabled, alignItems, ...rest }: IProps) => {
  const Icon = checked ? CheckboxCheckedIcon : CheckboxUncheckedIcon;
  const IconGreyed = checked ? CheckboxCheckedGreyedIcon : CheckboxUncheckedGreyedIcon;
  const IconToUse = disabled ? IconGreyed : Icon;

  return (
    <Wrapper onClick={preventPropagationHandler} disabled={disabled} alignItems={alignItems}>
      <IconToUse width={'24px'} height={'24px'} />
      <HiddenCheckbox type="checkbox" {...rest} checked={checked} disabled={disabled} />
      {children}
    </Wrapper>
  );
};
