import * as React from 'react';
import { GlobalStyles } from '../Fonts';
import '../Fonts/fonts.css';
import { IPreloadedState } from '../Loadable';
import { Frontload } from 'react-frontload';

export const PreloadedStateContext = React.createContext<IPreloadedState>({});

export function AppContext(props: { children: JSX.Element | JSX.Element[] }) {
  const { children } = props;
  return (
    <Frontload>
      <React.Fragment>
        <GlobalStyles />
        {children}
      </React.Fragment>
    </Frontload>
  );
}
