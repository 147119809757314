import * as React from 'react';
import ReactSelect from 'react-select';
import { Select, ISelectItem } from '../Select';

export type MultiselectType = string | number | boolean | object;

type IExtractProps<T> = T extends React.Component<infer U> ? U : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IMultiSelectProps<T> extends IExtractProps<ReactSelect<any>> {
  id: string;
  selectAllTitle: string;
  selected: Array<T>;
  isLoading?: boolean;
  isDisabled?: boolean;
  onSelectionChange: (selected: Array<T>) => void;
  items: Array<ISelectItem<T>>;
  width?: string;
  placeholder?: string;
  hideSelectAll?: boolean;
  dontResetSelectedOnItemsChange?: boolean;
}

const SELECT_ALL_ID = 'ID_SELECT_ALL';

const compareArrays = <T extends unknown>(set1: Array<T>, set2: Array<T>) => {
  return (
    set1.length === set2.length &&
    set1.every((set1Item) => {
      return set2.some((set2Item) => {
        return set1Item === set2Item;
      });
    })
  );
};

export const MultiSelect = <T extends object | string | number | boolean>({
  id,
  selected,
  onSelectionChange,
  items: itemsRaw,
  isLoading,
  isDisabled,
  selectAllTitle,
  width,
  placeholder,
  hideSelectAll,
  dontResetSelectedOnItemsChange,
  ...rest
}: IMultiSelectProps<T>) => {
  const items = React.useMemo<Array<ISelectItem<null | T>>>(() => {
    const areAllSelected = compareArrays(
      itemsRaw.map(({ value }) => value),
      selected
    );
    if (areAllSelected) {
      return itemsRaw;
    }
    const selectAllItem = hideSelectAll
      ? []
      : [{ title: selectAllTitle, id: SELECT_ALL_ID, value: null }];

    return [...selectAllItem, ...itemsRaw];
  }, [itemsRaw, selectAllTitle, selected]);

  React.useEffect(() => {
    if (!dontResetSelectedOnItemsChange) {
      onSelectionChange([]);
    }
  }, [itemsRaw, dontResetSelectedOnItemsChange]);

  const handleSelectionChange = React.useCallback(
    (changedItems: Array<T> | undefined) => {
      const items = changedItems ?? [];
      if (items.some((item) => item === null)) {
        onSelectionChange(itemsRaw.map((item) => item.value));
      } else {
        onSelectionChange(items);
      }
    },
    [onSelectionChange, itemsRaw]
  );

  return (
    <Select
      isMulti
      isClearable
      isSearchable
      id={id}
      customAutocomplete
      width={width}
      isLoading={isLoading}
      handleChange={handleSelectionChange}
      val={selected}
      items={items}
      placeholder={placeholder}
      disabled={isDisabled}
      {...rest}
    />
  );
};
