import * as React from 'react';
import styled from 'styled-components';
import DefaultPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
// eslint-disable-next-line no-restricted-imports
import {
  parsePhoneNumber as parse,
  getCountryCallingCode as getCode,
  isValidPhoneNumber as isValid,
} from 'react-phone-number-input';
import { createGlobalStyle } from 'styled-components';
import { Color } from '../../helpers/Fonts';
import { SCREEN } from '../../helpers';

type IPhoneStyleProps = { borderColor?: Color; className?: string };

export type IPhoneInputProps = PhoneInputProps &
  IPhoneStyleProps & {
    autoComplete?: string;
  };

export const parsePhoneNumber = parse;
export const getCountryCallingCode = getCode;
export const isValidPhoneNumber = isValid;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const styles = require('!!raw-loader!react-phone-input-2/lib/style.css');

const USA_ISO = 'us';

const PhoneInputStyles = createGlobalStyle(styles);
const FilteredDefaultPhoneInput = (props: IPhoneInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    borderColor = Color.INPUT_BORDER,
    placeholder = '1 (555) 555-5555',
    autoComplete,
    ...rest
  } = props;
  return (
    <DefaultPhoneInput
      {...rest}
      value={rest.value ?? ''}
      country={rest.country || USA_ISO}
      preferredCountries={[USA_ISO]}
      inputProps={{ autoComplete }}
      buttonStyle={{ borderColor, height: '38px' }}
      inputStyle={{ width: '100%', borderColor, height: '38px', borderRadius: '4px' }}
      placeholder={placeholder}
      containerClass={props.className}
    />
  );
};

const StyledFilteredPhoneInput = styled(FilteredDefaultPhoneInput)`
  ${SCREEN.TP} {
    .country-list {
      &--right {
        right: 0;
        transform: translateX(50%);
      }
    }
  }
`;

export const PhoneInput = (props: IPhoneInputProps) => {
  return (
    <>
      <PhoneInputStyles />
      <StyledFilteredPhoneInput
        {...props}
        autoFormat={false}
        country={props.country?.toString().toLowerCase()}
      />
    </>
  );
};
