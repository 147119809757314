import * as React from 'react';
import { MessagesList, If, Map, Lens } from '@usga/modules';
import { TWithNumber } from './interfaces';
import { Item } from './Items';

interface IOptions<T> {
  singleTitle: string;
  firstTitle: string;
  finalTitle: string;
  areAllOfSingleStageType: (items: Array<T>) => boolean;
  getLocal: (items: Array<T>) => Array<T>;
  getFinal: (items: Array<T>) => Array<T>;
  singleComponent: React.ComponentType<T>;
  firstComponent: React.ComponentType<T>;
  finalComponent: React.ComponentType<TWithNumber<T>>;
}

interface IProps<T> {
  items: Array<T>;
  waitlisted?: boolean;
}

export function makeUserChoicesItemComponent<T extends object>(params: IOptions<T>) {
  const {
    finalComponent: FinalComponent,
    firstComponent: FirstComponent,
    singleComponent: SingleComponent,
  } = params;
  return function UserChoicesItemComponent({ items, waitlisted }: IProps<T>) {
    return (
      <If cond={items.length > 0}>
        <Lens value={items} mapper={params.areAllOfSingleStageType}>
          {(allSingleStage) => (
            <React.Fragment>
              <If cond={allSingleStage}>
                <MessagesList>
                  <Item title={params.singleTitle}>
                    <Map items={items}>
                      {(item, index) => (
                        <SingleComponent key={index} {...item} waitlisted={waitlisted} />
                      )}
                    </Map>
                  </Item>
                </MessagesList>
              </If>
              <If cond={!allSingleStage}>
                <Lens value={items} mapper={params.getLocal}>
                  {(localExemption) => (
                    <Lens value={items} mapper={params.getFinal}>
                      {(finalExemptions) => (
                        <If cond={localExemption.length > 0 || finalExemptions.length > 0}>
                          <MessagesList>
                            <If cond={localExemption.length > 0}>
                              <Item title={params.firstTitle}>
                                <Map items={localExemption}>
                                  {(it, index) => (
                                    <FirstComponent waitlisted={waitlisted} {...it} key={index} />
                                  )}
                                </Map>
                              </Item>
                            </If>
                            <If cond={finalExemptions.length > 0}>
                              <Item title={params.finalTitle}>
                                <Map items={finalExemptions}>
                                  {(it, index) => (
                                    <FinalComponent
                                      waitlisted={waitlisted}
                                      place={index + 1}
                                      key={index}
                                      {...it}
                                    />
                                  )}
                                </Map>
                              </Item>
                            </If>
                          </MessagesList>
                        </If>
                      )}
                    </Lens>
                  )}
                </Lens>
              </If>
            </React.Fragment>
          )}
        </Lens>
      </If>
    );
  };
}
