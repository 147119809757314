import * as React from 'react';
import identity from 'lodash/identity';
import stubFalse from 'lodash/stubFalse';
import parseHtml from 'html-react-parser';
import sanitizeHTML, { IOptions } from 'sanitize-html';
import { isClient } from '../Detect';
import { IDOMUtils } from './IDOMUtils';

export class DefaultCleanHTMLOptions implements IOptions {
  public readonly allowedAttributes = false;
}

export class DOMUtils implements IDOMUtils {
  private readonly defaultCleanOptions = new DefaultCleanHTMLOptions();

  private readonly div = document.createElement('div');

  public stripTags(html: string) {
    this.div.innerHTML = html;
    const innerText = this.div.innerText;
    this.clearDiv();
    return innerText;
  }

  public isHTMLRenderable(html: string) {
    try {
      parseHtml(this.cleanHTML(html));
      return true;
    } catch (e) {
      return false;
    }
  }

  public cleanHTML<T extends IOptions>(html: string, options?: T) {
    try {
      return sanitizeHTML(html, options || this.defaultCleanOptions);
    } catch (e) {
      return '';
    }
  }

  private clearDiv() {
    this.div.innerHTML = '';
  }
}

const MOCK: IDOMUtils = { cleanHTML: identity, stripTags: identity, isHTMLRenderable: stubFalse };

export const DOMUtilsContext = React.createContext<IDOMUtils>(isClient() ? new DOMUtils() : MOCK);

export function useDOMUtils() {
  const domUtils = React.useContext(DOMUtilsContext);
  if (!domUtils) {
    throw new Error('No DOMUtils provider rendered');
  }
  return domUtils;
}
