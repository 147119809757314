import * as React from 'react';
import { IFlexWrapperProps } from './interfaces';
import styled from 'styled-components';
import { SCREEN } from '../breakpoints';

const StyledFlexWrapper = styled.div<IFlexWrapperProps>`
  display: flex;
  flex: ${({ flex }) => flex || '1 0 auto'};
  flex-basis: ${({ basis }) => basis || '100%'};
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  align-self: ${({ alignSelf }) => alignSelf || 'stretch'};
  flex-wrap: ${({ wrap }) => wrap || 'wrap'};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  background-color: ${({ backgroundColor }) => backgroundColor};
  visibility: ${({ invisible }) => (invisible ? 'hidden' : 'inherit')};
  z-index: ${({ zIndex }) => zIndex};
  border: ${({ border }) => border};
  position: relative;
  box-sizing: border-box;
  ${SCREEN.TP} {
    flex-basis: ${({ basis }) => basis || 'initial'};
    width: ${({ width }) => width};
    margin: ${({ margin }) => margin};
  }
`;

export function FlexWrapper(props: React.ComponentProps<typeof StyledFlexWrapper>) {
  return <StyledFlexWrapper {...props} />;
}
