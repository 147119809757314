import styled, { css } from 'styled-components';
import { Button, UploadButton } from '../../components/Button';
import { Color, Font } from '../../helpers/Fonts';

interface IProps {
  width?: string;
  height?: string;
  margin?: string;
  disabled?: boolean;
}

const adminButtonStyles = css<IProps>`
  box-sizing: border-box;
  border-radius: 2px;
  height: 36px;
  width: ${({ width }) => width || '130px'};
  height: ${({ height }) => height};
  background-color: ${Color.DARK_GREY};
  border: 1px solid ${({ disabled }) => (disabled ? Color.DARK_DEEP_GREY : Color.TEXT_GREY)};
  color: ${Color.BLACK};
  margin: ${(props) => props.margin || '10px'};
`;

export const AdminButton = styled(
  Button as React.ComponentType<React.ComponentProps<typeof Button>>
)`
  ${adminButtonStyles};
  font-family: ${Font.REGULAR};

  .path {
    stroke: ${Color.DEFAULT};
  }
`;

export const AdminButtonLabel = styled(UploadButton)`
  ${adminButtonStyles};
  line-height: 36px;
`;
