import * as React from 'react';
import constant from 'lodash/constant';
import { useAuth0Service } from '../appAuth';
import { HttpService } from './HttpService';
import { HttpServiceContext } from './contexts';

export function HttpServiceProvider(props: React.PropsWithChildren<object>) {
  const auth0 = useAuth0Service();
  if (!auth0) {
    throw new Error('Auth0 is not available');
  }

  const service = React.useMemo(constant(new HttpService(auth0)), [auth0]);

  React.useEffect(() => {
    return () => {
      service.dispose();
    };
  }, [service]);

  return (
    <HttpServiceContext.Provider value={service}>{props.children}</HttpServiceContext.Provider>
  );
}
