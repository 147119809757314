import * as React from 'react';

export function useDidUpdate(callback: () => void, deps: unknown[]) {
  const mountedRef = React.useRef(false);
  React.useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }

    return callback();
  }, deps);
}
