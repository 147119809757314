import * as React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { makeBubbledEventHandler } from '../BubbledEventHandler';

import ReactDateTimePicker, {
  IDateTimePickerProps,
  ITileClassNameParams,
} from 'react-datetime-picker/dist/entry.nostyle';
import ReactDateRangePicker, {
  IDateRangePickerProps,
  DateRange,
} from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle.js';
import { Color } from '../../helpers/Fonts';
import { StartOfDayProvider, EndOfDayProvider } from './DateProvider';
import { fromEDT, toEDT } from './EDTConverter';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const DateTimePickerStyles = require('!!raw-loader!react-datetime-picker/dist/DateTimePicker.css');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CalendarStyles = require('!!raw-loader!react-calendar/dist/Calendar.css');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ClockStyles = require('!!raw-loader!react-clock/dist/Clock.css');

// eslint-disable-next-line @typescript-eslint/no-var-requires
const DateRangePickerStyled = require('!!raw-loader!@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css');

export { IDateTimePickerProps } from 'react-datetime-picker/dist/entry.nostyle';
export { toEDT } from './EDTConverter';

export {
  IDateRangePickerProps,
  DateRange,
} from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle.js';

export * from './EDTConverter';

interface IWithBorderColor {
  borderColor?: Color;
}

interface IDateTimePickerComponentProps extends IDateTimePickerProps, IWithBorderColor {}
interface IDateRangePickerComponentProps extends IDateRangePickerProps, IWithBorderColor {
  onChange?: (dateRange?: DateRange) => void;
  isClearable?: boolean;
}

const CustomDatePickerStyles = css<IDateTimePickerComponentProps | IDateRangePickerComponentProps>`
  width: 100%;

  .react-datetime-picker,
  .react-daterange-picker {
    &__wrapper {
      border: 1px solid ${({ borderColor }) => borderColor || Color.INPUT_BORDER};
      flex-basis: 100%;
      height: 38px;
      border-radius: 4px;
      padding: 0 10px;
      box-sizing: border-box;

      input,
      select {
        outline: none;
        border: none;
      }
    }

    &__inputGroup {
      min-width: auto;

      &__input {
        padding-right: 2px;
      }
    }

    &__range-divider {
      display: inline-flex;
      align-items: center;
    }

    &__calendar {
      border: 1px solid ${Color.INPUT_BORDER};
      background-color: ${Color.WHITE};
      overflow: hidden;
      border-radius: 4px;
      margin: 2px auto;
      z-index: 10;

      .react-calendar {
        &__tile--active {
          &.active-start-date {
            background-color: #006edc;
          }
        }
      }
    }
  }
`;
const ACTIVE_START_DATE_CLASS = 'active-start-date';
const DateRangePickerComponent = styled(ReactDateRangePicker)<
  IDateRangePickerComponentProps & { tileClassName?: (params: ITileClassNameParams) => string }
>`
  ${css(DateTimePickerStyles)};
  ${css(CalendarStyles)};
  ${css(ClockStyles)};
  ${css(DateRangePickerStyled)};
  ${CustomDatePickerStyles};

  .react-datetime-picker,
  .react-daterange-picker {
    &__wrapper {
      padding: 0 4px;
    }

    &__inputGroup {
      min-width: auto;
    }

    &__range-divider {
      display: inline-flex;
      align-items: center;
    }
  }

  & .${ACTIVE_START_DATE_CLASS} {
    background-color: ${Color.CALENDAR_ACTIVE_START_DATE};
  }
`;
const DateTimePickerComponent = styled(ReactDateTimePicker)<
  IDateTimePickerComponentProps & { tileClassName?: (params: ITileClassNameParams) => string }
>`
  ${css(DateTimePickerStyles)};
  ${css(CalendarStyles)};
  ${css(ClockStyles)};
  ${CustomDatePickerStyles};

  & .${ACTIVE_START_DATE_CLASS} {
    background-color: ${Color.CALENDAR_ACTIVE_START_DATE};
  }
`;
/**
 * The code below is tricky.
 * The problem if this component is that is allows user to type any
 * number of leading zeros.
 * https://github.com/wojtekmaj/react-datetime-picker/issues/84
 * That problem is not solved for now, so we have to solve this on our side
 * The solution is to handle leading zero inputs for all
 * input controls in the DatePicker.
 * When the leading (not the following) zero is typed or pasted from the clipboard,
 * we change the value directly and dispatch a new event without this zero.
 * TODO: upgrade package when It is released and get rid of this hack.
 */
const timeNames = ['hour12', 'hour24', 'minute', 'second'];
const HandleInput = makeBubbledEventHandler('input');
const HandlePaste = makeBubbledEventHandler('paste');
export const DateTimePicker = (props: IDateTimePickerComponentProps) => {
  const [valueMemoized, setValueMemoized] = React.useState<IDateTimePickerComponentProps['value']>(
    props.value
  );

  React.useEffect(() => {
    setValueMemoized(props.value);
  }, [props.value]);

  React.useEffect(() => {
    if (!props.minDate) {
      return;
    }

    setValueMemoized(props.minDate);
  }, [props.minDate]);

  const handler = React.useCallback((event: Event) => {
    const timeChanged = timeNames.includes((event.target as HTMLInputElement)?.name);
    if (!event || !event.target || timeChanged) {
      return true;
    }

    const target: HTMLInputElement = (event.target as unknown) as HTMLInputElement;
    if (!target.value || Number.parseInt(target.value) !== 0) {
      return true;
    }

    const newEvent = new Event('input', { bubbles: true, cancelable: true });
    target.value = '';
    target.dispatchEvent(newEvent);
  }, []);
  const handleBlur = React.useCallback(() => {
    if (props.onChange && valueMemoized !== undefined) {
      props.onChange(valueMemoized as Date);
    }
    if (props.onBlur) {
      props.onBlur();
    }
  }, [valueMemoized, props.onBlur, props.onChange]);

  const handleChange = React.useCallback(
    (date: Date) => {
      setValueMemoized(date);
      if (props.onChange && !Number.isNaN(date?.getTime() ?? Number.NaN)) {
        props.onChange(date);
      }
    },
    [props.onChange]
  );

  const { activeStartDate, ...rest } = props;

  const tileClassNameFn = React.useCallback(
    ({ view, date }: ITileClassNameParams) => {
      if (!activeStartDate) {
        return '';
      }

      if (view !== 'month' || !activeStartDate) {
        return '';
      }

      const sameYear = activeStartDate.getFullYear() === date.getFullYear();
      const sameMonth = activeStartDate.getMonth() === date.getMonth();
      const sameDate = activeStartDate.getDate() === date.getDate();

      if (sameYear && sameMonth && sameDate) {
        return ACTIVE_START_DATE_CLASS;
      }

      return '';
    },
    [activeStartDate]
  );

  return (
    <HandlePaste handler={handler}>
      <HandleInput handler={handler}>
        <DateTimePickerComponent
          {...rest}
          activeStartDate={activeStartDate}
          tileClassName={tileClassNameFn}
          value={valueMemoized}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </HandleInput>
    </HandlePaste>
  );
};

DateTimePicker.defaultProps = {
  clearIcon: null,
  calendarIcon: null,
  disableClock: true,
  format: 'MM/dd/yyyy hh:mm:ss',
  locale: 'en-EN',
};

const mapEDTValue = (value: Date | Date[] | undefined): Date | Date[] | undefined => {
  if (value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(toEDT);
  } else {
    return toEDT(value);
  }
};

export const DateTimePickerEDT = ({ value, onChange, ...rest }: IDateTimePickerComponentProps) => {
  const [localValue, setLocalValue] = React.useState<Date | Array<Date> | undefined>(undefined);

  React.useEffect(() => {
    const newValue = mapEDTValue(value);
    setLocalValue(newValue);
  }, [value]);

  const handleChange = React.useMemo(() => {
    if (onChange === undefined) {
      return undefined;
    }

    return (value: Date) => {
      if (!value) {
        onChange(value);
        return;
      }

      onChange(fromEDT(value));
    };
  }, [onChange]);

  return <DateTimePicker {...rest} value={localValue} onChange={handleChange} />;
};

export const DateRangePicker = (props: IDateRangePickerComponentProps) => {
  const { isClearable, onChange, clearIcon, value } = props;

  const onRangeChange = React.useCallback(
    (dateRange?: DateRange | null) => {
      if (onChange) {
        const dateRangeValue = dateRange ?? undefined;
        onChange(dateRangeValue);
      }
    },
    [onChange]
  );

  const tileClassNameFn = React.useCallback(
    ({ view, date }: ITileClassNameParams) => {
      if (!props.activeStartDate) {
        return '';
      }

      if (view !== 'month' || !props.activeStartDate) {
        return '';
      }

      const sameYear = props.activeStartDate.getFullYear() === date.getFullYear();
      const sameMonth = props.activeStartDate.getMonth() === date.getMonth();
      const sameDate = props.activeStartDate.getDate() === date.getDate();

      if (sameYear && sameMonth && sameDate) {
        return ACTIVE_START_DATE_CLASS;
      }

      return '';
    },
    [props.activeStartDate]
  );

  const clearIconEntry = isClearable && !!value ? clearIcon : null;

  return (
    <DateRangePickerComponent
      {...props}
      onChange={onRangeChange}
      clearIcon={clearIconEntry}
      tileClassName={tileClassNameFn}
    />
  );
};

export const DateRangePickerUTC = ({
  value: utcValue,
  onChange,
  ...rest
}: IDateRangePickerComponentProps) => {
  const [localValue, setLocalValue] = React.useState<DateRange | undefined>(undefined);

  React.useEffect(() => {
    if (!utcValue) {
      setLocalValue(undefined);
      return;
    }

    const local = moment();
    const [startUTC, endUTC] = utcValue;
    const startOfDayProvider = new StartOfDayProvider(local);
    const endOfDayProvider = new EndOfDayProvider(local);

    const localDateRange: DateRange = [
      startOfDayProvider.getDateObjectLocal(startUTC),
      endOfDayProvider.getDateObjectLocal(endUTC),
    ];

    setLocalValue(localDateRange);
  }, [utcValue]);

  const handleChange = React.useMemo(() => {
    if (!onChange) {
      return undefined;
    }

    return (dateRange?: DateRange | null) => {
      if (!dateRange) {
        onChange(undefined);
        return;
      }

      const [startDate, endDate] = dateRange;
      const utc = moment.utc();
      const startOfDayProvider = new StartOfDayProvider(utc);
      const endOfDayProvider = new EndOfDayProvider(utc);

      const utcDateRange: DateRange = [
        startOfDayProvider.getDateObject(startDate),
        endOfDayProvider.getDateObject(endDate),
      ];
      onChange(utcDateRange);
    };
  }, [onChange]);

  return <DateRangePicker {...rest} value={localValue} onChange={handleChange} />;
};

/**
 * This component limits range input by a provided onChange value.
 * Component should match the interface
 * React.ComponentType<IDateRangePickerComponentProps>,
 * so any date range picker component should be acceptable.
 */
export function makeLimitedDateRangePicker(
  Component: React.ComponentType<IDateRangePickerComponentProps>,
  maxMSecs: number
): React.ComponentType<IDateRangePickerComponentProps> {
  const getLimitedDateRange = (dateRange: DateRange | undefined): DateRange | undefined => {
    if (!dateRange) {
      return dateRange;
    }

    const [from, to] = dateRange;
    if (!from || !to) {
      return dateRange;
    }

    return [new Date(Math.max(to.getTime() - maxMSecs, from.getTime())), to];
  };

  return function LimitedDateRangePicker({
    onChange,
    value,
    ...rest
  }: IDateRangePickerComponentProps) {
    const valueLimited = React.useMemo(() => {
      return getLimitedDateRange(value);
    }, [value]);
    const handleChange = React.useMemo(() => {
      if (!onChange) {
        return onChange;
      }
      return (dateRange: DateRange | undefined) => {
        onChange(getLimitedDateRange(dateRange));
      };
    }, [onChange]);
    return <Component value={valueLimited} onChange={handleChange} {...rest} />;
  };
}
