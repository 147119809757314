import styled, { css } from 'styled-components';

interface IProps {
  isOpened: boolean;
  className?: string;
  onClick: () => void;
}

export const Chevron = styled.div<IProps>`
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7px solid #000;
  ${(props) =>
    props.isOpened
      ? css`
          transform: rotate(0);
        `
      : css`
          transform: rotate(180deg);
        `}
  cursor: pointer;
  width: 0;
  height: 0;
`;
