import * as React from 'react';
import styled from 'styled-components';
import icon from '../../assets/pdf.png';
import { FlexAlignment, FlexDirection, FlexWrapper } from '../../helpers/FlexWrapper';
import { Color, Font } from '../../helpers/Fonts';
import { Cursor, RichText, Text } from '../Text';
import { DownloadIcon } from '../Icon';
import { SCREEN } from '../../helpers';

interface IProps {
  actionText?: string;
  title?: string;
  description?: string;
  url?: string;
  download: (url: string) => void;
}

const Container = styled(FlexWrapper)`
  border-bottom: 1px solid #f7f7f7;
  max-width: 1250px;
  margin: 0 auto;

  ${SCREEN.TP} {
    padding: 10px 50px 0;
  }
`;

const StyledIcon = styled.img`
  padding: 0 30px;

  ${SCREEN.TP_DOWN} {
    display: none;
  }
`;

const StyledMobileIcon = styled.img`
  display: none;

  height: 26px;
  width: 26px;

  ${SCREEN.TP_DOWN} {
    display: block;
  }
`;

const StyledRichText = styled(RichText)`
  p {
    margin: 0;
  }
`;

const IconsContainer = styled(FlexWrapper)`
  ${SCREEN.TP_DOWN} {
    justify-content: flex-start;
    height: 26px;
    margin: 0 10px 10px 10px;
  }
`;

const StyledDownloadIcon = styled(
  DownloadIcon as React.ComponentType<React.ComponentProps<typeof DownloadIcon>>
)`
  ${SCREEN.TP_DOWN} {
    margin: 0 0 0 10px;
  }
`;

const StyledTextContainer = styled(FlexWrapper)`
  flex-basis: 100%;

  ${SCREEN.TP_DOWN} {
    padding: 10px;
  }
`;

export function DownloadAttachment(props: IProps) {
  const download = React.useCallback(() => {
    if (!props.url) {
      return;
    }

    props.download(props.url);
  }, [props.url, props.download]);

  return (
    <Container width={'100%'} backgroundColor={Color.WHITE} direction={FlexDirection.ROW}>
      <FlexWrapper
        justifyContent={FlexAlignment.CENTER}
        alignItems={FlexAlignment.CENTER}
        flex={'0'}
        padding={'5px 0'}
      >
        <StyledIcon src={icon} />
      </FlexWrapper>
      <StyledTextContainer alignItems={FlexAlignment.START} padding={'20px 0 0'} flex={'1'}>
        <Text as={'h4'} width={'100%'} font={Font.BOLD}>
          {props.title}
        </Text>
        <StyledRichText html={props.description ?? ''} />
      </StyledTextContainer>
      <IconsContainer
        justifyContent={FlexAlignment.CENTER}
        alignItems={FlexAlignment.CENTER}
        flex={'0'}
        direction={FlexDirection.ROW}
      >
        <StyledMobileIcon src={icon} />
        <StyledDownloadIcon
          alt={props.actionText}
          onClick={download}
          width={'26px'}
          height={'26px'}
          margin={'42px'}
          cursor={Cursor.POINTER}
        />
      </IconsContainer>
    </Container>
  );
}
