import * as React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN, Button } from '@usga/modules';
import { UserChoices } from './UserChoices';

export enum SideRailGap {
  RIGHT = 'right',
  LEFT = 'left',
}

interface IProps {
  gap?: SideRailGap;
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<unknown>;
}

const SIDE_RAIL_GAP = (props: IProps) => css`
  ${props.gap ? `margin-${props.gap}: 40px` : ''}
`;

export const SideRailDiv = styled.div<IProps>`
  width: 100%;
  margin-bottom: 30px;

  ${Button} {
    display: block;
    margin: 10px auto;
  }

  ${SCREEN.TL} {
    ${SIDE_RAIL_GAP};
    max-width: 320px;
    min-width: 320px;
    margin-bottom: 0;
  }
`;

export const SideRail = ({ children, ...rest }: IProps) => {
  return (
    <SideRailDiv {...rest}>
      <UserChoices />
      {children}
    </SideRailDiv>
  );
};
