import * as React from 'react';
import styled from 'styled-components';

const Anchor = styled.a`
  visibility: hidden;
`;

interface IProps {
  disableScrollOnMount?: boolean;
}

export type ScrollToParameters = Parameters<
  typeof HTMLAnchorElement['prototype']['scrollIntoView']
>;

export class ScrollTo extends React.Component<IProps> {
  private static DEFAULT_SCROLL_PARAMS = {
    block: 'center',
  } as const;

  private anchor = React.createRef<HTMLAnchorElement>();

  componentDidMount(): void {
    if (!this.props.disableScrollOnMount) {
      this.scrollToAnchor(ScrollTo.DEFAULT_SCROLL_PARAMS);
    }
  }

  public render() {
    return (
      <>
        <Anchor ref={this.anchor} />
        {this.props.children}
      </>
    );
  }

  public getElement() {
    return this.anchor.current;
  }

  public scrollToAnchor(...args: ScrollToParameters) {
    if (this.anchor.current) {
      this.anchor.current.scrollIntoView(...args);
    }
  }
}

export function withScrollTo<T>(Component: React.ComponentType<T>) {
  return function WithScrollTo(props: T): JSX.Element {
    return (
      <ScrollTo>
        <Component {...props} />
      </ScrollTo>
    );
  };
}
