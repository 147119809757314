import React from 'react';
import noop from 'lodash/noop';
import identity from 'lodash/identity';
import constant from 'lodash/constant';
import * as Utils from './utils';
import { IJoditProps, IRichEditorProps } from './interfaces';
import { Expect } from '../../helpers/OnChange';
import { RichEditorWrapper } from './components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RichEditor(props: IRichEditorProps) {
  const handleOnBlurIfModified = Utils.useDirty(
    props.value,
    (props.onBlur || noop) as (value: React.FocusEvent<HTMLTextAreaElement> | string) => void
  );

  const [config, setConfig] = React.useState<object | null>(null);
  const componentRef = React.useRef<React.ComponentType<IJoditProps> | null>(null);

  React.useEffect(() => {
    setConfig(require('./config').config);
    componentRef.current = require('jodit-react').default;
  }, []);

  const JoditEditor = componentRef.current;

  const overridenConfig = React.useMemo(() => {
    return {
      ...(config ?? {}),
      ...(props.overrideConfig || identity)(config ?? {}),
    };
  }, [props.overrideConfig, config]);

  return (
    <Expect value={config}>
      {constant(
        <Expect value={JoditEditor}>
          {(JoditEditor) => (
            <RichEditorWrapper>
              <JoditEditor
                value={props.value}
                config={overridenConfig}
                onChange={props.onChange}
                onBlur={handleOnBlurIfModified}
              />
            </RichEditorWrapper>
          )}
        </Expect>
      )}
    </Expect>
  );
}
