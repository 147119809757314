import * as React from 'react';
import styled from 'styled-components';
import { AttachmentIcon } from '../Icon';
import { Color } from '../../helpers';

interface IRemoveHoverProps {
  opacity?: string;
}

const RemoveHover = styled.div<IRemoveHoverProps>`
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ opacity }) => (opacity === undefined ? '0.7' : opacity)};
  background: ${Color.WHITE};
  cursor: pointer;
`;

const FileStyled = styled.div`
  display: inline-flex;
  height: 100px;
  min-width: 100px;
  background-color: ${Color.DARK_GREY};
  border: 1px solid ${Color.DARK_DEEP_GREY};
  position: relative;
  margin: 0 10px 10px 0;

  &:hover ${RemoveHover} {
    visibility: visible;
  }
`;

const RemoveText = styled.span`
  font-size: 32px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
`;

const CaptionWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  max-width: 200px;
  overflow: hidden;
  margin-right: 13px;
  flex-direction: column;
`;

const Caption = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin: 0;
`;

const measureMagnitudes = ['b', 'kb', 'mb', 'gb', 'tb'];

/*
 * Please note, some OS'es shows files sizes in metric system,
 * so I decided to use IEC https://en.wikipedia.org/wiki/ISO/IEC_80000
 */
const formatFileSize = (bytesLength: number) => {
  let measureMagnitudeIndex = 0;
  let size = bytesLength;
  while (size >= 1024 && measureMagnitudes[measureMagnitudeIndex + 1]) {
    size /= 1024;
    ++measureMagnitudeIndex;
  }

  return {
    size: Math.round(size),
    measureMagnitude: measureMagnitudes[measureMagnitudeIndex],
  };
};

interface IBaseFileComponentProps {
  onRemoveClick: () => void;
  name: string;
  children?: string;
}

export const BaseFileComponent = ({
  children = '',
  name,
  onRemoveClick,
}: IBaseFileComponentProps) => (
  <FileStyled>
    <RemoveHover onClick={onRemoveClick}>
      <RemoveText>Remove</RemoveText>
    </RemoveHover>
    <IconWrapper>
      <AttachmentIcon width="48px" height="48px" color={Color.TEXT_GREY} />
    </IconWrapper>
    <CaptionWrapper>
      <Caption>{name}</Caption>
      <Caption>{children}</Caption>
    </CaptionWrapper>
  </FileStyled>
);

export interface IFileComponentProps {
  file: File;
  onClick: (file: File) => void;
}

export const DefaultFileComponent = ({ file, onClick }: IFileComponentProps) => {
  const handleClick = React.useCallback(() => {
    onClick(file);
  }, [onClick, file]);

  const { measureMagnitude, size } = formatFileSize(file.size);
  return (
    <BaseFileComponent name={file.name} onRemoveClick={handleClick}>
      {`(${size} ${measureMagnitude})`}
    </BaseFileComponent>
  );
};

const FileInputStyled = styled.label`
  display: inline-flex;
  height: 100px;
  width: 100px;
  justify-content: center;
  align-items: center;
  background-color: ${Color.DARK_GREY};
  border: 1px solid ${Color.DARK_DEEP_GREY};
  cursor: pointer;

  & input[type='file'] {
    display: none;
  }
`;

export const DefaultFileInputComponentWrapper = ({ children }: React.PropsWithChildren<object>) => (
  <FileInputStyled>
    <AttachmentIcon width="48px" height="48px" color={Color.TEXT_GREY} />
    {children}
  </FileInputStyled>
);

const ExistingFileButton = styled.div`
  height: 100%;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  color: ${Color.BLACK};

  &:hover {
    background-color: ${Color.DARK_DEEP_GREY};
  }
`;

const ButtonCaption = styled.span`
  transform: rotate(-45deg);
`;

interface IExistingFileComponentProps {
  onRemoveClick: () => void;
  onDownloadClick: () => void;
  name: string;
  children?: string;
}

export const ExistingFileComponent = ({
  children = '',
  name,
  onRemoveClick,
  onDownloadClick,
}: IExistingFileComponentProps) => (
  <FileStyled>
    <RemoveHover opacity="0.9">
      <ExistingFileButton onClick={onRemoveClick}>
        <ButtonCaption>Remove</ButtonCaption>
      </ExistingFileButton>
      <ExistingFileButton onClick={onDownloadClick}>
        <ButtonCaption>Download</ButtonCaption>
      </ExistingFileButton>
    </RemoveHover>
    <IconWrapper>
      <AttachmentIcon width="48px" height="48px" color={Color.TEXT_GREY} />
    </IconWrapper>
    <CaptionWrapper>
      <Caption>{name}</Caption>
      <Caption>{children}</Caption>
    </CaptionWrapper>
  </FileStyled>
);
