import * as React from 'react';
import { getRandomHash } from './Hash';

export function useRefreshKey() {
  const [refreshKey, setRefreshKey] = React.useState(getRandomHash());
  const updateRefreshKey = React.useCallback(() => {
    setRefreshKey(getRandomHash());
  }, []);

  return [refreshKey, updateRefreshKey] as const;
}
