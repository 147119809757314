import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { IDisposable } from '../Disposable';
import { ReactiveCookie } from './ReactiveCookie';

/**
 * Cookie observer. Observes cookie expiration.
 * Please note, It can't track changes done in the another place:
 * all changes should be done within this class
 */
export class ExpirableReactiveCookie extends ReactiveCookie implements IDisposable {
  private readonly expirationSubject: BehaviorSubject<boolean>;

  private readonly changeSubscription: Subscription;

  constructor(name: string) {
    super(name);
    this.expirationSubject = new BehaviorSubject(this.getValue() === null);
    this.changeSubscription = this.changeSubject
      .asObservable()
      .pipe(distinctUntilChanged())
      .subscribe(this.handleChange);
  }

  public observeExpiration() {
    return this.expirationSubject.asObservable();
  }

  public isExpired() {
    return this.expirationSubject.value;
  }

  public expire() {
    this.setValue(null);
  }

  public dispose() {
    return this.changeSubscription.unsubscribe();
  }

  private handleChange = (val: string | null) => {
    this.expirationSubject.next(val === null);
  };
}
