import * as React from 'react';

function defaultMapper<T>(val: T) {
  return val;
}

interface IOnChangeProps<M, K extends keyof M> {
  model: M;
  set: (model: M) => void;
  change: K;
  map?: (val: M) => M;
  children: (callback: (newValue: M[K]) => void) => React.ReactNode;
}

export const OnChange = <M extends object, K extends keyof M>({
  change,
  children,
  model,
  set,
  map,
}: IOnChangeProps<M, K>) => {
  const onChange = React.useCallback(
    (newValue: M[K]) => {
      const newModel = (map ?? defaultMapper)({ ...model, [change]: newValue });
      set(newModel);
    },
    [set, model, change, map]
  );

  return <>{children(onChange)}</>;
};

interface IOnRemoveProps<M, K extends keyof M> {
  set: React.Dispatch<React.SetStateAction<M>>;
  remove: K;
  children: (callback: () => void) => React.ReactNode;
  map?: (val: M) => M;
}

export const OnRemove = <M extends object, K extends keyof M>({
  remove,
  children,
  set,
  map,
}: IOnRemoveProps<M, K>) => {
  const onChange = React.useCallback(() => {
    set((model) => {
      const newModel = {
        ...model,
        [remove]: undefined,
      };
      return (map ?? defaultMapper)(newModel);
    });
  }, [set, remove, map]);

  return <>{children(onChange)}</>;
};

interface IMapCallbackValueProps<I extends unknown, O extends unknown> {
  onChange: (val: O) => void;
  map: (val: I) => O;
  children: (callback: (value: I) => void) => React.ReactNode;
}

export const MapCallbackValue = <O extends unknown, I extends unknown>({
  onChange,
  children,
  map,
}: IMapCallbackValueProps<O, I>) => {
  const newCallback = React.useCallback(
    (val: O) => {
      onChange(map(val));
    },
    [onChange, map]
  );
  return <>{children(newCallback)}</>;
};

interface IExpectProps<T extends unknown> {
  value: T | undefined | null;
  children: (value: T) => React.ReactNode;
}

export const Expect = <T extends unknown>({ children, value }: IExpectProps<T>) => {
  if (value !== null && value !== undefined) {
    return <React.Fragment>{children(value)}</React.Fragment>;
  }

  return <React.Fragment />;
};

interface IIfProps {
  cond: boolean;
  children: React.ReactNode | (() => React.ReactNode);
}

export function If({ cond, children }: IIfProps) {
  if (!cond) {
    return <React.Fragment />;
  }

  const inner = typeof children === 'function' ? children() : children;

  return <React.Fragment>{inner}</React.Fragment>;
}

interface IMapProps<T> {
  items: Array<T>;
  children: (item: T, index: number, items: Array<T>) => React.ReactNode;
}

export function Map<T>(props: IMapProps<T>) {
  return <React.Fragment>{props.items.map(props.children)}</React.Fragment>;
}
