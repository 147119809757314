import { MessageTypeEnum } from '@usga/champadmin-api';

export function mapMessageType(messageType: MessageTypeEnum) {
  switch (messageType) {
    case MessageTypeEnum.CUSTOM:
      return 'Custom Message';
    case MessageTypeEnum.EXEMPTION:
      return 'Exemption Message';
    case MessageTypeEnum.STROKEPOLICY:
      return 'Stroke Policy Message';
    default:
      return '';
  }
}
