import { IScheduler, SchedulerTask } from './interfaces';

export class TimerScheduler implements IScheduler {
  private timerId: number | undefined = undefined;

  constructor(private readonly timeout: number) {}

  public schedule(fn: SchedulerTask) {
    window.clearTimeout(this.timerId);
    this.timerId = window.setTimeout(fn, this.timeout);
    return () => {
      return window.clearTimeout(this.timerId);
    };
  }
}
