import momentTimezone from 'moment-timezone';

export const NEW_YORK_TIMEZONE = 'America/New_York';
export const EDT_DATE_TIME = 'h:mm A [EDT] MMMM D, YYYY';

const getUSTzIdentifier = (date: Date) => {
  const isDST = momentTimezone(date)
    .tz(NEW_YORK_TIMEZONE)
    .isDST();
  return isDST ? 'EDT' : 'EST';
};

export function toEDT(date: Date) {
  return new Date(date.toLocaleString('en-US', { timeZone: NEW_YORK_TIMEZONE }));
}

export function fromEDT(date: Date) {
  return new Date(`${date.toLocaleString('en-US')} ${getUSTzIdentifier(date)}`);
}
