import * as React from 'react';
import debounce from 'lodash/debounce';
import { IOverlayProps } from './interfaces';
import * as Constants from './constants';
import { OverlayAbsolute, OverlayFade, OverlayWrapper } from './components';
import { If, Expect } from '../../helpers';

export function Overlay(props: React.PropsWithChildren<IOverlayProps>) {
  const [show, setShow] = React.useState(props.active);
  const changeVisible = React.useMemo(() => debounce(setShow, Constants.TRANSITION_NUM), []);
  React.useEffect(() => {
    if (props.active) {
      setShow(true);
    }
    changeVisible(props.active);
  }, [props.active]);

  return (
    <OverlayWrapper>
      <OverlayFade active={props.active}>
        {props.children}
        <If cond={show}>
          <OverlayAbsolute />
        </If>
      </OverlayFade>
      <If cond={show}>
        <Expect value={props.OverlayLayer}>{(OverlayLayer) => <OverlayLayer />}</Expect>
      </If>
    </OverlayWrapper>
  );
}
