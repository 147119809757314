import * as EventTypes from './message-bus-types';

export function isApplicationEvent(
  message: EventTypes.MessageBusEvents
): message is EventTypes.IApplicationEvent {
  return message.type === 'newApplicationResponseModel';
}

export function isPartnerApplicationEvent(
  message: EventTypes.MessageBusEvents
): message is EventTypes.IPartnerApplicationEvent {
  return message.type === 'partnerChoice';
}

export function isEligibilityEvent(
  message: EventTypes.MessageBusEvents
): message is EventTypes.IEligibilityEvent {
  return message.type === 'newEligibilityModel';
}

export function isOfficialsQualifyingSiteChampionhipChangeEvent(
  message: EventTypes.MessageBusEvents
): message is EventTypes.IOfficialsQualifyingSiteChampionhipChangeEvent {
  return message.type === 'newOfficialsQualifyingSiteChampionshipId';
}

export function isOfficialsQualifyingSiteContextChangeEvent(
  message: EventTypes.MessageBusEvents
): message is EventTypes.IOfficialsQualifyingSiteContextChangeEvent {
  return message.type === 'newIOfficialsQualifyingSiteContext';
}
