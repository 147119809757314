import * as React from 'react';
import { css } from 'styled-components';
import { ISelectItem, MultiSelect, MultiselectType } from '../../form';
import { FilterWrapper } from './FilterWrapper';
import { IFilterComponentProps } from './FilterComponentProps';
import { getRandomHash } from '../../helpers/RandomHash';

export const multiSelectStyles = {
  container: () => css`
    flex: 1;
    align-self: flex-start;
    min-width: 185px;
    width: auto;
  `,
  control: () => css`
    display: flex;
    flex-direction: row;
  `,
};

interface IProps<T> extends IFilterComponentProps<Array<T>> {
  items: Array<ISelectItem<T>>;
  selectAllTitle?: string;
  hideSelectAll?: boolean;
  width?: string;
  isLoading?: boolean;
  styles?: object;
  isDisabled?: boolean;
}
const DEFAULT_SELECT_ALL_CAPTION = 'Select All';
export const MultiSelectFilter = <T extends MultiselectType>(props: IProps<T>) => {
  const { onValueChange, title, value, items, onRemoveClick } = props;
  const selectIdRef = React.useRef(`multi-filter-${getRandomHash()}`);
  const expectedValues = React.useMemo(() => {
    return value.filter((currentSelectedValue) => {
      return items.some((currentItem) => {
        return currentItem.value === currentSelectedValue;
      });
    });
  }, [value, items]);
  const autoWidth = onRemoveClick ? '200px' : '224px';
  return (
    <FilterWrapper onRemoveClick={onRemoveClick} disableAutoWidth={typeof props.width === 'string'}>
      <MultiSelect
        width={props.width ?? autoWidth}
        id={selectIdRef.current}
        items={items}
        onSelectionChange={onValueChange}
        selectAllTitle={props.selectAllTitle ? props.selectAllTitle : DEFAULT_SELECT_ALL_CAPTION}
        selected={expectedValues}
        placeholder={title}
        hideSelectAll={props.hideSelectAll}
        isLoading={props.isLoading}
        isDisabled={props.isDisabled}
        disabled={props.disabled}
        styles={props.styles}
      />
    </FilterWrapper>
  );
};
