import * as React from 'react';
import { CountryModel, StateModel, StateTypeEnum } from '@usga/champadmin-api';
import { useHttpService } from '../http';
import { EMPTY, forkJoin } from 'rxjs';
import { Color, ISelectItem, useNotification } from '@usga/modules';
import { catchError } from 'rxjs/operators';

export interface ICountryStateProps {
  countries: CountryModel[];
  states: StateModel[];
  getStatesSelectItems: (countryId?: string) => ISelectItem[];
  getCountriesSelectItems: () => ISelectItem[];
  getCountryById: (countryId: string) => CountryModel | undefined;
  getStateById: (stateId: string) => StateModel | undefined;
  getCountryByCodeIOC: (countryCode?: string) => CountryModel | undefined;
  getCountryByCodeISO: (coutrnyCode?: string) => CountryModel | undefined;
  getStateByCode: (stateCode?: string) => StateModel | undefined;
  getUSA: () => CountryModel | undefined;
  getCanada: () => CountryModel | undefined;
  getRussia: () => CountryModel | undefined;
  normalizeLocation: (...args: (string | undefined)[]) => string;
}

export const CountryStateContext = React.createContext<ICountryStateProps | null>(null);

// IOC codes
export enum Countries {
  USA = 'USA',
  CANADA = 'CAN',
  RUSSIA = 'RUS',
}

export const TEXAS_ISO = 'TX';

export const CountryStateProvider: React.FC = ({ children }) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [countries, setCountries] = React.useState<CountryModel[]>([]);
  const [states, setStates] = React.useState<StateModel[]>([]);
  const httpService = useHttpService();
  const showNotification = useNotification();
  // const auth0 = useAuth0Service();

  React.useEffect(() => {
    const country$ = httpService.defaultApi('binChampadminCommonCountryListGet', {
      skipAuthCheck: true,
    })(-1, 0);
    const state$ = httpService.defaultApi('binChampadminCommonStateListGet', {
      skipAuthCheck: true,
    })(StateTypeEnum.STATE, -1, 0);
    const province$ = httpService.defaultApi('binChampadminCommonStateListGet', {
      skipAuthCheck: true,
    })(StateTypeEnum.PROVINCE, -1, 0);

    const sub = forkJoin([country$, state$, province$])
      .pipe(
        catchError(() => {
          showNotification({
            id: 'country_state_error',
            message: 'Something went wrong with country-state request',
            color: Color.ALERT,
          });

          return EMPTY;
        })
      )
      .subscribe(([countriesRes, statesRes, provincesRes]) => {
        const USA = countriesRes.data.items.find((country) => country.codeIOC === Countries.USA);
        const otherCountries = countriesRes.data.items.filter((country) => country.id !== USA?.id);

        const sortedCountries = USA ? [USA, ...otherCountries] : otherCountries;

        setCountries(sortedCountries);
        setStates([...statesRes.data.items, ...provincesRes.data.items]);
        setIsLoaded(true);
      });

    return () => sub.unsubscribe();
  }, []);

  const getStatesSelectItems = React.useCallback(
    (countryId?: string): ISelectItem[] => {
      const countryStates = states.filter((state) => state.countryId === Number(countryId));

      return countryStates
        ? countryStates.map((state) => ({
            id: state.codeISO,
            title: state.name,
            value: String(state.id),
          }))
        : [];
    },
    [states]
  );

  const getCountriesSelectItems = React.useCallback((): ISelectItem[] => {
    return countries.map((country) => ({
      id: country.codeIOC,
      title: country.name,
      value: String(country.id),
    }));
  }, [countries]);

  const getCountryById = React.useCallback(
    (countryId?: string) => {
      if (!countryId) {
        return;
      }

      return countries.find(({ id }) => id === Number(countryId));
    },
    [countries]
  );

  const getCountryByCodeIOC = React.useCallback(
    (codeIOCToFind?: string) => {
      if (!codeIOCToFind) {
        return undefined;
      }

      return countries.find(({ codeIOC }) => codeIOC === codeIOCToFind);
    },
    [countries]
  );

  const getCountryByCodeISO = React.useCallback(
    (codeISOtoFind?: string) => {
      if (!codeISOtoFind) {
        return undefined;
      }

      return countries.find(({ codeISO }) => codeISO === codeISOtoFind);
    },
    [countries]
  );

  const getStateById = React.useCallback(
    (stateId?: string) => {
      if (!stateId) {
        return;
      }

      return states.find((state) => state.id === Number(stateId));
    },
    [states]
  );

  const getStateByCode = React.useCallback(
    (stateCode?: string) => {
      if (!stateCode) {
        return;
      }

      return states.find(({ codeISO }) => codeISO === stateCode);
    },
    [states]
  );

  const getUSA = React.useCallback(() => {
    return countries.find((country) => country.codeIOC === Countries.USA);
  }, [countries]);

  const getRussia = React.useCallback(() => {
    return countries.find((country) => country.codeIOC === Countries.RUSSIA);
  }, [countries]);

  const getCanada = React.useCallback(() => {
    return countries.find((country) => country.codeIOC === Countries.CANADA);
  }, [countries]);

  const normalizeLocation = React.useCallback(
    (...args: (string | undefined)[]): string => args.filter((e) => !!e).join(', '),
    []
  );

  const value = React.useMemo(
    () => ({
      countries,
      states,
      getStatesSelectItems,
      getCountriesSelectItems,
      getCountryById,
      getStateById,
      normalizeLocation,
      getUSA,
      getRussia,
      getCanada,
      getCountryByCodeIOC,
      getCountryByCodeISO,
      getStateByCode,
    }),
    [countries, states]
  );

  if (!isLoaded) {
    //isLoaded
    return null;
  }

  return <CountryStateContext.Provider value={value}>{children}</CountryStateContext.Provider>;
};

export const withCountryState = <P extends ICountryStateProps>(
  Component: React.ComponentType<P>
): ((props: Omit<P, keyof ICountryStateProps>) => JSX.Element) => {
  return function WithCountryState(props) {
    const ctx = React.useContext(CountryStateContext);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const AnyComponent: React.ComponentType<any> = Component;
    return <AnyComponent {...props} {...ctx} />;
  };
};

export function useCountryState<T>(): ICountryStateProps {
  const ctx = React.useContext(CountryStateContext);

  if (!ctx) {
    throw new Error('Country State context was not loaded');
  }

  return ctx;
}
