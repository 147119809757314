import moment from 'moment';

const FORMAT = 'MM/DD/YYYY';

export function getQualifyingTwoDatesParameters(firstValue: string, outFormat = FORMAT) {
  if (!moment(firstValue).isValid()) {
    return null;
  }

  const firstDate = moment.utc(new Date(firstValue)).format(outFormat);
  const secondDate = moment
    .utc(new Date(firstValue))
    .add(1, 'days')
    .format(outFormat);
  return { firstDate, secondDate };
}

export function getQualifyingTwoDates(firstValue: string, outFormat = FORMAT) {
  const dates = getQualifyingTwoDatesParameters(firstValue, outFormat);
  if (dates === null) {
    return '';
  }

  return `${dates.firstDate} and ${dates.secondDate}`;
}
