import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { BehaviorSubject, EMPTY, from, Observable, timer } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { IAppAuthService, IAuthResult } from '../appAuth';
import { AdminApi, DefaultApi, PlayerApi } from '@usga/champadmin-api';
import { LegacyHttpService } from './LegacyHttpService';
import {
  IApiCaller,
  IRequestParams,
  IArguments,
  IAxiosResponseType,
  ILegacyAPI,
  IModernAPI,
} from './interfaces';

export abstract class ModernHTTPService extends LegacyHttpService
  implements ILegacyAPI, IModernAPI {
  public defaultApi: IApiCaller<DefaultApi>;

  public adminApi: IApiCaller<AdminApi>;

  public playerApi: IApiCaller<PlayerApi>;

  private authResult: BehaviorSubject<IAuthResult>;

  private basePath = '';

  constructor(auth: IAppAuthService) {
    super(auth);
    this.authResult = this.auth.authResult;
    this.defaultApi = this.wrapApi(DefaultApi);
    this.adminApi = this.wrapApi(AdminApi);
    this.playerApi = this.wrapApi(PlayerApi);
  }

  private wrap<T extends Function>(
    request: T,
    requestParams: IRequestParams
  ): (...args: IArguments<T>) => Observable<AxiosResponse<IAxiosResponseType<T>>> {
    return (...args: IArguments<T>) => {
      return this.executeRequest(request, args, requestParams);
    };
  }

  private createRequest<T extends Function>(request: T, args: IArguments<T>, result: IAuthResult) {
    return new Observable<AxiosResponse<IAxiosResponseType<T>>>((sub) => {
      const source = axios.CancelToken.source();
      const cancelToken = source.token;
      const idToken = result.idToken;
      const authHeaders = { Authorization: 'Bearer ' + idToken };
      const headers: Record<string, string> = {
        ...authHeaders,
        'Content-Type': 'application/json',
      };
      const requestEnv = {
        basePath: this.basePath,
        axios: axios.create({ headers, cancelToken }),
      };
      const input: AxiosPromise<IAxiosResponseType<T>> = request.apply(requestEnv, args);
      const request$ = from(input);
      request$.subscribe(sub);
      return source.cancel;
    });
  }

  private executeRequest<T extends Function>(
    request: T,
    args: IArguments<T>,
    requestParams: IRequestParams
  ): Observable<AxiosResponse<IAxiosResponseType<T>>> {
    const doAuth = () => {
      this.auth.authResult.next({});
      this.auth.redirectLogin();
      return EMPTY;
    };
    return from(this.authResult).pipe(
      switchMap(
        (authResult): Observable<IAuthResult> =>
          from(this.auth.validateToken(authResult)).pipe(catchError(doAuth))
      ),
      filter((result) => {
        if (requestParams.skipAuthCheck) {
          return true;
        }
        if (!result.idToken) {
          console.warn(`Prevented attempt to load request ${request.name} without auth`);
          return false;
        }
        return true;
      }),
      take(1),
      switchMap((result) => this.createRequest(request, args, result)),
      catchError((e: AxiosError) => {
        const { response } = e;
        if (response && response.status === 403) {
          console.error(e);
          return doAuth();
        }
        throw e;
      }),
      catchError((e: AxiosError) => {
        const { response } = e;
        if (response && response.status === 429) {
          return timer(1000).pipe(
            switchMap(() => this.executeRequest(request, args, requestParams))
          );
        }
        throw e;
      })
    );
  }

  private wrapApi<T extends { new (): unknown }>(clazz: T): IApiCaller<InstanceType<T>> {
    return ((
      methodName: keyof InstanceType<T>,
      skipAuthCheck: IRequestParams = { skipAuthCheck: false }
    ) => {
      const method = clazz.prototype[methodName];
      if (!method) {
        throw new Error('Api ' + methodName + ' not found');
      }
      return this.wrap(method, skipAuthCheck);
    }) as IApiCaller<InstanceType<T>>;
  }
}
