export enum RichEditorToolbarButtons {
  DELIMITER = '|',
  source = 'source',
  selectall = 'selectall',
  cut = 'cut',
  copy = 'copy',
  paste = 'paste',
  undo = 'undo',
  redo = 'redo',
  align = 'align',
  bold = 'bold',
  strikethrough = 'strikethrough',
  underline = 'underline',
  italic = 'italic',
  superscript = 'superscript',
  subscript = 'subscript',
  ul = 'ul',
  ol = 'ol',
  outdent = 'outdent',
  indent = 'indent',
  font = 'font',
  fontsize = 'fontsize',
  brush = 'brush',
  paragraph = 'paragraph',
  image = 'image',
  table = 'table',
  link = 'link',
  emailLink = 'emailLink',
  hr = 'hr',
}

export const BUTTONS = [
  RichEditorToolbarButtons.source,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.selectall,
  RichEditorToolbarButtons.cut,
  RichEditorToolbarButtons.copy,
  RichEditorToolbarButtons.paste,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.undo,
  RichEditorToolbarButtons.redo,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.align,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.bold,
  RichEditorToolbarButtons.strikethrough,
  RichEditorToolbarButtons.underline,
  RichEditorToolbarButtons.italic,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.superscript,
  RichEditorToolbarButtons.subscript,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.ul,
  RichEditorToolbarButtons.ol,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.outdent,
  RichEditorToolbarButtons.indent,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.font,
  RichEditorToolbarButtons.fontsize,
  RichEditorToolbarButtons.brush,
  RichEditorToolbarButtons.paragraph,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.image,
  RichEditorToolbarButtons.table,
  RichEditorToolbarButtons.link,
  RichEditorToolbarButtons.emailLink,
  RichEditorToolbarButtons.DELIMITER,
  RichEditorToolbarButtons.hr,
];
