import * as React from 'react';
import { Message, Text, TextCase, WordBreak, Font } from '@usga/modules';

interface IProps {
  title: string;
}

export const Item = ({ children, title }: React.PropsWithChildren<IProps>) => (
  <Message>
    <Text textCase={TextCase.UPPERCASE} font={Font.BOLD} letterSpacing={'0.5px'}>
      {title}
    </Text>
    <Text width="100%" wordBreak={WordBreak.BREAK_WORD}>
      {children}
    </Text>
  </Message>
);
