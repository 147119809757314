export interface ICookieParams {
  path?: string;
  expires?: Date;
  secure?: boolean;
  maxAge?: number;
}

/**
 * Cookie string formatter
 *
 * @param name name of the cookie
 * @param value value of the cookie
 * @param options options of a new cookie
 */
function makeCookieString(name: string, value: string, options: ICookieParams = {}) {
  const path = options.path || '/';
  const expires = options?.expires?.toUTCString();
  const secure = options.secure;
  const maxAge = options.maxAge;
  const newCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  const cookiePartsToAdd: Array<string> = [`path=${path}`];
  if (expires !== undefined) {
    cookiePartsToAdd.push(`expires=${expires}`);
  }
  if (secure !== undefined) {
    cookiePartsToAdd.push(secure ? `secure` : `secure=${secure}`);
  }
  if (maxAge !== undefined) {
    cookiePartsToAdd.push(`max-age=${maxAge}`);
  }
  const cookieToSet = cookiePartsToAdd.reduce((acc, currentParamKV) => {
    return `${acc}; ${currentParamKV}`;
  }, newCookie);

  return cookieToSet;
}

/**
 * This function tries to invoke a callback with arguments and does nothing/returns `undefined`
 * in case when the `fn` caused an error
 *
 * @param fn the function to be invoked
 */
function tryOrUndefined<R, T extends Array<unknown>>(fn: (...args: T) => R) {
  return (...args: T) => {
    try {
      return fn(...args);
    } catch {
      return undefined;
    }
  };
}

/**
 * Sets a new cookie with name, value and some params
 */
export const setCookie = tryOrUndefined((name: string, value: string, options?: ICookieParams) => {
  const cookie = makeCookieString(name, value, options);
  document.cookie = cookie;
});

/**
 * Exposes a cookie value by Its name
 */
export const getCookie = tryOrUndefined((name: string) => {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
});
