import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { IInputProps } from './interfaces';
import { InputLabel, InputLogo, InputStyled, InputWrapper } from './components';

export const Input: React.FunctionComponent<IInputProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onBlur, ...spanProps } = props;
  return (
    <InputWrapper width={props.width} className={props.className} borderColor={props.borderColor}>
      {props.mask ? (
        <MaskedInput
          mask={props.mask}
          onChange={props.onChange}
          guide={props.guide}
          render={(ref: (e: HTMLInputElement) => void, innerProps: IInputProps) => (
            <>
              <InputStyled
                ref={(el: HTMLInputElement) => ref(el)}
                {...props}
                autoComplete={'nofill'}
                onChange={innerProps.onChange}
              />
              <InputLabel
                as={'span'}
                value={props.value}
                labelMode={props.labelMode}
                width={props.width}
              >
                {props.label}
              </InputLabel>
            </>
          )}
        />
      ) : (
        <>
          <InputStyled {...props} autoComplete={'nofill'} />
          <InputLabel as={'span'} {...spanProps} className={'input-label'}>
            {props.label}
          </InputLabel>
        </>
      )}
      <InputLogo>{props.image}</InputLogo>
    </InputWrapper>
  );
};
