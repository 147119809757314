import * as React from 'react';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { AdminButton, Map } from '@usga/modules';
import {
  Modal,
  FlexWrapper,
  Text,
  FlexDirection,
  FlexAlignment,
  AdminTitle,
  If,
} from '@usga/modules';

export interface IBaseQuestion {
  id: unknown;
  title: string;
  buttonPropsSub$?: BehaviorSubject<Partial<React.ComponentProps<typeof AdminButton>>>;
  customOnClick?: (subscriber: Subscriber<IBaseQuestion>, closeDialog: () => void) => void;
}

export interface IQuestionOptions<T extends IBaseQuestion> {
  title: string;
  answers: T[];
  description?: React.ReactNode;
  answerOnClose?: T;
  overrideWidth?: string;
  modalClassName?: string;
  allowCloseOutside?: boolean;
}

const DEFAULT_ANSWERS = {
  yes: {
    title: 'Yes',
    id: 'ID_YES',
  },
  no: {
    title: 'No',
    id: 'ID_NO',
  },
  continue: {
    title: 'Continue',
    id: 'ID_CONTINUE',
  },
  cancel: {
    title: 'Cancel',
    id: 'ID_CANCEL',
  },
  ok: {
    id: 'ID_OK',
    title: 'OK',
  },
};

interface IQuestions<T extends IBaseQuestion> {
  ask: (options: IQuestionOptions<T>) => Observable<T>;
  defaultAnswers: typeof DEFAULT_ANSWERS;
}

export interface IQuestionsWithJsx<T extends IBaseQuestion> extends IQuestions<T> {
  jsxItems: React.ReactNode;
}

export interface IWithQuestions<T extends IBaseQuestion = IBaseQuestion> {
  questions: IQuestions<T>;
}

type IButtonComponentProps = React.ComponentProps<typeof AdminButton> & {
  buttonPropsSub?: BehaviorSubject<Partial<React.ComponentProps<typeof AdminButton>>>;
};

const ButtonComponent = ({ buttonPropsSub, ...rest }: IButtonComponentProps) => {
  const [propsFromSub, setPropsFromSub] = React.useState<
    Partial<React.ComponentProps<typeof AdminButton>>
  >(buttonPropsSub?.getValue() || {});
  React.useEffect(() => {
    const sub$ = buttonPropsSub?.subscribe(setPropsFromSub);
    return () => {
      sub$?.unsubscribe();
    };
  });
  return <AdminButton {...rest} {...propsFromSub} />;
};

/**
 * This hook allows any component to ask questions.
 * Typeparam T is for descrining answer type
 * @return object containing ask function and JSX rendered
 */
export const useQuestions = <T extends IBaseQuestion = IBaseQuestion>(): IQuestionsWithJsx<T> => {
  const [jsxItems, setJsxItems] = React.useState<React.ReactNode[]>([]);

  const makeRemoveFn = React.useMemo(() => {
    return (jsx: JSX.Element) => {
      return () => {
        setJsxItems((oldJSXItems) => {
          return oldJSXItems.filter((currentJSXItem) => {
            return currentJSXItem !== jsx;
          });
        });
      };
    };
  }, []);

  const ask = React.useCallback(
    (options: IQuestionOptions<T>) => {
      return new Observable<T>((observer) => {
        const allowCloseOutside =
          typeof options.allowCloseOutside === 'boolean'
            ? options.allowCloseOutside
            : Boolean(options.answerOnClose);
        const Wrapper = typeof options.description === 'string' ? Text : React.Fragment;
        const jsx = (
          <Modal
            open
            contentStyle={{
              minWidth: options.overrideWidth,
            }}
            key={options.title}
            hideCloseButton={!options.answerOnClose}
            onClose={
              options.answerOnClose
                ? () => {
                    const closeDialog = makeRemoveFn(jsx);
                    if (options.answerOnClose?.customOnClick) {
                      return options.answerOnClose.customOnClick(observer, closeDialog);
                    }
                    observer.next(options.answerOnClose);
                    observer.complete();
                    closeDialog();
                  }
                : undefined
            }
            closeOnDocumentClick={allowCloseOutside}
            closeOnEscape={allowCloseOutside}
            className={options.modalClassName}
            disableOverlayClick={!allowCloseOutside}
            footerContent={
              <FlexWrapper direction={FlexDirection.ROW} justifyContent={FlexAlignment.CENTER}>
                <Map items={options.answers}>
                  {(answer) => (
                    <ButtonComponent
                      buttonPropsSub={answer.buttonPropsSub$}
                      key={String(answer.id)}
                      onClick={() => {
                        const closeDialog = makeRemoveFn(jsx);
                        if (answer.customOnClick) {
                          return answer.customOnClick(observer, closeDialog);
                        }
                        observer.next(answer);
                        observer.complete();
                        closeDialog();
                      }}
                    >
                      {answer.title}
                    </ButtonComponent>
                  )}
                </Map>
              </FlexWrapper>
            }
          >
            <>
              <FlexWrapper>
                <AdminTitle width={'auto'}>{options.title}</AdminTitle>
                <If cond={Boolean(options.description)}>
                  <Wrapper>{options.description}</Wrapper>
                </If>
              </FlexWrapper>
            </>
          </Modal>
        );
        setJsxItems([...jsxItems, jsx]);
      });
    },
    [jsxItems, makeRemoveFn]
  );

  return { ask, jsxItems, defaultAnswers: DEFAULT_ANSWERS };
};

export const withQuestions = <P extends {}, T extends IBaseQuestion = IBaseQuestion>(
  Component: React.ComponentType<P & IWithQuestions<T>>
) => (props: P) => {
  const { ask, defaultAnswers, jsxItems } = useQuestions<T>();
  const questionsProp = React.useMemo(() => ({ ask, defaultAnswers }), [
    jsxItems,
    defaultAnswers,
    ask,
  ]);

  return (
    <>
      <Component {...props} questions={questionsProp} />
      {jsxItems}
    </>
  );
};
