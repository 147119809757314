export interface ILoadedFonts {
  [fontName: string]: {
    [extname: string]: string;
  };
}

export enum Font {
  BOLD = 'NationalWeb-Bold',
  MEDIUM = 'NationalWeb-Medium',
  REGULAR = 'NationalWeb-Regular',
  REGULAR_ITALIC = 'NationalWeb-Italic',
  LIGHT = 'NationalWeb-Light',
  LIGHT_ITALIC = 'NationalWeb-LightItalic',
  BOOK = 'NationalWeb-Book',
  BOLD_ITALIC = 'NationalWeb-BoldItalic',
}

export enum Color {
  PRIMARY = '#00365f',
  SECONDARY = '#158ee0',
  ALERT = '#da1a31',
  SUCCESS = '#00830b',
  DEFAULT = '#333',
  GREY = '#F6F6F6',
  DARK_GREY = '#efefef',
  DARK_DEEP_GREY = '#e3e3e3',
  LIGHT_GREY = '#fbfbfb',
  TEXT_GREY = '#9b9b9b',
  BORDER_GREY = '#dddddd',
  INPUT_BORDER = '#ccc',
  WHITE = '#fff',
  BLACK = '#000',
  PENDING = '#f58142',
  CALENDAR_ACTIVE_START_DATE = '#f3f59c',
  ORANGE = '#ff6600',
  PINK = '#ff00ff',
}
