import * as React from 'react';
import styled from 'styled-components';
import logo from '../../assets/usga-logo.png';
import { FlexAlignment, FlexDirection, FlexWrapper } from '../../helpers/FlexWrapper';
import { Color } from '../../helpers/Fonts';
import { Text } from '../Text';

interface IProps {
  href?: string;
  onClick?: () => void;
}

const StyledWrapper = styled(FlexWrapper)`
  background-color: ${Color.LIGHT_GREY};
  cursor: ${(props: IProps) => props.href && 'pointer'};
  flex: initial;
  max-width: 320px;
`;

const StyledImg = styled.a`
  background-image: url(${logo});
  background-position: left center;
  background-size: 6.25rem auto;
  background-repeat: no-repeat;
  display: block;
  flex-shrink: 0;
  margin: 0 10px;
  height: 73px;
  width: 7.5rem;
`;

export function HeaderLogo(props: IProps) {
  return (
    <StyledWrapper alignItems={FlexAlignment.CENTER} direction={FlexDirection.ROW} {...props}>
      <StyledImg />
      <Text>Champ Admin Tool</Text>
    </StyledWrapper>
  );
}
