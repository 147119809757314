import * as React from 'react';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ChangesTrackerContext, IChangesTrackerContextValue } from './ChangesTrackerContext';
import { useHistory as useBaseHistory } from 'react-router';
import { QuestionsContext } from './QuestionsContext';

type History = ReturnType<typeof useBaseHistory>;

export interface IExtendedHistory extends History, IChangesTrackerContextValue {
  checkUnsavedAndPush: (location: string) => Observable<boolean>;
}

export function useHistory(): IExtendedHistory {
  const baseHistory = useBaseHistory();
  const { flushChanges, hasChanges, setChanged, getMessage, observeHasChanges } = React.useContext(
    ChangesTrackerContext
  );

  const questions = React.useContext(QuestionsContext);

  const getAnswer = React.useCallback(() => {
    if (questions === null) {
      throw new Error('No QuestionsProvider');
    }

    const {
      ask,
      defaultAnswers: { no, yes },
    } = questions;

    return ask({ title: getMessage() ?? '', answers: [yes, no], answerOnClose: no }).pipe(
      map((answer) => answer.id === yes.id)
    );
  }, [questions]);

  const checkUnsavedAndPush = React.useCallback(
    (location: string) => {
      if (hasChanges()) {
        const observable$ = getAnswer().pipe(share());
        observable$.subscribe((answer) => {
          if (answer) {
            baseHistory.push(location);
            return of(true);
          } else {
            return of(false);
          }
        });
        return observable$;
      } else {
        baseHistory.push(location);
        return of(true);
      }
    },
    [baseHistory, hasChanges, getAnswer, getMessage]
  );

  const extendedHistory = React.useMemo<IExtendedHistory>(() => {
    return {
      ...baseHistory,
      flushChanges,
      setChanged,
      hasChanges,
      getMessage,
      checkUnsavedAndPush,
      observeHasChanges,
    };
  }, [
    baseHistory,
    flushChanges,
    setChanged,
    hasChanges,
    checkUnsavedAndPush,
    getMessage,
    observeHasChanges,
  ]);

  return extendedHistory;
}

export function discardChangesOnUnmount<P extends object>(
  Component: React.ComponentType<P>
): React.ComponentType<P> {
  return function TrackChanges(props: P) {
    const { flushChanges } = React.useContext(ChangesTrackerContext);
    React.useEffect(() => {
      return () => {
        flushChanges();
      };
    }, [flushChanges]);

    return <Component {...props} />;
  };
}
