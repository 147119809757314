import React from 'react';

export function disableUpdate<T>(Component: React.ComponentType<T>) {
  return class DisableUpdate extends React.Component<T> {
    render() {
      return <Component {...this.props} />;
    }
    shouldComponentUpdate() {
      return false;
    }
  };
}
