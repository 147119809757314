import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IProps extends React.ComponentProps<typeof Link> {
  to: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export function LinkClickable({
  to,
  onClick,
  children,
  ...linkProps
}: React.PropsWithChildren<IProps>) {
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (!onClick || e.ctrlKey) {
        return;
      }

      e.preventDefault();
      onClick(e);
    },
    [onClick]
  );

  return (
    <StyledLink {...linkProps} to={to} onClick={handleClick}>
      {children}
    </StyledLink>
  );
}
