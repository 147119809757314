import { ChampionshipBaseModel, WorkflowTypeEnum } from '@usga/champadmin-api';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export function isTwoStagesFlow(
  championship: DeepPartial<ChampionshipBaseModel> | undefined | null,
  excludeWorkflows: WorkflowTypeEnum[] = []
) {
  const workflowsToCheck = [
    WorkflowTypeEnum.USOPEN,
    WorkflowTypeEnum.USAMATEUR,
    WorkflowTypeEnum.TWOSTAGEOPEN,
  ].filter((it) => !excludeWorkflows.includes(it));

  return workflowsToCheck.includes(
    championship?.championshipType?.workflowType ?? ('' as WorkflowTypeEnum)
  );
}

export function hideChampTypeFromMultiSelect(id: number) {
  setTimeout(() => {
    const champType11 = document.evaluate(
      `//div[contains(@class, "-multiValue")]/div[text()="${id}"]/..`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;

    if (!champType11) {
      return;
    }

    (champType11 as HTMLElement).style.display = 'none';
  }, 500);
}
