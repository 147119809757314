import { ApplicationProgressionEnum } from '@usga/champadmin-api';

export const mapApplicationStatus = (applicationStatus: ApplicationProgressionEnum) => {
  switch (applicationStatus) {
    case ApplicationProgressionEnum.ACCEPTED:
      return 'Accepted';
    case ApplicationProgressionEnum.DRAFT:
      return 'Draft';
    case ApplicationProgressionEnum.NEEDSREVIEW:
      return 'Needs Review';
    case ApplicationProgressionEnum.Q:
      return 'Q';
    case ApplicationProgressionEnum.WITHDRAWN:
      return 'Withdrawn';
    case ApplicationProgressionEnum.WAITLIST:
      return 'Waitlisted';
    case ApplicationProgressionEnum.NEEDPARTNER:
      return 'Needs Partner';
    default:
      return '';
  }
};
