import * as React from 'react';
import styled from 'styled-components';
import { ICloudinaryImage } from 'cloudinary-react';
import { CloudinaryImage } from '../CloudinaryImage';

export const STANDART_ROUNDED_SIZE = 190;

const StyledImage = styled(CloudinaryImage)`
  border-radius: ${(props: ICloudinaryImage) =>
    props.radius === 'max' ? '50%' : props.radius || '50%'};
  overflow: hidden;
`;

export function RoundedImage(props: ICloudinaryImage): JSX.Element {
  return (
    <StyledImage
      {...props}
      radius={props.radius || 'max'}
      height={props.height || STANDART_ROUNDED_SIZE}
      width={props.width || STANDART_ROUNDED_SIZE}
    />
  );
}
