import { BlacklistStatusEnum } from '@usga/champadmin-api';

export const mapBlacklistStatus = (blacklistStatus: BlacklistStatusEnum) => {
  switch (blacklistStatus) {
    case BlacklistStatusEnum.NOSHOW1:
      return 'No show 1';
    case BlacklistStatusEnum.NOSHOW2:
      return 'No show 2';
    case BlacklistStatusEnum.NOSHOW3:
      return 'No show 3';
    case BlacklistStatusEnum.PERFORMANCEVIOLATION1:
      return 'Performance Violation 1';
    case BlacklistStatusEnum.PERFORMANCEVIOLATION2:
      return 'Performance Violation 2';
    case BlacklistStatusEnum.KEEP:
      return 'Keep';
    case BlacklistStatusEnum.MONEY:
      return 'Money';
  }
};
