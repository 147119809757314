import * as React from 'react';
import styled from 'styled-components';
import { FlexDirection, FlexWrapper } from '../../helpers/FlexWrapper';
import { Color, Font } from '../../helpers/Fonts';
import { ILayoutTabsProps, IButtonProps } from './interfaces';

const ButtonWrapper = styled(FlexWrapper)`
  max-width: 300px;
  border-radius: 15px;
  margin: 0 auto 25px;
  overflow: hidden;
`;

const Button = styled.button`
  width: 150px;
  height: 30px;
  font-family: ${Font.BOOK};
  font-size: ${(props: IButtonProps) => props.fontSize || '16px'};
  color: ${(props: IButtonProps) =>
    props.active ? props.activeColor || '#fff' : props.color || Color.PRIMARY};
  background-color: ${(props: IButtonProps) =>
    props.active ? props.activeColor || Color.PRIMARY : '#fff'};
  border: none;
  outline: none;
  cursor: pointer;
`;

export function LayoutTabs<T extends string>(props: ILayoutTabsProps<T>) {
  const renderTabs = props.tabs.map((tab, index) => {
    const tabProps = {
      onClick: props.handleClick,
      active: tab.id === props.activeTab,
      id: tab.id,
      title: tab.title,
    };
    return (
      <Button key={index} {...tabProps}>
        {tab.title}
      </Button>
    );
  });

  return (
    <ButtonWrapper {...props} direction={FlexDirection.ROW}>
      {renderTabs}
    </ButtonWrapper>
  );
}
