import { BehaviorSubject } from 'rxjs';
import * as Utils from './utils';

/**
 * Cookie observer
 * Please note, It can't track changes done in the another place:
 * all changes should be done within this class
 */
export class ReactiveCookie {
  protected readonly changeSubject: BehaviorSubject<string | null>;

  constructor(private readonly name: string) {
    const cookie = Utils.getCookie(name) || null;
    this.changeSubject = new BehaviorSubject(cookie);
  }

  public observeChanges() {
    return this.changeSubject.asObservable();
  }

  public setValue(value: string | null, options?: Utils.ICookieParams) {
    if (value === null) {
      this.deleteCookie();
    } else {
      this.setCookie(value, options);
    }
    this.changeSubject.next(value);
  }

  public forceCheck() {
    const cookie = Utils.getCookie(this.name) || null;
    if (cookie !== this.changeSubject.value) {
      this.changeSubject.next(cookie);
    }
  }

  public getValue() {
    return this.changeSubject.value;
  }

  private deleteCookie() {
    Utils.setCookie(this.name, '', { maxAge: -1 });
  }

  private setCookie(value: string, options?: Utils.ICookieParams) {
    Utils.setCookie(this.name, value, options);
  }
}
