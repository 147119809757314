import * as React from 'react';
import styled from 'styled-components';
import { FilterWrapper } from './FilterWrapper';
import { IFilterComponentProps } from './FilterComponentProps';
import { Checkbox } from '../Checkbox';
import { Color } from '../../helpers';

const Frame = styled.label<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '224px' : '200px')};
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid ${Color.INPUT_BORDER};
  padding-left: 5px;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const BooleanFilter = ({
  disabled,
  onValueChange,
  value,
  onRemoveClick,
  title,
}: IFilterComponentProps<boolean>) => {
  const handleValueChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (evt) => {
      onValueChange(evt.currentTarget.checked);
    },
    [onValueChange]
  );
  return (
    <FilterWrapper onRemoveClick={onRemoveClick}>
      <Frame fullWidth={!onRemoveClick}>
        <Checkbox checked={value} onChange={handleValueChange} disabled={disabled}>
          {title}
        </Checkbox>
      </Frame>
    </FilterWrapper>
  );
};
