import React from 'react';
import { Page, withModel } from '@adobe/cq-react-editable-components';
import { AppContext, disableUpdate } from '@usga/modules';
import { Normalize as StyledNormalize } from 'styled-normalize';
import { Auth0Provider, GuestUserGuard } from './services/appAuth';
import './pages';
import './components';
import { HttpServiceProvider, CheckImpersonation } from './services/http';
import { hot } from 'react-hot-loader/root';
import { RendersProvider } from '@usga/modules';
import { CountryStateProvider } from './services/country/country-state';
import { ChangesTrackerProvider } from './services/ChangesTracker';
import { DocumentTitle } from './DocumentTitle';

const Normalize = disableUpdate(StyledNormalize);

// This component is the application entry point
class AppComponent extends Page {
  public componentDidMount() {
    try {
      _satellite?.pageBottom();
    } catch (e) {
      // do nothing, analytics is not available
    }
  }

  public render() {
    return (
      <DocumentTitle title={this.props.title}>
        <RendersProvider>
          <ChangesTrackerProvider>
            <Normalize />
            <AppContext>
              <Auth0Provider>
                <GuestUserGuard>
                  <HttpServiceProvider>
                    <CheckImpersonation pageType={this.props.type}>
                      <CountryStateProvider>
                        {this.childComponents}
                        {this.childPages}
                      </CountryStateProvider>
                    </CheckImpersonation>
                  </HttpServiceProvider>
                </GuestUserGuard>
              </Auth0Provider>
            </AppContext>
          </ChangesTrackerProvider>
        </RendersProvider>
      </DocumentTitle>
    );
  }
}

export const App = hot(withModel(AppComponent));
