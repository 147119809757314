import * as React from 'react';
import { useMemo } from 'react';
import ReactJsPopup from 'reactjs-popup';
import {
  CloseButton,
  PopupHeaderContent,
  PopupHeader,
  PopupContentWrapper,
  PopupWrapper,
  PopupChildrenWrapper,
} from './components';
import { useBodyLockScroll, LockScrollClassNames } from './helpers';
import { AddCircleIcon } from '../Icon';
import { If } from '../../helpers/OnChange';

type IInferProps<T> = T extends React.PureComponent<infer U> ? U : never;

interface IProps extends IInferProps<ReactJsPopup> {
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  hideCloseButton?: boolean;
  disableOverlayClick?: boolean;
  successMessage?: string;
  errorMessage?: string;
}

export const Modal: React.FunctionComponent<IProps> = (props) => {
  const {
    overlayStyle,
    contentStyle,
    headerContent,
    footerContent,
    children,
    lockScroll = true,
    hideCloseButton,
    onClose,
    className,
    disableOverlayClick,
    successMessage,
    errorMessage,
    ...rest
  } = props;
  const popupRef = React.useRef<null | HTMLDivElement>(null);

  const customContentStyle: React.CSSProperties = useMemo(() => {
    return {
      padding: undefined,
      width: undefined,
      border: 'none',
      ...contentStyle,
    };
  }, [contentStyle]);

  const handleOverlayClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (!disableOverlayClick && onClose) {
        onClose();
      }
    },
    [disableOverlayClick, onClose]
  );

  const handleModalClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  React.useEffect(() => {
    const { disableScroll, enableScroll } = useBodyLockScroll(popupRef.current);

    if (rest.open && lockScroll) {
      disableScroll();
    }

    return enableScroll;
  }, [lockScroll, rest.open]);

  return (
    <PopupWrapper
      successMessage={successMessage}
      errorMessage={errorMessage}
      onClick={handleOverlayClick}
      className={className}
      ref={popupRef}
    >
      <ReactJsPopup
        modal={true}
        overlayStyle={overlayStyle}
        contentStyle={customContentStyle}
        closeOnDocumentClick={false}
        onClose={handleModalClose}
        lockScroll={false}
        {...rest}
      >
        <React.Fragment>
          <If cond={!hideCloseButton}>
            <CloseButton onClick={handleModalClose}>
              <AddCircleIcon />
            </CloseButton>
          </If>
          <If cond={Boolean(headerContent)}>
            <PopupHeader>
              <PopupHeaderContent>{headerContent}</PopupHeaderContent>
            </PopupHeader>
          </If>
          <PopupContentWrapper className={`${LockScrollClassNames.ENABLE} popup-content-wrapper`}>
            <PopupChildrenWrapper>{children}</PopupChildrenWrapper>
          </PopupContentWrapper>
          <If cond={Boolean(footerContent)}>
            <div>{footerContent}</div>
          </If>
        </React.Fragment>
      </ReactJsPopup>
    </PopupWrapper>
  );
};
