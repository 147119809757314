import styled from 'styled-components';
import { Color } from '../../helpers/Fonts';
import { IMenuItemsContainerProps, MenuAlign } from './interfaces';
import { Text, ITextProps } from '../Text';
import { MenuAdmin } from '../Icon';

const MENU_Z_INDEX = 9999;

export const FullscreenOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: ${MENU_Z_INDEX};
`;

export const MenuItemsPosition = styled.div<{ align?: MenuAlign }>`
  width: 0;
  height: 0;
  position: absolute;
  z-index: ${MENU_Z_INDEX + 1};
  left: ${({ align }) => (align === 'left' ? '100%' : '0')};
  top: 100%;
`;

export const MenuItemsContainer = styled.div<IMenuItemsContainerProps>`
  width: 200px;
  background: white;
  border-radius: 0 0 2px 2px;
  box-shadow: 2px 2px 2px rgba(155, 155, 155, 50%);
  position: absolute;
  border: 1px solid ${Color.TEXT_GREY};
  right: ${({ align }) => (align === 'left' ? '0' : undefined)};
`;

export const MenuItemWrapper = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  width: 100%;
  height: 30px;
  align-items: center;
  padding: 0 10px;
  white-space: nowrap;
  box-sizing: border-box;
  color: ${({ disabled }) => `${disabled ? Color.TEXT_GREY : undefined}`};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'transparent' : Color.TEXT_GREY)};
  }
`;

export const MenuItemText = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;

export const MenuHeader = styled.span`
  display: inline-flex;
  align-items: center;
  height: 1em;
  flex-direction: row;
`;

export const MenuText = styled(Text as React.ComponentType<ITextProps>)<ITextProps>`
  position: relative;
  display: inline-flex;
`;

export const MenuIcon = styled(
  MenuAdmin as React.ComponentType<React.ComponentProps<typeof MenuAdmin>>
)`
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;
