import styled, { css, keyframes } from 'styled-components';
import * as Constants from './constants';
import { IOverlayProps } from './interfaces';

export const OverlayWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const fadeOut = keyframes`
  from {
    opacity: ${Constants.MIN_OPACITY};
  }
  to {
    opacity: ${Constants.MAX_OPACITY};
  }
`;

const fadeIn = keyframes`
  from {
    opacity: ${Constants.MAX_OPACITY};
  }
  to {
    opacity: ${Constants.MIN_OPACITY};
  }
`;

export const OverlayAbsolute = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
`;

export const OverlayFade = styled.div<IOverlayProps>`
  opacity: ${(props) => (props.active ? Constants.MIN_OPACITY : Constants.MAX_OPACITY)};
  ${(props) =>
    !props.active // fixme figure out how to use fadeIn
      ? css`
          animation: ${props.active ? fadeIn : fadeOut} ${Constants.TRANSITION_STR} ease-in;
        `
      : ''}
`;
