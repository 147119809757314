import React from 'react';
import { MapTo, Page } from '@adobe/cq-react-editable-components';

export class PageComponent extends Page {
  public render() {
    return (
      <div className={'page'}>
        {this.childComponents}
        {this.childPages}
      </div>
    );
  }
}

MapTo('champAdmin/components/structure/page')(PageComponent);
