import * as React from 'react';
import { BehaviorSubject, Subscription } from 'rxjs';

interface ISharedPersistentContextParams<T> {
  subscribe: (callback: (persistentValue: T) => void) => Subscription;
  next: (queryParms: T) => void;
  reset: () => void;
  getValue: () => T;
}

export interface IWIthPersistentValueParams<T> {
  sharedPersistentValueContext: ISharedPersistentContextParams<T>;
}

/**
 * Class for sharing data and make them persist disregarding of
 * its mounted state and existance in the VDOM.
 * the value set once will be preserved,
 * and shared between subscribed by SharedPersistentContext instance components
 */
export class SharedPersistentContext<T> {
  private readonly initial: T;

  private subj$: BehaviorSubject<T>;

  /**
   *
   * @param initial initial value to set from the beginning and after `reset`
   */
  constructor(initial: T) {
    this.initial = initial;
    this.subj$ = new BehaviorSubject(initial);
  }

  /**
   * Subscribe a component to shared value.
   * Creates a new component.
   * use {@link useSharedPersistentValue} hook
   * to subscribe a component instance.
   *
   * @param Component component to subscribe
   */
  public subscribeComponent = <P extends {} = {}>(
    Component: React.ComponentType<P & IWIthPersistentValueParams<T>>
  ) => {
    const sharedPersistentValue = {
      subscribe: (callback: (persistentValue: T) => void) => this.subj$.subscribe(callback),
      next: (persistentValue: T) => this.subj$.next(persistentValue),
      reset: () => this.subj$.next(this.initial),
      getValue: () => this.subj$.getValue(),
    };

    return class extends React.Component<P> {
      public static displayName = `${Component.displayName}WithSharedPersistentValue`;
      public render() {
        return <Component {...this.props} sharedPersistentValueContext={sharedPersistentValue} />;
      }
    };
  };

  public useSharedPersistentValue = (
    sharedPersistentValueContext: ISharedPersistentContextParams<T>
  ) => {
    const [sharedPersistentValue, setSharedPersistentValue] = React.useState(
      sharedPersistentValueContext.getValue()
    );

    React.useEffect(() => {
      const sub$ = sharedPersistentValueContext.subscribe((newPersistentValue) => {
        /*
         * Please note, this code could led to
         * "Can't perform a React state update on an unmounted component" warning.
         * Probably due to React issue.
         * TODO: check and fix It.
         * see https://github.com/facebook/react/issues/15006
         */
        setSharedPersistentValue(newPersistentValue);

        return () => {
          sub$.unsubscribe();
        };
      });
    }, []);

    const updateSharedPersistentValue = React.useCallback(
      (persistentValue: T) => {
        sharedPersistentValueContext.next(persistentValue);
      },
      [sharedPersistentValueContext.next]
    );

    return {
      sharedPersistentValue,
      updateSharedPersistentValue,
      reset: sharedPersistentValueContext.reset,
    };
  };
}
