import styled, { createGlobalStyle } from 'styled-components';
import { Color, Font } from './interfaces';
import { disableUpdate } from '../DisableUpdate';
import '../../assets/favicon.ico';

export * from './interfaces';

export const GlobalStyles = disableUpdate(createGlobalStyle`

body {
  font-family: ${Font.BOOK}, Arial, sans-serif;
  letter-spacing: 0.5;
  background-color: rgb(239, 240, 242);
  color: ${Color.DEFAULT};

  
  b {
    font-family: ${Font.BOLD}, Arial, sans-serif;
    font-weight: normal;
  }
  
  i {
    font-family: ${Font.REGULAR_ITALIC}, Arial, sans-serif;
  }
}

html, body {
  height: 100%;
  width: 100%;
}

span {
  display: inline-block;
}

h1, h2, h3, h4, h5, h6, p, b, strong {
  font-weight: normal;
}

strong {
  font-family: ${Font.BOLD};
}

.disable-scroll {
    height: 100vh;
    overflow-y: hidden;

    body {
      height: 100vh;
      overflow-y: hidden; 
    }
  }
`);

export const FontBook = styled.span`
  font-family: ${Font.BOOK};
`;
export const FontMedium = styled.span`
  font-family: ${Font.MEDIUM};
`;
export const FontBold = styled.span`
  font-family: ${Font.BOLD};
`;
export const FontLight = styled.span`
  font-family: ${Font.LIGHT};
`;
export const FontLightItalic = styled.span`
  font-family: ${Font.LIGHT_ITALIC};
`;
export const FontRegular = styled.span`
  font-family: ${Font.REGULAR};
`;
export const FontRegularItalic = styled.span`
  font-family: ${Font.REGULAR_ITALIC};
`;
