import { QualifyingSiteStatusEnum } from '@usga/champadmin-api';

export const mapQualifyingSiteStatusEnum = (status: QualifyingSiteStatusEnum) => {
  switch (status) {
    case QualifyingSiteStatusEnum.APPROVED:
      return 'Approved';
    case QualifyingSiteStatusEnum.CLOSED:
      return 'Closed';
    case QualifyingSiteStatusEnum.PENDING:
      return 'Pending';
  }
};
