import * as React from 'react';

interface IFileInputProps {
  onChange: (file: File | null) => void;
}

export const FileInput = ({ onChange }: IFileInputProps) => {
  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ({ currentTarget }) => {
      const files = currentTarget.files;
      if (!files) {
        onChange(null);
        return;
      }
      const firstFile = files[0];
      if (!firstFile) {
        onChange(null);
        return;
      }
      onChange(firstFile);
    },
    [onChange]
  );
  return <input type="file" onChange={handleChange} />;
};
