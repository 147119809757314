export enum TextAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify',
}

export enum TextCase {
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
  CAPITALIZE = 'capitalize',
}

export enum Cursor {
  DEFAULT = 'default',
  POINTER = 'pointer',
  TEXT = 'text',
  INHERIT = 'inherit',
}

export enum TextDecoration {
  UNDERLINE = 'underline',
  NONE = 'none',
  INHERIT = 'inherit',
}

export enum WordBreak {
  NORMAL = 'normal',
  BREAK_WORD = 'break-word',
  BREAK_ALL = 'break-all',
  KEEP_ALL = 'keep-all',
}

export enum TextOverflow {
  CLIP = 'clip',
  ELLIPSIS = 'ellipsis',
}

export enum WhiteSpace {
  NORMAL = 'normal',
  NOWRAP = 'nowrap',
  PRE = 'pre',
  PRELINE = 'pre-line',
  PREWRAP = 'pre-wrap',
  INHERIT = 'inherit',
}
