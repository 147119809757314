import * as React from 'react';
import { HttpServiceContext } from './contexts';
import { IHttpService } from './interfaces';

export function useHttpService() {
  const service = React.useContext(HttpServiceContext);
  if (!service) {
    throw new Error('HttpService is unavailable');
  }
  return service;
}

export interface IHttpServiceComponentProps {
  httpService: IHttpService;
}

export function withHttpService<P extends IHttpServiceComponentProps>(
  Component: React.ComponentType<P>
): (props: Omit<P, keyof IHttpServiceComponentProps>) => JSX.Element {
  return function WithHttpService(props) {
    const httpService = React.useContext(HttpServiceContext);
    if (!httpService) {
      throw new Error('Http service is now available');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const AnyComponent: React.ComponentType<any> = Component;
    return <AnyComponent {...props} httpService={httpService} />;
  };
}
