/**
 * ComponentMapping instance.
 * @private
 */
let instance = null;

/**
 * ComponentMapping singleton. It manages the mapping between AEM component resource types and corresponding
 * JavaScript component class.
 */
class ComponentMapping {
    /**
     * Returns mapping singleton.
     */
    static get instance() {
        return new this();
    }

    /**
     * Creates ComponentMapping singleton.
     * @constructor
     */
    constructor() {
        this.mapping = {};

        if (!instance) {
            instance = this;
        }

        return instance;
    }

    /**
     * Creates mapping for given resource type(s) and a component class.
     *
     * @param {string|array} resourceTypes      - resource type(s)
     * @param {object} clazz                    - component class that should be associated with given resource type(s)
     *
     * @protected
     */
    map(resourceTypes, clazz) {
        if (resourceTypes && clazz) {
            const self = this;

            [].concat(resourceTypes).forEach((entry) => { self.mapping[entry] = clazz; });
        }
    }

    /**
     * Returns object (or undefined) matching with given resource type.
     *
     * @param {string} resourceType     - resource type
     * @returns {object|undefined}      - class associated with given resource type
     */
    get(resourceType) {
        return this.mapping[resourceType];
    }
}

/**
 * Helper function that can be used to map a class to given resource type(s).
 *
 * @param {string|array} resourceTypes          - resource type(s)
 * @returns {function}                          - function meant to map a class with the previously given resource types
 */
function MapTo(resourceTypes) {
    return (clazz) => ComponentMapping.instance.map(resourceTypes, clazz);
}

export { ComponentMapping, MapTo };
