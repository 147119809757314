import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';
import { Color, Font } from '../../helpers/Fonts';

export const selectCss = css<{ borderColor: string; height: string; width: string }>`
  font-family: ${Font.REGULAR};
  border: 1px solid ${({ borderColor }) => borderColor || Color.INPUT_BORDER};
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || '100%'};
  min-height: 36px;
  font-size: 16px;
  border-radius: 4px;
  position: relative;
`;

export const StyledSelect = styled(ReactSelect)`
  ${selectCss};
`;

export const reactSelectComponentsStyles = {
  control: () => css`
    display: flex;
    height: 100%;
    cursor: pointer;
  `,
  menu: (props: string) => css`
    ${props};
    z-index: 10;
  `,
  menuList: (props: string) => css`
    ${props};
    max-height: 150px;
  `,
};
