import { AxiosResponse } from 'axios';
import constant from 'lodash/constant';
import { ImpersonationCookieModel, PagePathModel } from '@usga/champadmin-api';

export const suppressObservableResult = constant(undefined);

export function extractImpersonationProfile(
  response: string | null
): ImpersonationCookieModel | null {
  return response ? JSON.parse(response) : null;
}
export function extractNavigationPath(response: AxiosResponse<PagePathModel>) {
  return response.data.pagePath;
}
