import * as React from 'react';
import { tableBorder, tableBorderCollapse, tdBackground, tdBorder } from './default-styles';

function processHtml(htmlInput: React.FocusEvent<HTMLTextAreaElement> | string) {
  const template = document.createElement('template');

  const html = typeof htmlInput === 'string' ? htmlInput : htmlInput.target.innerHTML;

  template.innerHTML = html;
  template.content.querySelectorAll('table').forEach((table) => {
    table.style.borderCollapse = table.style.borderCollapse || tableBorderCollapse;
    table.style.border = table.style.border || tableBorder;
  });
  template.content.querySelectorAll('td').forEach((td) => {
    td.style.backgroundColor = td.style.backgroundColor || tdBackground;
    td.style.border = td.style.border || tdBorder;
  });
  return template.innerHTML;
}

export function useDirty(
  initial: string,
  fn: (arg: React.FocusEvent<HTMLTextAreaElement> | string) => unknown
) {
  const stateRef = React.useRef<string>(initial);

  return React.useCallback(
    (arg: React.FocusEvent<HTMLTextAreaElement> | string) => {
      if (arg !== stateRef.current) {
        stateRef.current = typeof arg === 'string' ? arg : arg.target.innerHTML;
        fn(processHtml(arg));
      }
    },
    [fn]
  );
}
