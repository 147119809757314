import * as React from 'react';
import styled from 'styled-components';
import { FlexWrapper, FlexDirection, FlexAlignment, FlexWrap } from '../../helpers';
import { TrashIcon } from '../Icon';

interface IWrapperProps {
  disableAutoWidth?: boolean;
}

interface IOwnProps extends IWrapperProps {
  onRemoveClick?: () => void;
}

const FilterContainer = styled(FlexWrapper)<IWrapperProps>`
  width: ${({ width }) => width};
  align-self: flex-start;
  flex: 0 0 auto;
  margin: 0 5px 5px;
`;

export const FilterWrapper = ({
  children,
  onRemoveClick,
  disableAutoWidth,
}: React.PropsWithChildren<IOwnProps>) => (
  <FilterContainer
    direction={FlexDirection.ROW}
    alignItems={FlexAlignment.CENTER}
    justifyContent={FlexAlignment.START}
    wrap={FlexWrap.NOWRAP}
    width={disableAutoWidth ? '100%' : '224px'}
  >
    <FlexWrapper flex="initial">{children}</FlexWrapper>
    {onRemoveClick && (
      <div>
        <TrashIcon width="24px" height="24px" onClick={onRemoveClick} />
      </div>
    )}
  </FilterContainer>
);
