import * as React from 'react';
import styled, { StyledComponent } from 'styled-components';
import {
  AddCircleIcon,
  EditIconNew,
  TrashIcon,
  IProps as ISvgIconProps,
  FacebookSvg,
  InstagramSvg,
  TwitterSvg,
  YoutubeSvg,
  LinkedinSvg,
  GooglePlusSvg,
} from '../Icon';
import { Color } from '../../helpers';

const DEFAULT_SIDE = '24px';

interface IFlatIconicButtonOptions {
  side?: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: StyledComponent<any, any, object & ISvgIconProps, string | number | symbol>;
  margin?: string;
}

interface IFlatIconicButtonProps {
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => unknown;
  disabled?: boolean;
}

const makeFlatIconButton = ({
  side = DEFAULT_SIDE,
  name = 'AnonymousIconicButton',
  icon: Icon,
  margin = '0',
}: IFlatIconicButtonOptions) => {
  const StyledButton = styled.button`
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
  `;

  const IconStyled = styled(Icon as React.ComponentType<React.ComponentProps<typeof Icon>>)`
    color: ${({ disabled }) => (disabled ? Color.DARK_DEEP_GREY : Color.DEFAULT)};
    ${StyledButton}:hover & {
      color: ${({ disabled }) => (disabled ? Color.DARK_DEEP_GREY : Color.DARK_DEEP_GREY)};
    }
  `;

  const Component = function({
    onClick,
    disabled,
    margin: marginProp,
    side: sideProp,
  }: IFlatIconicButtonProps & Partial<IFlatIconicButtonOptions>) {
    return (
      <StyledButton onClick={onClick} disabled={disabled}>
        <IconStyled
          width={sideProp || side}
          height={sideProp || side}
          margin={marginProp || margin}
          disabled={disabled}
        />
      </StyledButton>
    );
  };

  Component.displayName = name;

  return Component;
};

export const ButtonAdd = makeFlatIconButton({
  icon: AddCircleIcon,
  margin: '10px',
  name: 'ButtonAdd',
});

export const ButtonEdit = makeFlatIconButton({
  icon: EditIconNew,
  margin: '10px',
  name: 'ButtonEdit',
});

export const ButtonTrash = makeFlatIconButton({
  icon: TrashIcon,
  margin: '10px',
  name: 'ButtonTrash',
});

export const InstagramIcon = makeFlatIconButton({
  icon: InstagramSvg,
  name: 'InstagramIcon',
  side: '46px',
});

export const TwitterIcon = makeFlatIconButton({
  icon: TwitterSvg,
  name: 'TwitterIcon',
  side: '46px',
});

export const YoutubeIcon = makeFlatIconButton({
  icon: YoutubeSvg,
  name: 'YoutubeIcon',
  side: '46px',
});

export const FacebookIcon = makeFlatIconButton({
  icon: FacebookSvg,
  name: 'FacebookIcon',
  side: '46px',
});

export const LinkedinIcon = makeFlatIconButton({
  icon: LinkedinSvg,
  name: 'LinkedinIcon',
  side: '46px',
});

export const GooglePlusIcon = makeFlatIconButton({
  icon: GooglePlusSvg,
  name: 'GooglePlusIcon',
  side: '46px',
});
