import * as React from 'react';
import parseHtml from 'html-react-parser';
import { ITextProps } from './interfaces';
import { Text } from './components';
import { useDOMUtils } from '../../helpers/DOMUtils/DOMUtils';

/**
 * Please note, the function this hook return might throw the exception.
 */
export function useHTMLParser() {
  const domUtils = useDOMUtils();
  return React.useCallback(
    (html: string) => {
      return parseHtml(domUtils.cleanHTML(html));
    },
    [domUtils]
  );
}

export function RichText({
  html,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dangerouslySetInnerHTML: _,
  ...props
}: {
  html: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
} & Omit<ITextProps, 'children'>) {
  const htmlParser = useHTMLParser();
  const cleanedHTML = React.useMemo(() => {
    try {
      return htmlParser(html);
    } catch (e) {
      return null;
    }
  }, [htmlParser, html]);
  if (!cleanedHTML) {
    return <Text {...props} />;
  }
  return <Text {...props}>{cleanedHTML}</Text>;
}
