import { WaitListAlternateTypeEnum } from '@usga/champadmin-api';

export const mapWaitlistAlternatesEnum = (alternate?: WaitListAlternateTypeEnum): string => {
  switch (alternate) {
    case WaitListAlternateTypeEnum.FIRSTALTERNATE:
      return '1st Alternate';
    case WaitListAlternateTypeEnum.SECONDALTERNATE:
      return '2nd Alternate';
    case WaitListAlternateTypeEnum.THIRDALTERNATE:
      return '3rd Alternate';
    default:
      return '';
  }
};
