import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Chevron } from '../Chevron';
import { FlexAlignment, FlexDirection, FlexWrapper } from '../../helpers/FlexWrapper';
import { Cursor, Text, TextCase } from '../Text';
import { Color } from '../../helpers/Fonts';
import styled from 'styled-components';
import { ScrollTo } from '../ScrollTo';
import { If } from '../../helpers';

type IUncontolledProps = {
  text?: string;
  header?: React.ReactElement;
  onChange?: (open: boolean) => void;
  isDefaultOpen?: boolean;
  removeFromDOM?: boolean;
  disableScrollOnOpen?: boolean;
};

type IProps = {
  text?: string;
  header?: React.ReactElement;
  isOpen: boolean;
  onChange: (open: boolean) => void;
  anchorRef: React.RefObject<ScrollTo>;
  removeFromDOM?: boolean;
};

const DropdownWrapper = styled(FlexWrapper)`
  border: 1px solid ${Color.DARK_DEEP_GREY};
  background-color: ${Color.LIGHT_GREY};
  padding: 10px;
  cursor: pointer;
  margin-top: -1px;
`;
const ContentWrapper = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  border: 1px solid ${Color.DARK_DEEP_GREY};
  border-top: none;
  padding: 10px 5px;
  box-sizing: border-box;
  width: 100%;
`;

const Controlled: React.FunctionComponent<IProps> = (props) => {
  const onChevronClick = useCallback(() => {}, []);
  const removeChildren = props.removeFromDOM && !props.isOpen;
  const context = useMemo(
    () => ({
      isOpen: props.isOpen,
      changeIsOpen: props.onChange,
      ref: props.anchorRef,
    }),
    [props.isOpen, props.anchorRef]
  );
  return (
    <>
      <AccordionContext.Provider value={context}>
        <DropdownWrapper
          direction={FlexDirection.ROW}
          justifyContent={FlexAlignment.BETWEEN}
          onClick={() => props.onChange(!props.isOpen)}
          backgroundColor={Color.WHITE}
          flex={'0'}
        >
          <If cond={Boolean(props.header)}>{props.header}</If>
          <If cond={Boolean(props.text)}>
            <Text fontSize={'22px'} textCase={TextCase.CAPITALIZE} cursor={Cursor.INHERIT}>
              {props.text}
            </Text>
          </If>
          <Chevron isOpened={props.isOpen} onClick={onChevronClick} />
        </DropdownWrapper>
        {!removeChildren ? (
          <ContentWrapper show={props.isOpen}>{props.children}</ContentWrapper>
        ) : null}
      </AccordionContext.Provider>
    </>
  );
};

interface IAccordionState {
  open: boolean;
}

export interface IAccordionContext {
  isOpen: boolean;
  ref: React.RefObject<ScrollTo>;
  changeIsOpen(isOpen: boolean, cb?: () => void): void;
}

export const AccordionContext = React.createContext<IAccordionContext | null>(null);

export class Accordion extends React.Component<IUncontolledProps, IAccordionState> {
  public static Controlled = Controlled;

  private anchorRef = React.createRef<ScrollTo>();

  constructor(props: IUncontolledProps) {
    super(props);
    this.state = {
      open: Boolean(props.isDefaultOpen),
    };
  }

  public render() {
    return (
      <ScrollTo disableScrollOnMount ref={this.anchorRef}>
        <Controlled
          {...this.props}
          onChange={this.setOpen}
          isOpen={this.state.open}
          anchorRef={this.anchorRef}
        />
      </ScrollTo>
    );
  }

  public setOpen = (open: boolean, cb?: () => void) =>
    new Promise<void>((resolve) => {
      {
        if (this.props.onChange) {
          this.props.onChange(open);
          this.scrollToAnchor();
          resolve();
        }
        this.setState(
          {
            open,
          },
          () => {
            this.scrollToAnchor();
            if (cb) {
              cb();
            }
            resolve();
          }
        );
      }
    });

  private scrollToAnchor = () =>
    !this.props.disableScrollOnOpen && this.anchorRef.current?.scrollToAnchor();
}
