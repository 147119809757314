import { ISelectItem } from '../../form';

export function mapObjectToSelectItems<T = string>(obj: {
  [key: string]: string;
}): ISelectItem<T>[] {
  return Object.keys(obj).map((key) => {
    return {
      id: key,
      title: obj[key],
      value: (key as unknown) as T,
    };
  });
}

type InferEnumValue<T> = T extends { [index: string]: infer I } ? I : never;

export function convertEnumName(str: string) {
  return str
    .split('_')
    .map((part) => {
      if (part.length > 1) {
        return part[0] + part.slice(1).toLowerCase();
      }
      return part;
    })
    .join(' ');
}

export function autoMapEnumToSelectItems<
  T extends { [index: string]: string },
  I extends InferEnumValue<T>
>(enumValue: T, modifier?: (value: I) => string | undefined): ISelectItem<I>[] {
  const preparedMap = Object.keys(enumValue).reduce((acc, key) => {
    const value = enumValue[key];
    acc[value] = (modifier && modifier(value as I)) || convertEnumName(value);
    return acc;
  }, {} as { [index: string]: string });

  return mapObjectToSelectItems<I>(preparedMap);
}
