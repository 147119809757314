import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EditorContext } from '@adobe/cq-react-editable-components';
import { WebAuth } from 'auth0-js';
import {
  Button,
  Color,
  FlexAlignment,
  FlexWrapper,
  PreloadedStateContext,
  QuickHit,
  isClient,
  AEMUpdateRouteContext,
} from '@usga/modules';
import { createPath, History } from 'history';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { retry, switchMap, map } from 'rxjs/operators';
import { withRouter } from 'react-router';
import { Auth0UserRolesEnum } from '@usga/champadmin-api';
import { IAppAuthOptions } from '@usga/build/entry/lib/serverRender';

const TOKEN_TYPE = 'id_token';
const AUTH_BUTTON_SIZE = { width: '270px', height: '32px' };

const AppAuthServiceContext = React.createContext<AppAuthService | null>(null);
export const AppAuthResultContext = React.createContext<IAuthResult>({});
export const useAuth0Service = () => useContext(AppAuthServiceContext);
export const withAuth0Service = <P extends { auth0: IAppAuthService }>(
  Component: React.ComponentType<P>
): ((props: Omit<P, keyof { auth0: IAppAuthService }>) => JSX.Element) => {
  return function WithAuth0Service(props) {
    const auth0 = useAuth0Service();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const AnyComponent: React.ComponentType<any> = Component;
    return <AnyComponent {...props} auth0={auth0} />;
  };
};

export type IAuthResult = {
  idToken?: string;
  idTokenPayload?: {
    sub?: string;
    exp: number;
    nonce?: string;
    'https://usga.org/roles'?: string[];
    email: string;
    updated_at: string;
  };
  state?: string;
};

export interface IAppAuthService {
  authResult: BehaviorSubject<IAuthResult>;

  checkLogin(): Promise<IAuthResult>;

  redirectLogin(): void;

  popupLogin(): Promise<IAuthResult>;

  logout(url?: string): void;

  getAuthResult(): IAuthResult;

  onAuthResultChange(fn: (result: IAuthResult) => void): Subscription;

  validateToken(authResult: IAuthResult): Promise<IAuthResult>;
}

export class AppAuthService implements IAppAuthService {
  public authResult = new BehaviorSubject<IAuthResult>({});

  private checkSessionSub$ = new BehaviorSubject<undefined | boolean>(undefined);

  private webAuth: WebAuth;

  private authScopes = ['openid', 'profile', 'email'].join(' ');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private promiseCacheStorage: Map<string, Promise<any>> = new Map();

  public constructor(private options: IAppAuthOptions, private history: History) {
    // it will mutate options
    this.webAuth = new WebAuth({ ...options });
  }

  public static isClient() {
    return isClient();
  }

  public static getCachedAuth() {
    if (process.env.CLIENT_USE_AUTH_CACHE) {
      if (AppAuthService.isClient() && window.localStorage) {
        const authString = window.localStorage.getItem('auth0-authResult');
        if (authString) {
          let authResult;
          try {
            authResult = JSON.parse(authString);
          } catch (e) {
            console.error('Failed to parse authResult', e);
          }
          if (authResult && authResult.idTokenPayload && authResult.idTokenPayload.exp) {
            const minSession = 15 * 60 * 1000; // 15 min
            if (authResult.idTokenPayload.exp * 1000 > Date.now() + minSession) {
              console.log('Using auth from cache', authResult);
              return authResult;
            }
          }
        }
      }
    } else if (process.env.NODE_ENV === 'development') {
      console.log('To use auth caching, add CLIENT_USE_AUTH_CACHE=true to your .env');
    }
    return {};
  }

  private static setCachedAuth(result: IAuthResult) {
    if (window.localStorage && process.env.CLIENT_USE_AUTH_CACHE) {
      window.localStorage.setItem('auth0-authResult', JSON.stringify(result));
    }
  }

  private static clearCachedAuth() {
    if (window.localStorage && process.env.CLIENT_USE_AUTH_CACHE) {
      window.localStorage.removeItem('auth0-authResult');
    }
  }

  redirectLogin(): void {
    if (AppAuthService.isClient()) {
      this.cachePromise(
        'redirectLogin',
        this.checkLogin()
          .then((authResult) => this.processResult(authResult))
          .catch(() => this.redirectAuthorize())
      );
    }
  }

  popupLogin(): Promise<IAuthResult> {
    return this.cachePromise(
      'popupLogin',
      this.checkLogin()
        .catch(() => this.popupAuthorize())
        .then((authResult) => this.processResult(authResult))
    );
  }

  logout(url?: string) {
    AppAuthService.clearCachedAuth();
    this.webAuth.logout({
      returnTo: url || this.getLogoutUrl(),
    });
  }

  getAuthResult(): IAuthResult {
    return this.authResult.getValue();
  }

  onAuthResultChange(fn: (result: IAuthResult) => void) {
    return this.authResult.subscribe(fn);
  }

  public wrapWithPageContext(url: string) {
    const ext = /\.html$/i.test(window.location.pathname) ? '.html' : '';
    const context = /\/content\/[^/]+\//i.exec(window.location.pathname);
    const baseUrl = context ? context[0] : '/';
    return this.getAbsoluteUrl(baseUrl + url + ext);
  }

  public getCheckSessionSub() {
    return this.checkSessionSub$.pipe(map(Boolean));
  }

  public isCheckingSession() {
    return Boolean(this.checkSessionSub$.getValue());
  }

  public checkLogin(): Promise<IAuthResult> {
    return this.cachePromise(
      'checkLogin',
      this.parseHash()
        .catch(
          () =>
            new Promise<IAuthResult>((resolve, reject) => {
              if (process.env.CLIENT_USE_AUTH_CACHE) {
                const cachedAuth = AppAuthService.getCachedAuth();
                if (cachedAuth.idToken) {
                  resolve(cachedAuth);
                  return;
                }
              }
              if (this.checkSessionSub$.getValue() === undefined) {
                this.checkSessionSub$.next(true);
              }
              this.webAuth.checkSession(
                {
                  domain: this.options.domain,
                  responseType: TOKEN_TYPE,
                  redirectUri: this.getRedirectUrl(),
                  scope: this.authScopes,
                },
                (err, authResult: IAuthResult) => {
                  if (err) {
                    console.error(err);
                    reject(err);
                  } else {
                    resolve(authResult);
                  }
                  this.checkSessionSub$.next(false);
                }
              );
            })
        )
        .then((authResult) => this.processResult(authResult))
    );
  }

  public validateToken(authResult: IAuthResult): Promise<IAuthResult> {
    if (authResult.idTokenPayload?.exp) {
      const isExpired = Math.floor(Date.now() / 1000) > authResult.idTokenPayload.exp;
      if (isExpired) {
        return Promise.reject();
      }
    }
    return Promise.resolve(authResult);
  }

  private parseHash(): Promise<IAuthResult> {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash(
        { hash: window.location.hash },
        (err, authResult: IAuthResult | null) => {
          if (err) {
            console.error(err);
            reject(err);
          } else if (!authResult) {
            if (this.authResult.getValue().idToken) {
              console.warn('Called parseHash on logged in user');
              resolve(this.authResult.getValue());
            } else {
              const hashError = new Error('no hash found');
              console.error(hashError.message);
              reject(hashError);
            }
          } else {
            this.processLoginState(authResult);
            resolve(authResult);
          }
        }
      );
    });
  }

  private cachePromise<T>(key: string, promise: Promise<T>): Promise<T> {
    const cachedPromise = this.promiseCacheStorage.get(key);
    if (cachedPromise) {
      return cachedPromise;
    } else {
      this.promiseCacheStorage.set(key, promise);
      const removeFormStorage = () => {
        this.promiseCacheStorage.delete(key);
      };
      promise.then(removeFormStorage, removeFormStorage);
      return promise;
    }
  }

  private processLoginState(authResult: IAuthResult) {
    if (authResult.state) {
      const path = atob(authResult.state);

      const isDifferentPath = isClient() && window.location.pathname !== path;
      if (isDifferentPath) {
        window.location.assign(path);
      }
    }
  }

  private processResult(authResult: IAuthResult) {
    this.authResult.next(authResult);
    AppAuthService.setCachedAuth(authResult);
    return authResult;
  }

  private popupAuthorize(): Promise<IAuthResult> {
    return new Promise((resolve, reject) => {
      this.webAuth.popup.authorize(
        {
          domain: this.options.domain,
          redirectUri: this.getRedirectUrl(),
          responseType: TOKEN_TYPE,
          scope: this.authScopes,
        },
        (err, result: IAuthResult) => {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  private getLoginUrl() {
    return this.wrapWithPageContext(this.options.loginPath);
  }

  private getLogoutUrl() {
    return this.wrapWithPageContext('player');
  }

  private getRedirectUrl() {
    return this.getAbsoluteUrl(this.options.redirectUri);
  }

  private getAbsoluteUrl(href: string) {
    const a = document.createElement('a');
    a.href = href;
    return a.href;
  }

  private redirectAuthorize(): void {
    this.webAuth.authorize({
      domain: this.options.domain,
      redirectUri: this.getLoginUrl(),
      responseType: TOKEN_TYPE,
      state: btoa(createPath(this.history.location)),
      scope: this.authScopes,
    });
  }
}

export const Auth0Provider = withRouter(function Auth0Provider(props) {
  const preloadedState = useContext(PreloadedStateContext);
  const service = useMemo(() => new AppAuthService(preloadedState.authOptions, props.history), []);
  const [authResult, setAuthResult] = useState(service.getAuthResult());
  useEffect(() => {
    service.onAuthResultChange(setAuthResult);
  }, [service]);
  if (!preloadedState.authOptions) {
    console.error('preloadedState.authOptions is empty');
    return <>{props.children}</>;
  } else {
    return (
      <AppAuthServiceContext.Provider value={service}>
        <AppAuthResultContext.Provider value={authResult}>
          <RolesProvider>{props.children}</RolesProvider>
        </AppAuthResultContext.Provider>
      </AppAuthServiceContext.Provider>
    );
  }
});

export const AuthGuard: React.FunctionComponent<{
  doAuth?: (auth: AppAuthService) => void;
  display?: boolean;
  placeholder?: JSX.Element;
}> = (props) => {
  const aemUpdateRoute = useContext(AEMUpdateRouteContext);
  const authResult = useContext(AppAuthResultContext);
  const auth = useContext(AppAuthServiceContext);

  useEffect(() => {
    aemUpdateRoute();
  }, [authResult && authResult.idToken]);

  useEffect(() => {
    if (props.doAuth) {
      if (!(authResult && authResult.idToken) && AppAuthService.isClient()) {
        if (auth) {
          props.doAuth(auth);
        } else {
          console.error('Auth service is not available');
        }
      }
    }
  }, [authResult, authResult.idToken]);

  if (props.display || (authResult && authResult.idToken)) {
    return <>{props.children}</>;
  } else {
    return props.placeholder || null;
  }
};

export const RedirectGuard: React.FunctionComponent<{ display?: boolean }> = (props) => {
  const isInEditor = React.useContext(EditorContext);
  const doAuth = useCallback(
    (auth) => {
      if (!isInEditor) {
        auth.redirectLogin();
      }
    },
    [isInEditor]
  );
  return (
    <AuthGuard doAuth={doAuth} display={props.display || false}>
      {props.children}
    </AuthGuard>
  );
};

export const GuestUserGuard: React.FunctionComponent<{
  callback?: (authResult: IAuthResult) => void;
}> = (props) => {
  const auth = useContext(AppAuthServiceContext);
  const authResult = useContext(AppAuthResultContext);
  React.useEffect(() => {
    const result = authResult.idToken
      ? Promise.resolve(authResult)
      : auth?.checkLogin().catch((err) => {
          console.warn(err);
          return {};
        });
    if (!result) {
      throw new Error('Auth is unavailable');
    }
    result.then(props.callback);
  }, []);

  return <>{props.children}</>;
};

/**
 * Unused for now, but preserved for further usage
 * @param props
 */
export const PopupGuard: React.FunctionComponent<{ display?: boolean }> = (props) => {
  const doAuth = useCallback((auth) => {
    of(() => auth.popupLogin())
      .pipe(
        switchMap((action) => action()),
        retry(1)
      )
      .subscribe();
  }, []);
  return (
    <AuthGuard doAuth={doAuth} display={props.display ?? false}>
      {props.children}
    </AuthGuard>
  );
};

const AppAuthRolesContext = React.createContext<string[]>([]);

const RolesProvider: React.FunctionComponent = ({ children }) => {
  const authResult = useContext(AppAuthResultContext);
  let roles: string[] = [];
  if (
    authResult &&
    authResult.idTokenPayload &&
    authResult.idTokenPayload['https://usga.org/roles']
  ) {
    roles = authResult.idTokenPayload['https://usga.org/roles'];
  }
  return <AppAuthRolesContext.Provider value={roles}>{children}</AppAuthRolesContext.Provider>;
};

const LogoutButton = ({ url }: { url?: string }) => {
  const auth = useAuth0Service();
  const handleClick = React.useCallback(() => {
    if (!auth) {
      return;
    }
    auth.logout(url);
  }, [auth]);

  return (
    <Button onClick={handleClick} color={Color.PRIMARY} size={AUTH_BUTTON_SIZE}>
      Logout
    </Button>
  );
};

export const AccessDeniedErrorComponent = ({ children }: React.PropsWithChildren<object>) => {
  return (
    <FlexWrapper alignItems={FlexAlignment.CENTER}>
      <QuickHit borderColor={Color.ALERT}>
        {children}
        <FlexWrapper>
          <LogoutButton />
        </FlexWrapper>
      </QuickHit>
    </FlexWrapper>
  );
};

export const RoleGuard: React.FunctionComponent<{ role: Auth0UserRolesEnum }> = ({
  role,
  children,
}) => {
  const roles = useContext(AppAuthRolesContext);
  if (roles.indexOf(role) < 0) {
    return (
      <AccessDeniedErrorComponent>
        You have no permission to visit this page.
      </AccessDeniedErrorComponent>
    );
  } else {
    return <>{children}</>;
  }
};

export const useAuthResultSubject = () => {
  const auth0 = useAuth0Service();

  const subject$ = React.useMemo(() => {
    return new BehaviorSubject<IAuthResult | undefined>(auth0?.getAuthResult());
  }, [auth0]);

  React.useEffect(() => {
    auth0?.onAuthResultChange((authResult) => {
      subject$.next(authResult);
    });
  }, [subject$, auth0]);

  return subject$;
};

export const useAuthResult = () => {
  const authResultSubject$ = useAuthResultSubject();
  const [authResult, setAuthResult] = React.useState<IAuthResult | undefined>(undefined);

  React.useEffect(() => {
    const sub$ = authResultSubject$.subscribe(setAuthResult);

    return () => {
      sub$.unsubscribe();
    };
  }, [authResultSubject$]);

  return authResult;
};

export const useIsCheckingSession = () => {
  const auth0 = useAuth0Service();
  const [isChecking, setIsChecking] = React.useState(Boolean(auth0?.isCheckingSession()));
  React.useEffect(() => {
    if (!auth0) {
      return;
    }
    const sub$ = auth0.getCheckSessionSub().subscribe(setIsChecking);
    return () => {
      sub$.unsubscribe();
    };
  }, [auth0]);

  return isChecking;
};
