import moment, { Moment } from 'moment';

abstract class DateProvider {
  protected readonly minutesOffset = moment().utcOffset();

  protected readonly source: Moment;

  constructor(source: Moment) {
    this.source = source;
  }

  public getDateObject(date: Date | undefined) {
    if (!date) {
      return undefined;
    }

    return this.source
      .year(date.getFullYear())
      .month(date.getMonth())
      .date(date.getDate())
      .toDate();
  }

  public getDateObjectLocal(date: Date | undefined) {
    if (!date) {
      return undefined;
    }

    const utcOffsetMinutes = moment(date).utcOffset();

    const localDateWithoutOffset = moment(date);

    const localDateMoment =
      utcOffsetMinutes > 0
        ? localDateWithoutOffset.subtract(Math.abs(utcOffsetMinutes), 'minutes')
        : utcOffsetMinutes < 0
        ? localDateWithoutOffset.add(Math.abs(utcOffsetMinutes), 'minutes')
        : localDateWithoutOffset;

    return this.getDateObject(localDateMoment.toDate());
  }
}

export class StartOfDayProvider extends DateProvider {
  constructor(source: Moment) {
    super(
      moment(source)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
    );
  }
}

export class EndOfDayProvider extends DateProvider {
  constructor(source: Moment) {
    super(
      moment(source)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)
    );
  }
}
