import * as React from 'react';
import styled from 'styled-components';

const DEFAULT_WIDTH = '1250px';

interface ILayoutOneColumnProps {
  width?: string;
}

export const LayoutOneColumn = styled.div<React.PropsWithChildren<ILayoutOneColumnProps>>`
  width: 100%;
  max-width: ${(props) => props.width || DEFAULT_WIDTH};
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
`;
