import styled from 'styled-components';

interface IProps {
  borderColor?: string;
}

export const QuickHit = styled.a<IProps>`
  display: block;
  padding: 16px;
  background-color: #fff;
  position: relative;
  margin: 0 0 2px 0;
  text-decoration: none;
  border-left: 3px solid ${(props) => props.borderColor || '#c7c9c8'};
  cursor: pointer;
`;
