import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: contents;
`;

export interface IReactSelectEventsMap {
  reactselect: Event;
}

interface ICustomElementEventMap extends HTMLElementEventMap, IReactSelectEventsMap {}

export interface IBubbledEventHandler<K extends keyof ICustomElementEventMap>
  extends React.HTMLAttributes<HTMLDivElement> {
  handler: (ev: ICustomElementEventMap[K]) => unknown;
}

/**
 * This component handles the bubbled event described in the `event` param
 * the only reason why is it done with the native events is that React
 * does not support some events such as onFocusIn/onFocusOut
 * (https://github.com/facebook/react/issues/6410)
 * These events should be used to handle bubbled events from inputs
 * And, since the onBlur and onFocus events does not bubble
 * (https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event),
 * the native onFocusOut is used.
 * @param event event type
 */
export const makeBubbledEventHandler = <K extends keyof ICustomElementEventMap>(
  event: K,
  options?: EventListenerOptions
) => {
  return class BubbledEventHandler extends React.Component<IBubbledEventHandler<K>> {
    private div?: HTMLDivElement;

    public componentWillUnmount() {
      this.div?.removeEventListener(
        event as keyof HTMLElementEventMap,
        this.eventListener as (this: HTMLDivElement, ev: Event) => unknown,
        options
      );
    }

    public render() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { handler, ...rest } = this.props;
      return (
        <Container {...rest} ref={this.handleRef}>
          {this.props.children}
        </Container>
      );
    }

    private eventListener = (ev: ICustomElementEventMap[K]) => {
      this.props.handler(ev);
      return true;
    };

    private handleRef = (div: HTMLDivElement) => {
      if (this.div !== div) {
        this.div?.removeEventListener(
          event as keyof HTMLElementEventMap,
          this.eventListener as (this: HTMLDivElement, ev: Event) => unknown,
          options
        );
      }

      this.div = div;
      if (this.div) {
        this.div.addEventListener(
          event as keyof HTMLElementEventMap,
          this.eventListener as (this: HTMLDivElement, ev: Event) => unknown,
          options
        );
      }
    };
  };
};
