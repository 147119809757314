import * as React from 'react';
import { FlexWrap, FlexDirection, FlexAlignment } from './interfaces';
import { FlexWrapper } from './index';
import { Color } from '../Fonts';

type Position =
  | 'top left'
  | 'top center'
  | 'top right'
  | 'center left'
  | 'center center'
  | 'center right'
  | 'center between'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right';

interface IPositionValue {
  alignItems: FlexAlignment;
  justifyContent: FlexAlignment;
}

const ColumnPositions: Record<Position, IPositionValue> = {
  'top left': {
    alignItems: FlexAlignment.START,
    justifyContent: FlexAlignment.START,
  },
  'top center': {
    alignItems: FlexAlignment.CENTER,
    justifyContent: FlexAlignment.START,
  },
  'top right': {
    alignItems: FlexAlignment.END,
    justifyContent: FlexAlignment.START,
  },
  'center left': {
    alignItems: FlexAlignment.START,
    justifyContent: FlexAlignment.CENTER,
  },
  'center center': {
    alignItems: FlexAlignment.CENTER,
    justifyContent: FlexAlignment.CENTER,
  },
  'center right': {
    alignItems: FlexAlignment.END,
    justifyContent: FlexAlignment.CENTER,
  },
  'center between': {
    alignItems: FlexAlignment.BETWEEN,
    justifyContent: FlexAlignment.CENTER,
  },
  'bottom left': {
    alignItems: FlexAlignment.START,
    justifyContent: FlexAlignment.END,
  },
  'bottom center': {
    alignItems: FlexAlignment.CENTER,
    justifyContent: FlexAlignment.END,
  },
  'bottom right': {
    alignItems: FlexAlignment.END,
    justifyContent: FlexAlignment.END,
  },
};

const RowPositions: Record<Position, IPositionValue> = {
  'top left': {
    alignItems: FlexAlignment.START,
    justifyContent: FlexAlignment.START,
  },
  'top center': {
    alignItems: FlexAlignment.START,
    justifyContent: FlexAlignment.CENTER,
  },
  'top right': {
    alignItems: FlexAlignment.START,
    justifyContent: FlexAlignment.END,
  },
  'center left': {
    alignItems: FlexAlignment.CENTER,
    justifyContent: FlexAlignment.START,
  },
  'center center': {
    alignItems: FlexAlignment.CENTER,
    justifyContent: FlexAlignment.CENTER,
  },
  'center right': {
    alignItems: FlexAlignment.CENTER,
    justifyContent: FlexAlignment.END,
  },
  'center between': {
    alignItems: FlexAlignment.CENTER,
    justifyContent: FlexAlignment.BETWEEN,
  },
  'bottom left': {
    alignItems: FlexAlignment.END,
    justifyContent: FlexAlignment.START,
  },
  'bottom center': {
    alignItems: FlexAlignment.END,
    justifyContent: FlexAlignment.CENTER,
  },
  'bottom right': {
    alignItems: FlexAlignment.END,
    justifyContent: FlexAlignment.END,
  },
};

interface IProps {
  wrap?: boolean;
  margin?: string;
  padding?: string;
  position?: Position;
  flex?: string;
  basis?: string;
  backgroundColor?: Color;
  maxWidth?: string;
}

export const Column = ({
  children,
  margin,
  padding,
  wrap = false,
  position = 'top left',
  flex,
  basis,
  backgroundColor,
  maxWidth,
}: React.PropsWithChildren<IProps>) => (
  <FlexWrapper
    flex={flex}
    basis={basis}
    margin={margin}
    padding={padding}
    maxWidth={maxWidth}
    wrap={wrap ? FlexWrap.WRAP : FlexWrap.NOWRAP}
    direction={FlexDirection.COLUMN}
    backgroundColor={backgroundColor}
    {...ColumnPositions[position]}
  >
    {children}
  </FlexWrapper>
);

export const Row = ({
  children,
  margin,
  padding,
  wrap = false,
  position = 'top left',
  flex,
  basis,
  backgroundColor,
  maxWidth,
}: React.PropsWithChildren<IProps>) => (
  <FlexWrapper
    flex={flex}
    basis={basis}
    margin={margin}
    padding={padding}
    maxWidth={maxWidth}
    wrap={wrap ? FlexWrap.WRAP : FlexWrap.NOWRAP}
    direction={FlexDirection.ROW}
    backgroundColor={backgroundColor}
    {...RowPositions[position]}
  >
    {children}
  </FlexWrapper>
);
