import * as React from 'react';
import { Subject } from 'rxjs';
import { MessageBusEvents } from './message-bus-types';

const MessageBusContext = React.createContext(new Subject<MessageBusEvents>());

export const useMessageBus = () => {
  return React.useContext(MessageBusContext);
};

export interface IWithMessageBus {
  message$: Subject<MessageBusEvents>;
}

export const withMessageBus = <P extends {}>(
  Component: React.ComponentType<P & IWithMessageBus>
) => (props: P) => (
  <MessageBusContext.Consumer>
    {(messageBusProps) => <Component {...props} message$={messageBusProps} />}
  </MessageBusContext.Consumer>
);
