import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Color } from '../../helpers/Fonts';

interface IProps {
  color?: Color;
  to: string;
  children: string | JSX.Element;
  target?: string;
}

const StyledLink = styled(Link)`
  color: ${(props) => props.color || 'inherit'};
`;

export function RouterLink(props: IProps) {
  const { color, to, target } = props;

  return (
    <StyledLink color={color} to={to} target={target}>
      {props.children}
    </StyledLink>
  );
}
