import { PlayerStatusEnum } from '@usga/champadmin-api';

export const mapPlayerStatus = (playerStatus?: PlayerStatusEnum) => {
  switch (playerStatus) {
    case PlayerStatusEnum.AMATEUR:
      return 'Amateur';
    case PlayerStatusEnum.APPLICANTFORREINSTATEMENT:
      return 'Applicant for Reinstatement';
    case PlayerStatusEnum.PROFESSIONAL:
      return 'Professional';
    default:
      return '';
  }
};

export const abbreviatePlayerStatus = (playerStatus: PlayerStatusEnum) => {
  switch (playerStatus) {
    case PlayerStatusEnum.AMATEUR:
      return 'A';
    case PlayerStatusEnum.APPLICANTFORREINSTATEMENT:
      return 'AR';
    case PlayerStatusEnum.PROFESSIONAL:
      return 'P';
    default:
      return '';
  }
};
