import styled, { css } from 'styled-components';
import { Color } from '../../helpers';

export const TextWrapper = styled.div`
  background-color: ${Color.BLACK};
  border-radius: 4px;
  opacity: 0.9;
  color: ${Color.WHITE};
  padding: 4px;
`;

const sharedArrowStyles = css`
  width: 8px;
  height: 8px;
  opacity: 0.9;
  box-sizing: border-box;
`;

export const TopArrow = styled.div`
  ${sharedArrowStyles}
  margin-top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid ${Color.BLACK};
  border-top: 8px solid transparent;
`;

export const BottomArrow = styled.div`
  ${sharedArrowStyles}
  margin-bottom: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-top: 8px solid ${Color.BLACK};
`;

export const LeftArrow = styled.div`
  ${sharedArrowStyles}
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid ${Color.BLACK};
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
`;

export const RightArrow = styled.div`
  ${sharedArrowStyles}
  margin-right: -8px;
  border-left: 8px solid ${Color.BLACK};
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
`;
