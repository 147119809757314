import * as React from 'react';
import styled from 'styled-components';
import { FlexDirection, FlexWrapper } from '../../helpers/FlexWrapper';
import { SearchIcon as DefaultSearchIcon } from '../Icon';
import { Input as DefaultInput } from '../../form/Input';
import { Color } from '../../helpers/Fonts';
import { isMobile } from '../../helpers/Detect';

interface IProps {
  onChange?: (value: string) => void;
  value?: string;
  placeholder?: string;
  search?: (searchString: string) => void;
  isClearable?: boolean;
  skipSearchOnChange?: boolean;
}

const InputWrapper = styled(FlexWrapper)`
  flex-basis: 100%;
  position: relative;
  padding: 0 10px;
`;

const Input = styled(DefaultInput)`
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: rgb(224, 224, 225);
  margin: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 12px;
  width: 20px;
  height: 20px;
  z-index: 1;

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 14px;
    width: 2px;
    background-color: ${Color.BLACK};
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const SearchIcon = styled(
  DefaultSearchIcon as React.ComponentType<React.ComponentProps<typeof DefaultSearchIcon>>
)`
  position: absolute;
  right: 20px;
  top: 10px;
  width: 20px;
  height: 20px;
  z-index: 1;
`;

export function Searchbar(props: IProps) {
  const [isFocused, setIsFocused] = React.useState(false);
  const placeholder = isFocused ? '' : props.placeholder;
  const [value, setValue] = React.useState(props.value);

  const enableFocus = React.useCallback(() => {
    setIsFocused(true);
  }, []);
  const disableFocus = React.useCallback(() => {
    setIsFocused(false);
  }, []);

  const onKeyDown = React.useCallback(
    (e) => {
      if (e.keyCode === 13 && props.onChange) {
        props.onChange && props.onChange(value ?? '');
        e.target.blur();
      }
    },
    [value]
  );

  const onChange = React.useCallback(
    (e) => {
      setValue(e.target.value);
      if (props.skipSearchOnChange) {
        return;
      }
      props.onChange && props.onChange(e.target.value);
    },
    [props.onChange]
  );

  const resetSearch = React.useCallback((e) => {
    e.stopPropagation();

    if (props.onChange) {
      props.onChange('');
    }
    if (props.search) {
      props.search('');
    }
  }, []);

  const onSearchClick = React.useCallback(() => {
    if (props.skipSearchOnChange) {
      props.onChange && props.onChange(value || '');
      return;
    }

    if (!isMobile()) {
      return;
    }

    props.search && props.search(props.value ?? '');
  }, [props.value, value]);

  return (
    <InputWrapper direction={FlexDirection.ROW}>
      <Input
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        onFocus={enableFocus}
        onBlur={disableFocus}
        onKeyDown={onKeyDown}
      />
      {props.isClearable && props.value ? (
        <CloseButton onClick={resetSearch} />
      ) : (
        <SearchIcon icon={'search'} color={Color.PRIMARY} onClick={onSearchClick} />
      )}
    </InputWrapper>
  );
}
