import * as React from 'react';
import styled, { css } from 'styled-components';
import { TooltipPosition } from './interfaces';
import { TextWrapper, TopArrow, BottomArrow, LeftArrow, RightArrow } from './controls';

interface ITooltipChildren {
  children: string;
}

const sharedWrappersStyle = css`
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const BottomTooltipWrapper = styled.div`
  ${sharedWrappersStyle}
  top: 100%;
  flex-direction: column;
`;

const TopTooltipWrapper = styled.div`
  ${sharedWrappersStyle}
  bottom: 100%;
  flex-direction: column;
`;

const LeftTooltipWrapper = styled.div`
  ${sharedWrappersStyle}
  right: 100%;
  flex-direction: row;
`;

const RightTooltipWrapper = styled.div`
  ${sharedWrappersStyle}
  left: 100%;
  flex-direction: row;
`;

const BottomTooltip = ({ children }: ITooltipChildren) => (
  <BottomTooltipWrapper>
    <TopArrow />
    <TextWrapper>{children}</TextWrapper>
  </BottomTooltipWrapper>
);

const TopTooltip = ({ children }: ITooltipChildren) => (
  <TopTooltipWrapper>
    <TextWrapper>{children}</TextWrapper>
    <BottomArrow />
  </TopTooltipWrapper>
);

const LeftTooltip = ({ children }: ITooltipChildren) => (
  <LeftTooltipWrapper>
    <TextWrapper>{children}</TextWrapper>
    <RightArrow />
  </LeftTooltipWrapper>
);

const RightTooltip = ({ children }: ITooltipChildren) => (
  <RightTooltipWrapper>
    <LeftArrow />
    <TextWrapper>{children}</TextWrapper>
  </RightTooltipWrapper>
);

export const tooltipsByPosition: Record<TooltipPosition, React.ComponentType<ITooltipChildren>> = {
  bottom: BottomTooltip,
  top: TopTooltip,
  left: LeftTooltip,
  right: RightTooltip,
};
