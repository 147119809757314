import * as React from 'react';
import { Observable, of } from 'rxjs';

export interface IChangesTrackerContextValue {
  setChanged: () => void;
  flushChanges: () => void;
  hasChanges: () => boolean;
  getMessage: () => string | undefined;
  observeHasChanges: () => Observable<boolean>;
}

export const ChangesTrackerContext = React.createContext<IChangesTrackerContextValue>({
  flushChanges: () => {},
  setChanged: () => {},
  hasChanges: () => false,
  getMessage: () => undefined,
  observeHasChanges: () => of(false),
});
