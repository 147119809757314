import * as React from 'react';
import { FlexAlignment, FlexWrapper, IFlexWrapperProps } from '../../helpers';

export const FieldWrapper = (props: IFlexWrapperProps) => (
  <FlexWrapper {...props}>{props.children}</FlexWrapper>
);

FieldWrapper.defaultProps = {
  width: '100%',
  padding: '10px',
  alignItems: FlexAlignment.START,
  justifyContent: FlexAlignment.START,
};
