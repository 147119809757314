import * as React from 'react';
import styled from 'styled-components';

export type ScrollEdge = 'top' | 'bottom';

interface IDivProps {
  height?: string;
  width?: string;
  position?: string;
  left?: string;
  top?: string;
  right?: string;
  bottom?: string;
}

interface IScrollContainerProps extends IDivProps {
  onScrollToEdge: (edge: ScrollEdge, evt: React.UIEvent<HTMLDivElement>) => void;
}

const InnverDiv = styled.div<IDivProps>`
  overflow-y: auto;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
`;

export const ScrollContainer = ({
  height,
  width,
  position,
  bottom,
  left,
  right,
  top,
  onScrollToEdge,
  children,
}: React.PropsWithChildren<IScrollContainerProps>) => {
  const handleScroll = React.useCallback(
    (evt: React.UIEvent<HTMLDivElement>) => {
      evt.persist();
      if (
        evt.currentTarget.scrollTop + evt.currentTarget.getBoundingClientRect().height >=
        evt.currentTarget.scrollHeight
      ) {
        onScrollToEdge('bottom', evt);
      } else if (evt.currentTarget.scrollTop === 0) {
        onScrollToEdge('top', evt);
      }
    },
    [onScrollToEdge]
  );

  return (
    <InnverDiv
      height={height}
      width={width}
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      position={position}
      onScroll={handleScroll}
    >
      {children}
    </InnverDiv>
  );
};
