import * as React from 'react';
import styled from 'styled-components';

interface IFieldSetProps {
  onChange?: (evt: React.ChangeEvent<HTMLFieldSetElement>) => void;
}

export const FieldSet = styled.fieldset<IFieldSetProps>`
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  display: block;
`;
