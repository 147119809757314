import { QualifyingStatusEnum } from '@usga/champadmin-api';

export const mapQualifyingStatus = (qualifyingStatus: QualifyingStatusEnum) => {
  switch (qualifyingStatus) {
    case QualifyingStatusEnum.DECLINEDSPOT:
      return 'Declined Spot';
    case QualifyingStatusEnum.FINALSTAGEFINALIST:
      return 'Finalist';
    case QualifyingStatusEnum.FINALSTAGEFINALIST1STALTERNATE:
      return 'Finalist – 1st Alternate';
    case QualifyingStatusEnum.FINALSTAGEFINALIST2NDALTERNATE:
      return 'Finalist – 2nd Alternate';
    case QualifyingStatusEnum.FINALSTAGEQUALIFYING:
      return 'Final Qualifying';
    case QualifyingStatusEnum.FIRSTSTAGEQUALIFYING:
      return 'Local Qualifying';
    case QualifyingStatusEnum.FIRSTSTAGEQUALIFYING1STALTERNATE:
      return 'Final Qualifying – 1st Alternate';
    case QualifyingStatusEnum.FIRSTSTAGEQUALIFYING2NDALTERNATE:
      return 'Final Qualifying – 2nd Alternate';
    case QualifyingStatusEnum.SINGLESTAGEFINALIST:
      return 'Finalist';
    case QualifyingStatusEnum.SINGLESTAGEFINALIST1STALTERNATE:
      return 'Finalist – 1st Alternate';
    case QualifyingStatusEnum.SINGLESTAGEFINALIST2NDALTERNATE:
      return 'Finalist – 2nd Alternate';
    case QualifyingStatusEnum.SINGLESTAGEQUALIFYING:
      return 'Qualifying';
    case QualifyingStatusEnum.STROKEPOLICY:
      return 'Stroke Policy';
    default:
      return '';
  }
};
