import * as React from 'react';
import { If } from '@usga/modules';
import {
  TwoColumnsLayoutModelMainSideEnum,
  TwoColumnsLayoutModelTabModeEnum,
} from '@usga/champadmin-api';
import { IContainerProps, ITab, ITabsActivity } from './interfaces';
import { LDownHiddenColumn, LayoutCustomTabs, LayoutTwoColumnsWrapper } from './components';
import * as Utils from './utils';

interface IProps extends IContainerProps {
  tabs: ITab[];
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  children?: null;
  mainSide: string;
  tabMode?: TwoColumnsLayoutModelTabModeEnum;
}

export function LayoutTwoColumns(props: IProps) {
  const [activeTab, setActiveTab] = React.useState(TwoColumnsLayoutModelMainSideEnum.LEFT);

  const handleTabClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.id;
    if (Utils.isVisibleLayoutSide(id)) {
      setActiveTab(id);
    } else {
      console.error(id + ' is not VisibleLayoutSide');
    }
  }, []);

  const tabsActivity = React.useMemo<ITabsActivity>(() => {
    return Utils.getTabActivity(activeTab, props.tabMode, props.mainSide);
  }, [activeTab, props.tabMode, props.mainSide]);

  return (
    <React.Fragment>
      <If cond={tabsActivity.showTabs}>
        <LayoutCustomTabs activeTab={activeTab} tabs={props.tabs} handleClick={handleTabClick} />
      </If>
      <LayoutTwoColumnsWrapper width={props.width}>
        <LDownHiddenColumn
          active={tabsActivity[TwoColumnsLayoutModelMainSideEnum.LEFT]}
          fullWidth={props.mainSide === TwoColumnsLayoutModelMainSideEnum.LEFT}
          as={props.mainSide === TwoColumnsLayoutModelMainSideEnum.LEFT ? 'main' : 'aside'}
        >
          {props.leftComponent}
        </LDownHiddenColumn>
        <LDownHiddenColumn
          active={tabsActivity[TwoColumnsLayoutModelMainSideEnum.RIGHT]}
          fullWidth={props.mainSide === TwoColumnsLayoutModelMainSideEnum.RIGHT}
          as={props.mainSide === TwoColumnsLayoutModelMainSideEnum.RIGHT ? 'main' : 'aside'}
        >
          {props.rightComponent}
        </LDownHiddenColumn>
      </LayoutTwoColumnsWrapper>
    </React.Fragment>
  );
}
