import * as React from 'react';
import { Color } from '../../helpers/Fonts';

export interface IInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'dangerouslySetInnerHTML'> {
  width?: string;
  height?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  className?: string;
  label?: string;
  labelMode?: LabelMode;
  mask?: (string | RegExp)[] | ((rawValue: string) => (string | RegExp)[]);
  guide?: boolean;
  image?: JSX.Element;
  borderColor?: Color;
  ref?: React.Ref<HTMLInputElement>;
  min?: number;
  max?: number;
}

export enum LabelMode {
  FLOAT = 'float',
  STATIC = 'static',
}
