import { IDataColumn } from '../../modules/Table';

abstract class ArrayFormatter<T extends object> {
  private readonly columns: Array<IDataColumn<T>>;

  protected abstract joinItemsWith: string;

  protected abstract joinRowsWith: string;

  constructor(columns: Array<IDataColumn<T>>) {
    this.columns = columns;
  }

  public formatData(data: Array<T>) {
    const header = this.columns.map((column) => {
      const title = column.title;
      if (typeof title !== 'string') {
        throw new Error(
          `Column title should be a string, but got: ${typeof title}. Check your columns array.`
        );
      }
      return this.cleanupData(String(title));
    });

    const body = data.map((item) =>
      this.columns.map((column) => this.cleanupData(String(column.data(item))))
    );

    const rows = [header, ...body].map((row) => row.join(',')).join('\n');

    return rows;
  }

  protected abstract cleanupData(data: string): string;
}

export class CSVFormatter<T extends object> extends ArrayFormatter<T> {
  protected joinItemsWith = ',';

  protected joinRowsWith = '\n';

  protected cleanupData(data: string) {
    return `"${data.replace('"', '""')}"`;
  }
}
