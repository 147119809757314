import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../../helpers/Fonts';
import { MinWidthMediaRule, formatMediaRule } from '../../helpers';
import { Text } from '../Text';

const ARROW_IN_THE_MIDDLE_SCREEN_SIZE = 1240;
const arrowInTheMiddleMediaQuery = formatMediaRule(
  new MinWidthMediaRule(ARROW_IN_THE_MIDDLE_SCREEN_SIZE)
);

export const InfoText = styled(Text as React.ComponentType<React.ComponentProps<typeof Text>>)`
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: #fff;
  width: 290px;
  padding: 20px 25px;
  margin-bottom: 16px;
  border: 1px solid ${Color.DARK_GREY};
  transform: translate(0, -10px);
  transition-duration: 200ms;
  visibility: hidden;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    border-top: 10px solid ${Color.DARK_GREY};
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-100%, 1px);
    z-index: 0;
  }

  &::before {
    transform: translate(-100%, 0);
    border-top-color: #fff;
    z-index: 1;
  }

  ${arrowInTheMiddleMediaQuery} {
    right: auto;
    left: 50%;
    transform: translate(-50%, -10px);

    &::before,
    &::after {
      left: 50%;
      transform: translate(-50%, 1px);
    }

    &::before {
      transform: translate(-50%, 0);
    }
  }
`;

export const InfoWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:hover {
    ${InfoText} {
      visibility: visible;
      transform: translate(0, 0);
    }
  }

  ${arrowInTheMiddleMediaQuery} {
    &:hover {
      ${InfoText} {
        transform: translate(-50%, 0);
      }
    }
  }
`;
