import * as React from 'react';

interface IProps<V, R> {
  value: V;
  mapper: (value: V) => R;
  children: (result: R) => React.ReactNode;
}

export const Lens = <V, R>({ children, mapper, value }: IProps<V, R>) => {
  const result = React.useMemo(() => {
    return mapper(value);
  }, [mapper, value]);

  return <>{children(result)}</>;
};
