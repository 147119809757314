import * as React from 'react';
import { ButtonLabel } from './index';

interface IProps {
  onUpload: (files: FileList) => void;
  children?: React.ReactNode;
  format: string;
  className?: string;
  disabled?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

export function UploadButton(props: IProps) {
  const onUpload = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.files) {
        props.onUpload(e.currentTarget.files);
      }
    },
    [props.onUpload]
  );

  const onClick = React.useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.currentTarget.value = '';
  }, []);

  return (
    <ButtonLabel className={props.className} disabled={props.disabled}>
      {props.children || 'UPLOAD'}
      <input
        type={'file'}
        disabled={props.disabled}
        onChange={onUpload}
        onClick={onClick}
        hidden
        accept={props.format}
        ref={props.inputRef}
      />
    </ButtonLabel>
  );
}
