import styled from 'styled-components';
import * as React from 'react';
import { Color } from '../../helpers/Fonts';
import { FlexAlignment, FlexWrapper, IFlexWrapperProps } from '../../helpers/FlexWrapper';

type IProps = IFlexWrapperProps;

export const SpinnerSvg = () => (
  <StyledSpinner viewBox="0 0 50 50">
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
  </StyledSpinner>
);

export const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  margin: 40px;
  width: 50px;
  height: 50px;
  z-index: 1;

  & .path {
    stroke: ${Color.PRIMARY};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const Spinner: React.FunctionComponent<IProps> = (props) => {
  return (
    <FlexWrapper alignItems={FlexAlignment.CENTER} {...props}>
      <SpinnerSvg />
    </FlexWrapper>
  );
};
