import memoize from 'lodash/memoize';
import { QualifyingLevelEnum } from '@usga/champadmin-api';

type WithLevel = { level: QualifyingLevelEnum };

export const areAllOfSingleStageType = memoize(function<T extends WithLevel>(items: T[]) {
  return items.every((item) => item.level === QualifyingLevelEnum.SINGLESTAGE);
});

export const getLocal = memoize(function<T extends WithLevel>(items: T[]) {
  return items.filter((item) => item.level === QualifyingLevelEnum.FIRSTSTAGE);
});

export const getFinal = memoize(function<T extends WithLevel>(items: T[]) {
  return items.filter((item) => item.level === QualifyingLevelEnum.FINALSTAGE);
});

/**
 * Converts a number to Its numeral representation
 * If the source number us not integer, this method will take only integer part
 *
 * @param num source number
 * @returns numeral representation
 */
export function getSerialNumber(num: number) {
  if (num !== num) {
    throw new Error("Can't convert NaN value");
  }
  const intNumber = Math.round(num);
  const numAsString = intNumber.toString();
  const lastDigit = numAsString[numAsString.length - 1];

  switch (lastDigit) {
    case '1':
      return `${numAsString}st`;
    case '2':
      return `${numAsString}nd`;
    case '3':
      return `${numAsString}rd`;
    default: {
      return `${numAsString}th`;
    }
  }
}
