import * as React from 'react';
import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ChangesTrackerContext, IChangesTrackerContextValue } from './ChangesTrackerContext';
import { QuestionsContext } from './QuestionsContext';
import { useQuestions } from '../../components/admin/QuestionModal';

const { Provider } = ChangesTrackerContext;

const QUESTION = 'Are you sure you want to leave this page? The changes you made will be lost.';

interface IHasUnsaved {
  hasUnsaved: true;
  confirmationMessage: string;
}

interface INoUnsaved {
  hasUnsaved: false;
  confirmationMessage: undefined;
}

type UnsavedData = IHasUnsaved | INoUnsaved;

const noUnsaved: INoUnsaved = {
  hasUnsaved: false,
  confirmationMessage: undefined,
};

function ContextValue(): IChangesTrackerContextValue {
  const unsavedData = new BehaviorSubject<UnsavedData>(noUnsaved);

  function setChanged() {
    unsavedData.next({
      hasUnsaved: true,
      confirmationMessage: QUESTION,
    });
  }

  function flushChanges() {
    unsavedData.next(noUnsaved);
  }

  function hasChanges() {
    return unsavedData.getValue().hasUnsaved;
  }

  function getMessage() {
    return unsavedData.getValue().confirmationMessage;
  }

  function observeHasChanges() {
    return unsavedData.pipe(
      map(({ hasUnsaved }) => hasUnsaved),
      distinctUntilChanged()
    );
  }

  return {
    setChanged,
    flushChanges,
    hasChanges,
    getMessage,
    observeHasChanges,
  };
}

export function ChangesTrackerProvider({ children }: React.PropsWithChildren<object>) {
  const contextValueRef = React.useRef(ContextValue());
  const questions = useQuestions();

  return (
    <QuestionsContext.Provider value={questions}>
      <Provider value={contextValueRef.current}>{children}</Provider>
      {questions.jsxItems}
    </QuestionsContext.Provider>
  );
}
