import * as React from 'react';
import { findDOMNode } from 'react-dom';
import ReactSelect from 'react-select';
import { FlexWrapper } from '../../helpers/FlexWrapper';
import { IReactSelectEventsMap } from '../../components/BubbledEventHandler';
import { StyledSelect, reactSelectComponentsStyles } from './components';

type IExtractProps<T> = T extends React.Component<infer U> ? U : never;

export type ISelectValue<T> = null | {
  label: string | T;
  value: T;
  isDisabled?: boolean;
};

export interface ISelectItem<T = string> {
  id: string;
  title: string;
  value: T;
  isDisabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISelectProps<T = string | null> extends IExtractProps<ReactSelect<any>> {
  // required for SSR
  id: string;
  items: ISelectItem<T>[];
  customAutocomplete?: boolean;
  noneValue?: string;
  allowCheckAll?: boolean;
  filterFn?: (item: T, inputVal: string) => boolean;
}

const SELECT_ALL_OPTION = {
  label: 'Check all',
  value: '*',
};

const UNSELECT_ALL_OPTION = {
  label: 'Uncheck all',
  value: '-',
};

const mapItemToValue = <T extends unknown>(item: ISelectItem<T>): ISelectValue<T> => ({
  label: item.title,
  value: item.value,
  isDisabled: item.isDisabled,
});

const SelectInner = React.forwardRef(function SelectRefForwardingComponent<T extends unknown>(
  props: ISelectProps<T>,
  ref: unknown
) {
  const { id, val, items, noneValue, allowCheckAll, styles, ...selectProps } = props;

  const convertItemToValue = React.useCallback(
    (v: T): ISelectValue<T> => {
      const item = items.find((it) => it.value === v);
      if (!item) {
        if (v) {
          return {
            label: v,
            value: v,
          };
        } else {
          // null is required to show placeholder
          return null;
        }
      }

      return mapItemToValue(item);
    },
    [items]
  );

  const value = React.useMemo(() => {
    if (Array.isArray(val)) {
      return val.map((v) => convertItemToValue(v));
    } else {
      return convertItemToValue(val);
    }
  }, [val, convertItemToValue]);

  const options = React.useMemo(() => {
    const values = items.map(mapItemToValue);

    if (noneValue) {
      return [{ label: noneValue, value: undefined }, ...values];
    }

    if (allowCheckAll) {
      return [SELECT_ALL_OPTION, UNSELECT_ALL_OPTION, ...values];
    }

    return values;
  }, [items, noneValue, allowCheckAll]);

  return (
    <StyledSelect
      {...selectProps}
      ref={ref}
      // required for ssr
      inputId={id}
      value={value}
      options={options}
      styles={{ ...reactSelectComponentsStyles, ...styles }}
    />
  );
});

export class Select<T> extends React.Component<ISelectProps<T>> {
  private inputRef?: HTMLInputElement;

  render() {
    const {
      customAutocomplete,
      handleEmptyMessage,
      disabled,
      className,
      menuPlacement,
      noneValue,
      placeholder = noneValue,
      allowCheckAll,
      isClearable,
      filterFn,
      ...selectProps
    } = this.props;

    return (
      <FlexWrapper flex={'initial'} className={className}>
        <SelectInner
          {...selectProps}
          allowCheckAll={allowCheckAll}
          ref={this.handleRef}
          menuPlacement={menuPlacement || 'auto'}
          onChange={this.handleChange}
          isSearchable={customAutocomplete || false}
          noOptionsMessage={handleEmptyMessage}
          isDisabled={disabled}
          placeholder={placeholder}
          className={className}
          isClearable={allowCheckAll ? false : isClearable}
          filterOption={filterFn}
        />
      </FlexWrapper>
    );
  }

  private handleRef = (ref: React.Component<unknown, object, unknown>) => {
    // eslint-disable-next-line react/no-find-dom-node
    const selectDOMNode = findDOMNode(ref);
    if (!selectDOMNode || !(selectDOMNode instanceof HTMLDivElement)) {
      return;
    }
    const input = selectDOMNode.getElementsByTagName('input')[0];
    if (!input) {
      return;
    }
    this.inputRef = input;
    input.setAttribute('autocomplete', this.props.autoComplete ?? 'nofill');
  };

  private handleChange = (item: ISelectValue<T> | ISelectValue<T>[]) => {
    let value: Array<T | undefined> | T | undefined = undefined;
    if (Array.isArray(item)) {
      if (String(item[item.length - 1]?.value) === SELECT_ALL_OPTION.value) {
        value = this.props.items.map((it) => it.value);
      } else if (String(item[item.length - 1]?.value) === UNSELECT_ALL_OPTION.value) {
        value = [];
      } else {
        value = item.map((i) => i?.value);
      }
    } else {
      value = item?.value;
    }
    this.props.handleChange(value);
    if (!this.inputRef) {
      return;
    }
    const eventType: keyof IReactSelectEventsMap = 'reactselect';
    const customReactSelectEvent = new CustomEvent(eventType, {
      detail: { value },
      bubbles: true,
    });
    this.inputRef.dispatchEvent(customReactSelectEvent);
  };
}
