import * as React from 'react';
import { Text, Font, If } from '@usga/modules';
import { ExemptionModel, QualifyingSitePlayerFullModel } from '@usga/champadmin-api';
import { TWithNumber } from './interfaces';
import * as Utils from './utils';
import { ItemText } from './ItemText';

export function ExemptionComponent(exemption: ExemptionModel) {
  return <Text width={'100%'}>{exemption.title}</Text>;
}

interface IQualifyingItemProps {
  title?: string;
  children: React.ReactNode;
}

type WithWaitListedProp<T> = T & {
  waitlisted?: boolean;
};

export function QualifyingItem({ title, children }: WithWaitListedProp<IQualifyingItemProps>) {
  return (
    <Text width="100%">
      <If cond={Boolean(title)}>
        <Text font={Font.BOLD} as="span">
          {title}:&nbsp;
        </Text>
      </If>
      {children}
    </Text>
  );
}

export function LocalQualifyingItem(props: WithWaitListedProp<QualifyingSitePlayerFullModel>) {
  return (
    <QualifyingItem>
      <ItemText {...props} waitlisted={props.waitlisted} />
    </QualifyingItem>
  );
}

export function SingleQualifyingItem(props: WithWaitListedProp<QualifyingSitePlayerFullModel>) {
  return (
    <QualifyingItem>
      <ItemText {...props} waitlisted={props.waitlisted} />
    </QualifyingItem>
  );
}

export function SectionalQualifyingItem(
  props: WithWaitListedProp<TWithNumber<QualifyingSitePlayerFullModel>>
) {
  return (
    <QualifyingItem title={`${Utils.getSerialNumber(props.place)} Choice`}>
      <ItemText {...props} waitlisted={props.waitlisted} />
    </QualifyingItem>
  );
}
