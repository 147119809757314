import * as React from 'react';
import { usePrevious } from '../../helpers/use-previous';

export const useBlurChange = <T extends unknown>(value: T, onChange: (newValue: T) => void) => {
  const [innerState, setInnerState] = React.useState(value);
  React.useEffect(() => {
    setInnerState(value);
  }, [value]);
  const onBlur = React.useCallback(() => {
    onChange(innerState);
  }, [innerState, onChange]);
  return [innerState, onBlur, setInnerState] as const;
};

export const useTyping = <T extends unknown>(
  value: T,
  onChange: (newValue: T) => void,
  interval = 500
) => {
  const [state, setState] = React.useState(value);
  const prevState = usePrevious(state);

  const timerRef = React.useRef<number | undefined>(undefined);

  React.useEffect(() => {
    setState(value);
  }, [value]);

  React.useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      if (prevState !== state) {
        onChange(state);
      }
    }, interval);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [state, interval, onChange]);

  return [state, setState] as const;
};

export const mapStringValue = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
  value;

export const mapNumberValue = (evt: React.ChangeEvent<HTMLInputElement>) =>
  Number.parseInt(mapStringValue(evt));
