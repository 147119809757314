import * as React from 'react';
import { Button } from '../Button';
import { Color } from '../../helpers/Fonts';
import { ApproveIcon } from '../Icon';

export interface ISelectButtonProps {
  title: string;
  selectedTitle: string;
  isSelected: boolean;
  onClick: (selected: boolean) => void;
  disabled?: boolean;
}

export class SelectButton extends React.Component<ISelectButtonProps> {
  public render() {
    const { disabled, isSelected, selectedTitle, title } = this.props;
    if (isSelected) {
      return (
        <Button
          color={Color.PRIMARY}
          onClick={this.handleClick}
          disabled={disabled}
          icon={
            <ApproveIcon
              width={'10px'}
              height={'10px'}
              margin={'-1px 4px'}
              color={disabled ? Color.TEXT_GREY : Color.WHITE}
            />
          }
          size={{
            width: '100px',
          }}
        >
          {selectedTitle}
        </Button>
      );
    }

    return (
      <Button
        transparent
        onClick={this.handleClick}
        disabled={disabled}
        size={{
          width: '100px',
        }}
      >
        {title}
      </Button>
    );
  }

  private handleClick = () => {
    this.props.onClick(this.props.isSelected);
  };
}
