import { Container, MapTo, ModelProvider } from '@adobe/cq-react-editable-components';
import { loadable } from '@usga/modules';

/**
 * Beware, this is monkey-patching.
 * The original ModelProvider and ModelManager component are buggy:
 * they're starting infinite updates when the model is updated and
 * `cqForceReload` prop of the ModelProvider component is equal to `true`.
 * To avoid that, we're counting updates:
 * - the 1st update must invoke component reloading
 * - the 2nd update must not (to prevent infinite updates cycle)
 * The `updateData` method of the `ModelProvider` component checks the prop
 * described above and runs updates regarding to prop value.
 * So we should overwrite prop value once just for this method to make
 * this method work properly.
 * The newset version of this library does not support the SSR.
 */
ModelProvider.prototype.updateData = (function(original) {
  return function(this: { cqForceReload: boolean; props: { cqForceReload: boolean } }) {
    if (this.cqForceReload === undefined) {
      this.cqForceReload = true;
    }
    this.props.cqForceReload = this.cqForceReload;
    const result = original.call(this);
    this.cqForceReload = !this.cqForceReload;
    return result;
  };
})(ModelProvider.prototype.updateData);

// connect your components like this

MapTo('champAdmin/components/content/home/global-navigation')(loadable(() => import('./Header')));
MapTo('champAdmin/components/content/home/banner')(loadable(() => import('./Home/Banner')));

MapTo('champAdmin/components/content/application/banner')(
  loadable(() => import('./ApplicationBanner'))
);

MapTo('champAdmin/components/content/home/selection-tabs')(
  loadable(() => import('./SelectionTabs'))
);
MapTo('champAdmin/components/content/home/selection-tab')(Container);

MapTo('champAdmin/components/content/home/eventCards')(loadable(() => import('./EventCardsList')));

MapTo('champAdmin/components/content/player/championship/amateur-appliance')(
  loadable(() => import('./appliances/flows/AmateurFlow'))
);
MapTo('champAdmin/components/content/player/championship/usamateur-appliance')(
  loadable(() => import('./appliances/flows/AmateurFlow'))
);
MapTo('champAdmin/components/content/adminApp/landing')(loadable(() => import('./admin')));
MapTo('champAdmin/components/content/player/payment')(
  loadable(() => import('./appliances/payment'))
);
MapTo('champAdmin/components/content/player/confirmation')(
  loadable(() => import('./appliances/confirmation'))
);

MapTo('champAdmin/components/content/player/championship/usopen-appliance')(
  loadable(() => import('./appliances/flows/UsopenFlow'))
);
MapTo('champAdmin/components/content/player/championship/open-appliance')(
  loadable(() => import('./appliances/flows/OpenFlow'))
);
MapTo('champAdmin/components/content/player/championship/twostageopen-appliance')(
  loadable(() => import('./appliances/flows/TwoStageOpenFlow'))
);
MapTo('champAdmin/components/content/player/championship/fourball-appliance')(
  loadable(() => import('./appliances/flows/FourballFlow'))
);
MapTo('champAdmin/components/content/player/championship/adaptive-appliance')(
  loadable(() => import('./appliances/flows/AdaptiveFlow'))
);

MapTo('champAdmin/components/content/player/text')(loadable(() => import('./ArticleText')));
MapTo('champAdmin/components/content/player/head-article')(
  loadable(() => import('./ArticleTitle'))
);

MapTo('champAdmin/components/content/home/links-block')(
  loadable(() => import('./LinksBlock')),
  {
    isEmpty: (props) => !props.model.links,
    emptyLabel: 'Links Block',
  }
);

MapTo('champAdmin/components/content/player/download')(
  loadable(() => import('./Attachment')),
  {
    isEmpty: () => true,
    emptyLabel: 'TBD download',
  }
);

MapTo('champAdmin/components/content/player/championship/app-notifications')(
  loadable(() => import('./MessagesList')),
  {
    isEmpty: (props) => !props.model.notifications || !props.model.notifications.length,
    emptyLabel: 'Notifications Block',
  }
);

MapTo('champAdmin/components/content/player/championship/link-button')(
  loadable(() => import('./ButtonLink'))
);

MapTo('champAdmin/components/content/officials/officialsEventList')(
  loadable(() => import('./official'))
);

MapTo('champAdmin/components/content/officials/qualifying-site')(
  loadable(() => import('./official/qualifying-site'))
);

MapTo('champAdmin/components/content/officials/back-to-home-page-button')(
  loadable(() => import('./official/BackToOfficialsButton'))
);

MapTo('champAdmin/components/content/officials/associatedSites')(
  loadable(() => import('./official/AssociatedSites'))
);

MapTo('champAdmin/components/content/staff/eventCardList')(
  loadable(() => import('./staff/ChampionshipsList'))
);

MapTo('champAdmin/components/content/staff/pairings')(
  loadable(() => import('./staff/StaffPairings'))
);

MapTo('champAdmin/components/content/staff/championship/one-column')(
  loadable(() => import('../pages/OneColumn'))
);

MapTo('champAdmin/components/content/officials/calendar')(
  loadable(() => import('./official/OfficialsCalendar'))
);

MapTo('champAdmin/components/content/officials/searchBar')(
  loadable(() => import('./official/OfficialFilterBar'))
);

MapTo('champAdmin/components/content/general/footer')(loadable(() => import('./Footer')));

MapTo('champAdmin/components/content/player/withdraw')(loadable(() => import('./Withdraw')));

MapTo('champAdmin/components/content/home/upcomingEvents')(
  loadable(() => import('./UpcomingQualifiers'))
);

MapTo('champAdmin/components/content/player/management/profile')(
  loadable(() => import('./PlayerHistory/PlayerProfile'))
);

MapTo('champAdmin/components/content/player/history/table')(
  loadable(() => import('./PlayerHistory/PlayerHistoryTable'))
);

MapTo('champAdmin/components/content/player/championship/back-to-list-button')(
  loadable(() => import('./BackToListButton'))
);

MapTo('champAdmin/components/content/player/payment/issues')(
  loadable(() => import('./PaymentIssues')),
  {
    isEmpty: () => true,
    emptyLabel: 'Payment issues module',
  }
);

MapTo('champAdmin/components/content/player/subscription')(
  loadable(() => import('./player/Subscription'))
);

MapTo('champAdmin/components/content/player/subscription-confirmation')(
  loadable(() => import('./player/Subscription/Confirmation'))
);

MapTo('champAdmin/components/content/login')(loadable(() => import('./LoginError')));

MapTo('champAdmin/components/content/player/confirmation/link-profiles')(
  loadable(() => import('./ManagerConfirmaion'))
);
