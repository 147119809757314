import * as React from 'react';
import styled from 'styled-components';
import { FlexDirection, FlexWrapper } from '../../helpers/FlexWrapper';
import { Color, Font } from '../../helpers/Fonts';
import { Text } from '../Text';
import { SCREEN } from '../../helpers';

interface IProps {
  title: string;
  steps: number[];
  activeStep: number;
}

interface IPaginationItem {
  isActive: boolean;
}

const Pagination = styled(FlexWrapper)`
  align-self: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Color.DARK_GREY};
`;

const PaginationItem = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props: IPaginationItem) =>
    props.isActive ? Color.PRIMARY : Color.DARK_GREY};

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${SCREEN.TP} {
    width: 14px;
    height: 14px;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`;

const Title = styled(Text as React.ComponentType<React.ComponentProps<typeof Text>>)`
  font-size: 20px;
  margin-bottom: 14px;
  margin-top: 16px;
  color: ${Color.DARK_GREY};

  ${SCREEN.TP} {
    margin-top: 40px;
  }
`;

const renderSteps = (steps: number[], activeStep: number) =>
  steps.map((e, i: number) => <PaginationItem isActive={activeStep === i} key={i} />);

export function ProgressBar(props: IProps) {
  return (
    <FlexWrapper backgroundColor={'#fff'} padding={'0 0 10px'}>
      <Title font={Font.LIGHT_ITALIC}>{props.title}</Title>
      <Pagination direction={FlexDirection.ROW}>
        <>{renderSteps(props.steps, props.activeStep)}</>
      </Pagination>
    </FlexWrapper>
  );
}
