import * as React from 'react';
import styled from 'styled-components';
import { Font } from '../../helpers/Fonts';
import { Text, WordBreak } from '../Text';
import { TextCase } from '@usga/modules';
import { FlexWrapper, FlexAlignment, FlexWrap } from '../../helpers/FlexWrapper';
import { SCREEN } from '../../helpers/breakpoints';

interface IProps {
  title: string;
  margin?: string;
  children?: React.ReactNode;
}

export function ReviewField({ title, children, margin }: IProps) {
  return (
    <Text width={'100%'} wordBreak={WordBreak.BREAK_WORD} margin={margin}>
      <Text font={Font.BOLD} as={'span'} margin={'0'}>
        {title}:&nbsp;
      </Text>
      {title.includes('Player Address') ||
      title.includes('Parent/Guardian Address') ||
      title.includes('City') ||
      title.includes('Handicap Verifier Title') ||
      title.includes('Relationship') ? (
        <Text as={'span'} margin={'0'} textCase={TextCase.CAPITALIZE}>
          {children}
        </Text>
      ) : (
        <Text as={'span'} margin={'0'}>
          {children}
        </Text>
      )}
    </Text>
  );
}

ReviewField.defaultProps = {
  margin: '10px 0',
};

export const ReviewFieldWrapper = styled(FlexWrapper)`
  align-items: ${FlexAlignment.START};
  justify-content: ${FlexAlignment.START};
  flex-wrap: ${FlexWrap.NOWRAP};
  flex-basis: 100%;

  ${SCREEN.TP} {
    flex-basis: 50%;
  }
`;
