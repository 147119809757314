import { IUSGAApp } from '../../helpers';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const preval = require('preval.macro');

export function appInterop(): IUSGAApp {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const AppModule = require(preval`
  const path = require('path');
  const configModule = require(path.resolve('./usga.build'));
  const config = configModule.config || configModule.default || configModule
  module.exports = config.appPath
`);
  return AppModule.App || AppModule.default || AppModule;
}
