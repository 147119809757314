import * as React from 'react';
import { DateRangePickerUTC, DateRange } from '../DateTimePicker';
import { IFilterComponentProps } from './FilterComponentProps';
import { FilterWrapper } from './FilterWrapper';

export const DateRangeFilter = ({
  onValueChange,
  value,
  onRemoveClick,
  disabled,
}: IFilterComponentProps<DateRange | undefined>) => {
  return (
    <FilterWrapper onRemoveClick={onRemoveClick}>
      <DateRangePickerUTC
        disabled={disabled}
        format="MM/dd/yyyy"
        value={value}
        onChange={onValueChange}
        clearIcon={null}
        calendarIcon={null}
      />
    </FilterWrapper>
  );
};
