import styled, { css } from 'styled-components';
import SvgSearch from './search.svg';
import SvgApprove from './approve.svg';
import SvgInfo from './info.svg';
import SvgChevron from './chevron.svg';
import SvgEditOld from './edit-icon.svg';
import SvgPlus from './plus.svg';
import SvgAddCircle from './add-circle.svg';
import SvgCheckboxChecked from './checkbox-checked.svg';
import SvgCheckboxUnchecked from './checkbox-unchecked.svg';
import SvgCheckboxCheckedGreyed from './checkbox-checked-greyed.svg';
import SvgCheckboxUncheckedGreyed from './checkbox-unchecked-greyed.svg';
import SvgEditNew from './edit.svg';
import SvgTrash from './trash.svg';
import Instagram from './instagram.svg';
import Facebook from './facebook.svg';
import Twitter from './twitter.svg';
import Youtube from './youtube.svg';
import Linkedin from './linkedin.svg';
import GooglePlus from './google-plus.svg';
import IconDropdown from './arrow-dropdown.svg';
import Attachment from './attachment.svg';
import Download from './download.svg';
import SvgMenuAdmin from './menu-admin.svg';
import DragAndDrop from './draganddrop.svg';
import Expand from './expand.svg';
import { Color } from '../../helpers/Fonts';
import { Cursor } from '../Text';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
  transformStyle?: string;
  cursor?: Cursor;
  inverted?: boolean;
  onClick?: () => void;
}

const DEFAULT_ICON_STYLE = css<IProps>`
  color: ${({ color }) => color || Color.BLACK};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  margin: ${({ margin }) => margin};
  transform: ${({ transformStyle }) => transformStyle};
  cursor: ${({ cursor }) => cursor};
  fill: currentColor;
`;

const DEFAULT_ROUND_ICON_STYLE = css`
  border-radius: 50%;
  width: 46px;
  height: 46px;
  padding: 0;
`;

export const ExpandIcon = styled(Expand)<IProps>`
  ${DEFAULT_ICON_STYLE}
`;

export const AttachmentIcon = styled(Attachment)<IProps>`
  ${DEFAULT_ICON_STYLE}
`;

export const AddCircleIcon = styled(SvgAddCircle)<IProps>`
  ${DEFAULT_ICON_STYLE};
`;

export const ArrowDropdown = styled(IconDropdown)<IProps>`
  ${DEFAULT_ICON_STYLE};
`;

export const CheckboxCheckedIcon = styled(SvgCheckboxChecked)<IProps>`
  flex: 0 0 auto;
  ${DEFAULT_ICON_STYLE};
`;

export const CheckboxUncheckedIcon = styled(SvgCheckboxUnchecked)<IProps>`
  flex: 0 0 auto;
  ${DEFAULT_ICON_STYLE};
`;

export const CheckboxCheckedGreyedIcon = styled(SvgCheckboxCheckedGreyed)<IProps>`
  flex: 0 0 auto;
  ${DEFAULT_ICON_STYLE};
`;

export const CheckboxUncheckedGreyedIcon = styled(SvgCheckboxUncheckedGreyed)<IProps>`
  flex: 0 0 auto;
  ${DEFAULT_ICON_STYLE}
`;

export const EditIconNew = styled(SvgEditNew)<IProps>`
  ${DEFAULT_ICON_STYLE};
`;

export const TrashIcon = styled(SvgTrash)<IProps>`
  ${DEFAULT_ICON_STYLE};
`;

export const SearchIcon = styled(SvgSearch)<IProps>`
  ${DEFAULT_ICON_STYLE};
`;

export const MenuAdmin = styled(SvgMenuAdmin)<IProps>`
  ${DEFAULT_ICON_STYLE};
`;

export const ApproveIcon = styled(SvgApprove)<IProps>`
  color: ${({ color }) => color || Color.WHITE};
  width: ${({ width }) => width || '12px'};
  height: ${({ height }) => height || '10px'};
  margin: ${({ margin }) => margin || '0 4px'};
  fill: currentColor;
`;

export const InfoIcon = styled(SvgInfo)<IProps>`
  color: ${({ color }) => color || Color.BLACK};
  width: ${({ width }) => width || '16px'};
  height: ${({ height }) => height || '16px'};
  margin: ${({ margin }) => margin};
  fill: currentColor;
`;

export const ChevronIcon = styled(SvgChevron)<IProps>`
  ${(props) => (props.inverted ? 'transform: rotate(180deg)' : '')};
  color: ${({ color }) => color || Color.TEXT_GREY};
  width: ${({ width }) => width || '16px'};
  height: ${({ height }) => height || '16px'};
  margin: ${({ margin }) => margin};
  fill: currentColor;
`;

export const EditIconOld = styled(SvgEditOld)<IProps>`
  color: ${({ color }) => color || Color.DEFAULT};
  width: ${({ width }) => width || '12px'};
  height: ${({ height }) => height || '12px'};
  margin: ${({ margin }) => margin || '4px'};
  border: 1px solid ${Color.DEFAULT};
  border-radius: 2px;
  padding: 3px;
  cursor: pointer;
  fill: currentColor;
`;

export const PlusIcon = styled(SvgPlus)<IProps>`
  color: ${({ color }) => color || Color.DEFAULT};
  width: ${({ width }) => width || '12px'};
  height: ${({ height }) => height || '12px'};
  margin: ${({ margin }) => margin || '4px'};
  border: 1px solid ${Color.DEFAULT};
  border-radius: 2px;
  padding: 3px;
  cursor: pointer;
  fill: currentColor;
`;

export const InstagramSvg = styled(Instagram)<IProps>`
  ${DEFAULT_ICON_STYLE};
  ${DEFAULT_ROUND_ICON_STYLE};
`;

export const FacebookSvg = styled(Facebook)<IProps>`
  ${DEFAULT_ICON_STYLE};
  ${DEFAULT_ROUND_ICON_STYLE};
`;

export const TwitterSvg = styled(Twitter)<IProps>`
  ${DEFAULT_ICON_STYLE};
  ${DEFAULT_ROUND_ICON_STYLE};
`;

export const YoutubeSvg = styled(Youtube)<IProps>`
  ${DEFAULT_ICON_STYLE};
  ${DEFAULT_ROUND_ICON_STYLE};
`;

export const LinkedinSvg = styled(Linkedin)<IProps>`
  ${DEFAULT_ICON_STYLE};
  ${DEFAULT_ROUND_ICON_STYLE};
`;

export const GooglePlusSvg = styled(GooglePlus)<IProps>`
  ${DEFAULT_ICON_STYLE};
  ${DEFAULT_ROUND_ICON_STYLE};
`;

export const DownloadIcon = styled(Download)<IProps>`
  ${DEFAULT_ICON_STYLE};
`;

export const DragAndDropIcon = styled(DragAndDrop)<IProps>`
  ${DEFAULT_ICON_STYLE};
`;
