import * as React from 'react';
import styled from 'styled-components';
import { QuickHit } from '../../components/QuickHit';
import { RichText, WordBreak } from '../../components/Text';
import { Color } from '../../helpers/Fonts';

interface IDefaultMessageComponentProps {
  children: string;
}

export const Message = styled(
  QuickHit as React.ComponentType<React.ComponentProps<typeof QuickHit>>
)`
  border: none;
  color: ${Color.ALERT};
`;

export const DefaultMessageComponent = ({ children: text }: IDefaultMessageComponentProps) => (
  <div>
    <Message key={text}>
      <RichText html={text} color={Color.PRIMARY} width={'100%'} wordBreak={WordBreak.BREAK_WORD} />
    </Message>
  </div>
);

interface IMessageListProps {
  children: React.ReactNode;
}

export const MessagesList = ({ children }: IMessageListProps) => <div>{children}</div>;
