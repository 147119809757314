import styled, { css } from 'styled-components';
import { FlexWrapper } from '../../helpers/FlexWrapper';
import { Color } from '../../helpers/Fonts';
import { SCREEN } from '../../helpers';
import { Cursor, Text } from '../../components/Text';
import { IInputProps, LabelMode } from './interfaces';

export const InputWrapper = styled(FlexWrapper)<IInputProps>`
  flex: auto;
  border: solid 1px ${Color.INPUT_BORDER};
  border-color: ${({ borderColor }) => borderColor || Color.INPUT_BORDER};
  max-height: ${(props) => props.height || '38px'};
  flex-direction: column-reverse;
  background-color: #fff;
  border-radius: 4px;
  position: relative;

  .input-label {
    &:empty {
      display: none;
    }
  }

  ${SCREEN.TP} {
    max-width: ${(props) => props.width || '100%'};
  }
`;

export const InputStyled = styled.input<IInputProps>`
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  font-size: 14px;
  z-index: 1;
  box-sizing: border-box;
  height: 100%;
  padding: ${(props) => (props.image ? '10px 54px 10px 14px' : '10px 14px')};
  cursor: ${({ readOnly }) => readOnly && Cursor.DEFAULT};

  ${(props) =>
    props.labelMode === LabelMode.FLOAT &&
    css`
      padding: 18px 14px 8px;

      ${props.value &&
        `
      & + ${Text} {
        font-size: 10px;
        transform: none;
        top: 0;
      }`}

      &:focus {
        & + ${Text} {
          font-size: 10px;
          transform: none;
          top: 0;
        }
      }
    `}
`;

const FloatLabel = css<IInputProps>`
  max-width: ${(props) => props.width};
  box-sizing: border-box;
  left: 14px;
  padding-right: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StaticLabel = css`
  top: auto;
  bottom: 100%;
  left: 0;
`;

export const InputLabel = styled(Text as React.ComponentType<React.ComponentProps<typeof Text>>)<
  IInputProps
>`
  width: auto;
  position: absolute;
  transition-duration: 150ms;
  white-space: nowrap;
  z-index: 0;
  ${(props) => (props.labelMode == LabelMode.FLOAT ? FloatLabel : StaticLabel)};
`;

export const InputLogo = styled.div`
  display: block;
  width: 40px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  &:empty {
    display: none;
  }
`;
