import * as React from 'react';

export enum FlexDirection {
  COLUMN = 'column',
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
}

export enum FlexWrap {
  WRAP = 'wrap',
  NOWRAP = 'nowrap',
}

export enum FlexAlignment {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
  BETWEEN = 'space-between',
  AROUND = 'space-around',
  STRETCH = 'stretch',
  BASELINE = 'baseline',
}

export interface IFlexWrapperProps {
  id?: string;
  children?: React.ReactNode;
  flex?: string;
  basis?: string;
  direction?: FlexDirection;
  justifyContent?: FlexAlignment;
  alignItems?: FlexAlignment;
  alignSelf?: string;
  wrap?: FlexWrap;
  padding?: string;
  margin?: string;
  /**
   * @deprecated use basis
   */
  width?: string;
  height?: string;
  maxWidth?: string;
  backgroundColor?: string;
  onClick?: () => void;
  className?: string;
  invisible?: boolean;
  zIndex?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: any;
  border?: string;
}
