import * as React from 'react';
import { Container, MapTo } from '@adobe/cq-react-editable-components';
import {
  Button,
  FlexAlignment,
  FlexWrapper,
  HiddenBlock,
  SCREEN,
  useRendersCount,
} from '@usga/modules';
import { IAEMPropsModel } from '../components/interfaces';
import { TwoColumnsLayoutModel, TwoColumnsLayoutModelMainSideEnum } from '@usga/champadmin-api/src';
import { REQUIRE_RENDERED_VIEWS_ID } from '../components/BackToListButton';
import { LayoutTwoColumns } from '../components/shared/Layout';
import { SideRail, SideRailGap } from '../components/shared/SideRail';

interface ISideRailWrapperProps {
  gap: SideRailGap;
}

const PrintButton = () => {
  return (
    <HiddenBlock screen={SCREEN.L_DOWN}>
      <Button onClick={window.print}>PRINT THIS PAGE</Button>
    </HiddenBlock>
  );
};

const SideRailWrapper = ({ gap, children }: React.PropsWithChildren<ISideRailWrapperProps>) => {
  const getRenderedComponentsCount = useRendersCount();
  return (
    <SideRail gap={gap}>
      {getRenderedComponentsCount(REQUIRE_RENDERED_VIEWS_ID) >= 1 ? <PrintButton /> : null}
      {children}
    </SideRail>
  );
};

class TwoColumn extends Container<IAEMPropsModel<TwoColumnsLayoutModel>> {
  public render() {
    return (
      <div {...this.containerProps}>
        <LayoutTwoColumns
          tabs={this.tabs}
          leftComponent={this.getLeftBlock()}
          rightComponent={this.getRightBlock()}
          mainSide={this.getMainSide()}
          tabMode={this.props.model.tabMode}
        />
        {this.placeholderComponent}
      </div>
    );
  }

  private get tabs() {
    return [
      {
        id: TwoColumnsLayoutModelMainSideEnum.LEFT,
        title: this.props.model.leftTitle ?? '',
      },
      {
        id: TwoColumnsLayoutModelMainSideEnum.RIGHT,
        title: this.props.model.rightTitle ?? '',
      },
    ];
  }

  private getLeftBlock = () => {
    if (this.getMainSide() === TwoColumnsLayoutModelMainSideEnum.LEFT) {
      return this.getMainWrapper();
    } else {
      return this.getSideRail(SideRailGap.RIGHT);
    }
  };

  private getRightBlock = () => {
    if (this.getMainSide() === TwoColumnsLayoutModelMainSideEnum.RIGHT) {
      return this.getMainWrapper();
    } else {
      return this.getSideRail(SideRailGap.LEFT);
    }
  };

  private getSideRail(gap: SideRailGap) {
    return <SideRailWrapper gap={gap}>{this.childComponents[0]}</SideRailWrapper>;
  }

  private getMainWrapper() {
    return (
      <FlexWrapper
        flex={'1'}
        alignItems={FlexAlignment.STRETCH}
        justifyContent={FlexAlignment.START}
      >
        {this.childComponents[1]}
      </FlexWrapper>
    );
  }

  private getMainSide() {
    return (
      (this.props.model && this.props.model.mainSide) || TwoColumnsLayoutModelMainSideEnum.LEFT
    );
  }
}

MapTo('champAdmin/components/content/player/championship/two-columns')(TwoColumn);
