import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { from, Observable, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IAppAuthService } from '../appAuth';
import { ILegacyAPI } from './interfaces';

export class LegacyHttpService implements ILegacyAPI {
  constructor(protected auth: IAppAuthService) {}

  get<T>(url: string, options?: AxiosRequestConfig): Observable<AxiosResponse<T>> {
    return this.makeRxRequest<T>({
      url,
      method: 'get',
      ...options,
    });
  }

  post<T>(url: string, data?: object, options?: AxiosRequestConfig): Observable<AxiosResponse<T>> {
    return this.makeRxRequest<T>({
      url,
      method: 'post',
      data,
      ...options,
    });
  }

  put<T>(url: string, data?: object, options?: AxiosRequestConfig): Observable<AxiosResponse<T>> {
    return this.makeRxRequest<T>({
      url,
      method: 'put',
      data,
      ...options,
    });
  }

  delete<T>(
    url: string,
    data?: object,
    options?: AxiosRequestConfig
  ): Observable<AxiosResponse<T>> {
    return this.makeRxRequest<T>({
      url,
      method: 'delete',
      data,
      ...options,
    });
  }

  private makeRxRequest<T>(userConfig: AxiosRequestConfig): Observable<AxiosResponse<T>> {
    return new Observable<AxiosResponse<T>>((sub) => {
      const source = axios.CancelToken.source();

      const cancelToken = source.token;

      const idToken = this.auth.authResult.getValue().idToken;
      const authHeaders = idToken ? { Authorization: 'Bearer ' + idToken } : {};

      const config = {
        ...userConfig,
        headers: {
          ...userConfig.headers,
          ...authHeaders,
          'Content-Type': 'application/json',
        },
        cancelToken,
        baseURL: '/bin/champadmin',
      };
      const request = from(axios.request<T>(config)).pipe(
        catchError((e: AxiosError) => {
          const { response } = e;

          if (response && response.status === 403) {
            console.error(e);
            return from(this.auth.popupLogin()).pipe(
              switchMap(() => this.makeRxRequest<T>(userConfig))
            );
          }

          throw e;
        }),
        catchError((e: AxiosError) => {
          const { response } = e;

          if (response && response.status === 429) {
            return timer(1000).pipe(switchMap(() => this.makeRxRequest<T>(userConfig)));
          }

          throw e;
        })
      );
      request.subscribe(sub);

      return source.cancel;
    });
  }
}
