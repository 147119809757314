import * as React from 'react';
import styled from 'styled-components';
import { If } from '@usga/modules';
import { useQualificationSiteAndExemption } from './useQualificationSiteAndExemption';
import { Exemptions, QualifyingSites } from './DisplayItem';
import { ApplianceStep, FlowManagerComponent } from '../../appliances/flows/FlowManager';

const UserChoicesWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
//
// const EXEMPTION_STEPS = [
//   ApplianceStep.EXEMPTION,
//   ApplianceStep.FINAL_EXEMPTION,
//   ApplianceStep.LOCAL_EXEMPTION,
//   ApplianceStep.FINAL_LOCATION,
//   ApplianceStep.LOCAL_LOCATION,
//   ApplianceStep.ELIGIBILITY,
//   ApplianceStep.REVIEW,
// ];
//
// const QUALIFYING_SITE_STEPS = [
//   ApplianceStep.FINAL_LOCATION,
//   ApplianceStep.LOCAL_LOCATION,
//   ApplianceStep.LOCATION,
//   ApplianceStep.ELIGIBILITY,
//   ApplianceStep.REVIEW,
// ];

export const UserChoices = () => {
  const { exemptionItems, qualifyingSiteItems, waitListItems } = useQualificationSiteAndExemption();

  const [, setStep] = React.useState<ApplianceStep | null>(null);

  React.useEffect(() => {
    const sub$ = FlowManagerComponent.currentStep.asObservable().subscribe(setStep);
    return () => {
      sub$.unsubscribe();
    };
  }, []);

  return (
    <If
      cond={exemptionItems.length > 0 || qualifyingSiteItems.length > 0 || waitListItems.length > 0}
    >
      <UserChoicesWrapper>
        <Exemptions items={exemptionItems} />
        <QualifyingSites items={waitListItems} waitlisted />
        <QualifyingSites items={qualifyingSiteItems} />
      </UserChoicesWrapper>
    </If>
  );
};
