// eslint-disable-next-line no-restricted-imports
import loadableFn, { DefaultComponent, LoadableComponent } from '@loadable/component';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { PreloadedStateContext } from '../AppContext';
import { frontloadConnect } from 'react-frontload';

export interface IPreloadedState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [id: string]: any;
}

function getInitialProps<T>(
  preloadedState: IPreloadedState,
  cqPath: string,
  Component: React.ComponentType<T> & { getInitialProps?: () => Promise<T> }
) {
  let props$;
  if (preloadedState[cqPath]) {
    props$ = Promise.resolve(preloadedState[cqPath]);
  }
  const componentPropsGetter = Component.getInitialProps;

  if (componentPropsGetter) {
    props$ = componentPropsGetter();
  } else {
    props$ = Promise.resolve({});
  }
  return props$;
}

const FrontloadComponent = frontloadConnect(({ preloadedState, cqPath, componentPromise }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return componentPromise.requireAsync().then(({ default: Component }: any) => {
    return getInitialProps(preloadedState, cqPath, Component).then((componentProps) => {
      preloadedState[cqPath] = componentProps;
    });
  });
})(
  ({
    preloadedState,
    cqPath, // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentPromise,
    Component,
    ...props
  }: {
    preloadedState: IPreloadedState;
    cqPath: string; // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentPromise: any; // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: LoadableComponent<any>;
  }) => {
    const preloadedProps = preloadedState[cqPath];
    useEffect(() => {
      delete preloadedState[cqPath];
    });
    return (
      <InitialPropsLoader
        preloadedProps={preloadedProps}
        {...props}
        cqPath={cqPath}
        Component={Component}
      />
    );
  }
);

const InitialPropsLoader: React.FunctionComponent<{
  Component: React.ComponentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preloadedProps: any;
  cqPath: string;
}> = ({ Component, preloadedProps, cqPath, ...props }) => {
  const [componentProps, setComponentProps] = useState(preloadedProps);
  useEffect(() => {
    if (!componentProps) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Component ${cqPath} has been updated. It shouldn't happening on first render`);
      }
      getInitialProps({}, cqPath, Component).then(setComponentProps);
    }
  }, [componentProps]);
  return <Component {...componentProps} {...props} cqPath={cqPath} />;
};

// promise arg is not actual promise
export function loadable<T>(
  promise: () => Promise<DefaultComponent<T>>
): React.ComponentType<
  T & {
    cqPath: string;
  }
> {
  const Component = loadableFn(promise);
  // 1: attach fronload(need path and store) and wait there for component and it's props
  // 2: attach loadable
  // 3: pass props from frontload, add retry function
  const WithPreloadedState: React.FunctionComponent<T & {
    cqPath: string;
  }> = (props) => {
    const preloadedState = useContext(PreloadedStateContext);
    return (
      <FrontloadComponent
        {...props}
        Component={Component}
        cqPath={props.cqPath}
        preloadedState={preloadedState}
        componentPromise={promise}
      />
    );
  };

  return WithPreloadedState;
}
