import { createHashHistory, createMemoryHistory, History } from 'history';
import { Router, RouterProps } from 'react-router';
import * as React from 'react';
import { useContext, useEffect } from 'react';

export const AEMUpdateRouteContext = React.createContext<() => void>(() => {});

export const createAEMHashHistory = (isInEditor: boolean) => {
  return isInEditor ? createMemoryHistory() : createHashHistory();
};

export const AEMHashHistoryContext = React.createContext<History | null>(null);

export function useAEMHistory() {
  return React.useContext(AEMHashHistoryContext);
}

export type IWithAEMHistoryProps = { aemHistory: History };

export function withAEMHistory<T extends IWithAEMHistoryProps>(Component: React.ComponentType<T>) {
  return function WithAEMHistory(props: T) {
    const history = useAEMHistory();

    return <Component {...props} aemHistory={history} />;
  };
}

export const AEMRouter: React.FunctionComponent<RouterProps> = (props) => {
  const update = useContext(AEMUpdateRouteContext);
  useEffect(() => {
    update();
    return props.history.listen(() => {
      update();
    });
  }, [props.history]);

  return <Router {...props} />;
};
