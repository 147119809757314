import { IPageModel, ModelClient, ModelManager } from '@adobe/cq-spa-page-model-manager';
import { hydrate, render } from 'react-dom';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { appInterop } from './lib/appInterop';
// eslint-disable-next-line no-restricted-imports
import { loadableReady } from '@loadable/component';
import { IPreloadedState, PreloadedStateContext } from '@usga/modules';
import { AppRouter } from '../client/appRouter';

function renderApp(pageModel: IPageModel, useHydrate: boolean, preloadedState: IPreloadedState) {
  // todo remove null values from model and print this error to console!!!!
  const App = appInterop();
  (useHydrate ? hydrate : render)(
    <BrowserRouter>
      <PreloadedStateContext.Provider value={preloadedState}>
        <AppRouter AppComponent={App} initialModel={pageModel} />
      </PreloadedStateContext.Provider>
    </BrowserRouter>,
    document.getElementById('react')
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function extractState(elementId: string): any {
  try {
    const prejsonScript = document.getElementById(elementId);
    let preloadedState = {};
    if (prejsonScript) {
      preloadedState = JSON.parse(prejsonScript.innerText);
      // Remove the script element from the DOM
      prejsonScript.remove();
    }
    return preloadedState;
  } catch (e) {
    console.error('Failed to extract state ' + elementId, e);
  }
}

const initialState = extractState('__AEM_INITIAL_STATE__');

const preloadedState = extractState('__PRELOADED_STATE__');

if (process.env.NODE_ENV === 'development') {
  console.info('AEMState', { ...initialState });
  console.info('preloadedState', { ...preloadedState });
}

const initialModel = initialState ? initialState.rootModel : undefined;

// todo don't do it on first render
const modelClient = new ModelClient();
const aemModelPromise: Promise<IPageModel> = ModelManager.initialize({
  model: initialModel,
  path: initialState ? initialState.path : undefined,
  modelClient: modelClient,
}).then(() => {
  return ModelManager.getData({ path: initialState.requestPath });
});
Promise.all<IPageModel, unknown>([
  aemModelPromise,
  new Promise<void>((resolve) => loadableReady(resolve)),
]).then(([model]) => {
  renderApp(model, !!initialModel, preloadedState);
});
