import * as React from 'react';
import { FilterWrapper } from './FilterWrapper';
import { IFilterComponentProps } from './FilterComponentProps';
import { Input } from '../../form';
import { useTyping, mapNumberValue } from './helpers';
import { MapCallbackValue } from '../../helpers';

export const NumberFilter = ({
  onValueChange,
  value,
  onRemoveClick,
  title,
  disableAutoWidth,
  disabled,
}: IFilterComponentProps<number>) => {
  const [state, setState] = useTyping(value, onValueChange);
  return (
    <FilterWrapper onRemoveClick={onRemoveClick} disableAutoWidth={disableAutoWidth}>
      <MapCallbackValue onChange={setState} map={mapNumberValue}>
        {(onChange) => (
          <Input
            disabled={disabled}
            type="number"
            value={state.toString()}
            onChange={onChange}
            placeholder={title}
          />
        )}
      </MapCallbackValue>
    </FilterWrapper>
  );
};
