import * as React from 'react';
import { ExpirableReactiveCookie } from './ExpirableReactiveCookie';
import { IDisposable } from '../Disposable';

/**
 * React wrapper for Reactive cookies
 */
export class ReactCookie implements IDisposable {
  private readonly name: string;

  private readonly expirableReactiveCookie: ExpirableReactiveCookie;

  private readonly CookieContext: React.Context<ExpirableReactiveCookie>;

  constructor(name: string) {
    this.name = name;
    this.expirableReactiveCookie = new ExpirableReactiveCookie(this.name);
    this.CookieContext = React.createContext(this.expirableReactiveCookie);
  }

  public getName() {
    return this.name;
  }

  public getExpirableReactiveCookie() {
    return this.expirableReactiveCookie;
  }

  public dispose() {
    this.expirableReactiveCookie.dispose();
  }

  public useForceCookieCheck() {
    const expirableReactiveCookie = React.useContext(this.CookieContext);
    const forceCheck = React.useCallback(() => {
      expirableReactiveCookie.forceCheck();
    }, [expirableReactiveCookie]);
    return forceCheck;
  }

  public useCookie() {
    const expirableReactiveCookie = React.useContext(this.CookieContext);
    const [cookie, updateCookie] = React.useState(expirableReactiveCookie.getValue());
    React.useEffect(() => {
      const cookieChangesSubsciption = expirableReactiveCookie
        .observeChanges()
        .subscribe(updateCookie);
      return () => {
        cookieChangesSubsciption.unsubscribe();
      };
    }, [expirableReactiveCookie]);

    const setCookie = React.useCallback(
      (value: string | null) => {
        expirableReactiveCookie.setValue(value);
      },
      [expirableReactiveCookie]
    );

    return { cookie, setCookie };
  }

  public useCookieExpiration() {
    const expirableReactiveCookie = React.useContext(this.CookieContext);
    const [expired, setExpired] = React.useState(expirableReactiveCookie.isExpired());
    React.useEffect(() => {
      const cookieExpirationSubscription = expirableReactiveCookie
        .observeExpiration()
        .subscribe(setExpired);
      return () => {
        cookieExpirationSubscription.unsubscribe();
      };
    }, [expirableReactiveCookie]);
    const expire = React.useCallback(() => {
      expirableReactiveCookie.expire();
    }, [expirableReactiveCookie]);
    return { expired, expire };
  }
}
