import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../../helpers/Fonts';
import { SCREEN } from '../../helpers';

interface IProps {
  color?: Color;
  children?: React.ReactNode;
  width?: string;
  height?: string;
  radius?: string;
  padding?: string;
  margin?: string;
  withBorder?: boolean;
  className?: string;
}

const StyledWrapper = styled.div<IProps>`
  background-color: ${({ color }) => color || '#fff'};
  border: ${({ withBorder }) => withBorder && `1px solid ${Color.DARK_DEEP_GREY}`};
  border-radius: ${({ radius }) => radius};
  padding: ${({ padding }) => padding || '10px'};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  width: ${({ width }) => width || '100%'};
  box-sizing: border-box;

  ${SCREEN.TP} {
    padding: ${({ padding }) => padding || '40px'};
  }
`;

export function ContentBox(props: IProps) {
  return (
    <StyledWrapper
      color={props.color}
      width={props.width}
      height={props.height}
      withBorder={props.withBorder}
      radius={props.radius}
      padding={props.padding}
      margin={props.margin}
      className={props.className}
    >
      {props.children}
    </StyledWrapper>
  );
}
