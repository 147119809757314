export class HandicapValue {
  public value: number;

  constructor(stringValue: string) {
    const firstChar = stringValue.trim()[0];
    if (firstChar === '+' || firstChar === '-') {
      this.value = parseFloat(stringValue);
    } else {
      this.value = -parseFloat(stringValue);
    }
  }

  static fromNumber(value: number) {
    return new HandicapValue(value.toString());
  }

  toString() {
    if (this.value > 0) {
      return '+' + this.value;
    } else {
      return Math.abs(this.value).toString();
    }
  }
}
