import styled, { css } from 'styled-components';
import { Color } from '../../helpers/Fonts';
import { FlexAlignment, FlexWrapper, SCREEN } from '../../helpers';

export const CloseButton = styled.div`
  position: absolute;
  width: 26px;
  height: 26px;
  cursor: pointer;
  top: 6px;
  right: 6px;
  z-index: 3;
  transform: rotate(45deg) scale(1.25);

  &::before {
    position: absolute;
    top: 6px;
    left: 6px;
    content: ' ';
    height: 12px;
    width: 12px;
    background-color: ${Color.WHITE};
    z-index: -1;
  }

  ${SCREEN.TL} {
    top: -12px;
    right: -12px;
  }
`;
export const PopupHeaderContent = styled.div`
  padding-right: 10px;
`;

export const PopupHeader = styled.div`
  margin: 0 0 30px;
  padding: 10px 10px 30px;
  border-bottom: 1px solid ${Color.DARK_GREY};
`;

export const PopupContentWrapper = styled(FlexWrapper)`
  flex: 1;
  justify-content: ${FlexAlignment.START};
  overflow-y: auto;
  padding: 0 10px;

  ${SCREEN.TP} {
    padding: 0 20px;
  }

  ${SCREEN.TL} {
    padding: 0 30px;
  }
`;

export const PopupWrapper = styled.div<{ successMessage?: string; errorMessage?: string }>`
  .popup-overlay {
    box-sizing: border-box;
    cursor: default;

    ${SCREEN.TP} {
      padding: 20px 0;
    }
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-height: 100%;
    max-width: 800px;
    min-width: 100%;
    padding: 10px 0;
    box-sizing: border-box;

    &::after {
      text-transform: uppercase;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 24px;
      color: ${Color.WHITE};
      ${(props) => {
        if (props.successMessage) {
          return css`
            content: '${props.successMessage}';
            background-color: ${Color.SUCCESS};
          `;
        }

        if (props.errorMessage) {
          return css`
            content: '${props.errorMessage}';
            background-color: ${Color.ALERT};
            bottom: -8px;
            height: 42px !important;
          `;
        }
      }}
    }

    ${SCREEN.TP} {
      height: auto;
      min-width: 500px;
      width: 90%;
      padding: 20px 0;
    }

    ${SCREEN.TL} {
      padding: 30px 0;

      &::after {
        height: 34px;
      }
    }
  }

  &.full-height {
    .popup-content {
      ${SCREEN.TP} {
        height: 100%;
      }
    }
  }
`;

export const PopupChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
