import * as React from 'react';
import { InfoIcon } from '../Icon';
import { Color } from '../../helpers/Fonts';
import { InfoText, InfoWrapper } from './comoponents';

interface IInfoProps {
  iconColor?: string;
  className?: string;
}

export function Info(props: React.PropsWithChildren<IInfoProps>) {
  return (
    <InfoWrapper className={props.className}>
      <InfoIcon color={props.iconColor} />
      <InfoText color={Color.PRIMARY} className={'info-text'}>
        {props.children}
      </InfoText>
    </InfoWrapper>
  );
}
