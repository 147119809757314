import { QualifyingSiteFilters } from '@usga/champadmin-api';

export const mapQualifyingSiteFilters = (qualifyingSiteFilters: QualifyingSiteFilters) => {
  switch (qualifyingSiteFilters) {
    case QualifyingSiteFilters.APPROVED:
      return 'Approved';
    case QualifyingSiteFilters.CARTSAVAILABLE:
      return 'Carts Available';
    case QualifyingSiteFilters.FINALQUALIFYING:
      return 'Final Qualifying';
    case QualifyingSiteFilters.LOCALQUALIFYING:
      return 'Local Qualifying';
    case QualifyingSiteFilters.PENDING:
      return 'Pending';
    case QualifyingSiteFilters.STATE:
      return 'State/Province';
    default:
      return '';
  }
};
