import * as React from 'react';
import { Input } from '../../form';
import { FilterWrapper } from './FilterWrapper';
import { IFilterComponentProps } from './FilterComponentProps';
import { useTyping, mapStringValue } from './helpers';
import { MapCallbackValue } from '../../helpers';

export const StringFilter = ({
  onValueChange,
  value,
  onRemoveClick,
  title,
  disabled,
  disableAutoWidth,
}: IFilterComponentProps<string>) => {
  const [state, setState] = useTyping(value, onValueChange);
  return (
    <FilterWrapper onRemoveClick={onRemoveClick} disableAutoWidth={disableAutoWidth}>
      <MapCallbackValue onChange={setState} map={mapStringValue}>
        {(onChange) => (
          <Input disabled={disabled} value={state} onChange={onChange} placeholder={title} />
        )}
      </MapCallbackValue>
    </FilterWrapper>
  );
};
