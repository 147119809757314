import * as React from 'react';
import { Observable, of, EMPTY } from 'rxjs';
import uniq from 'lodash/uniq';
import { DefaultFileComponent, DefaultFileInputComponentWrapper } from './file-components';
import { FileInput } from './FileInput';
import { useDidUpdate } from '../../helpers/DidUpdate';

const defaultProcessFile = (file: File) => of(file);

export const LimitFileBy = (maxSize: number) => (file: File) =>
  file.size <= maxSize ? of(file) : EMPTY;

interface IFilesInputProps {
  onChange: (files: File[]) => void;
  processFile?: (file: File) => Observable<File>;
}

const useUniqKeys = (files: File[]) => {
  return React.useMemo(
    () =>
      uniq(
        files.map(
          (file) => `${file.name}-${file.size}-${file.type}-${file.lastModified.toString()}`
        )
      ),
    [files]
  );
};

export const FilesInput = ({ onChange, processFile = defaultProcessFile }: IFilesInputProps) => {
  const [files, setFiles] = React.useState<Array<File>>([]);
  const [key, setKey] = React.useState(Math.random());

  const handleFilesChange = React.useCallback(
    (file: File | null) => {
      setKey(Math.random());
      if (!file) {
        return;
      }

      processFile(file).subscribe((file) => {
        setFiles([...files, file]);
      });
    },
    [files]
  );

  useDidUpdate(() => {
    onChange(files);
  }, [files]);

  const handleClick = React.useCallback(
    (file: File) => {
      setFiles(files.filter((currentFile) => file !== currentFile));
    },
    [files]
  );

  // try to use uniq files keys
  const uniqKeysByContent = useUniqKeys(files);

  return (
    <>
      {files.map((file, index) => (
        <DefaultFileComponent
          key={uniqKeysByContent.length === files.length ? uniqKeysByContent[index] : index}
          file={file}
          onClick={handleClick}
        />
      ))}
      <DefaultFileInputComponentWrapper>
        <FileInput key={key} onChange={handleFilesChange} />
      </DefaultFileInputComponentWrapper>
    </>
  );
};
