import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isIOS } from '../../helpers/Detect';

export enum LockScrollClassNames {
  DISABLE = 'disable-scroll',
  ENABLE = 'enable-scroll',
}

function allowTouchMove(element: Element | HTMLElement) {
  return (
    element.classList.contains(LockScrollClassNames.ENABLE) ||
    Boolean(element.closest(`.${LockScrollClassNames.ENABLE}`))
  );
}

export function useBodyLockScroll(container: Element | HTMLElement | null) {
  const html: HTMLElement | null =
    typeof document !== 'undefined' ? document.getElementsByTagName('html')[0] : null;
  const pageY: number = html?.scrollTop ?? 0;

  function disableScroll() {
    if (html && isIOS()) {
      html.classList.add(LockScrollClassNames.DISABLE);
    }

    if (container) {
      disableBodyScroll(container, { allowTouchMove });
    }
  }

  function enableScroll() {
    if (html && html.classList.contains(LockScrollClassNames.DISABLE)) {
      html.classList.remove(LockScrollClassNames.DISABLE);
      html.scrollTo(0, pageY);
    }

    clearAllBodyScrollLocks();
  }

  return { disableScroll, enableScroll };
}
