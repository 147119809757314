import * as React from 'react';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { IApplianceStepManager } from '../interfaces';
import { IWithMessageBus, useMessageBus } from '../../../services/MessageBus';

type IFlowState<T> = {
  data: Partial<T> | undefined;
  currentStep: number;
};

type IState<T> = IFlowState<T> & {
  undoStack: IFlowState<T>[];
  redoStack: IFlowState<T>[];
  initial?: IFlowState<T>;
};

export type IFlowManagerRenderArgs<T> = {
  manager: IApplianceStepManager<T>;
  data: Partial<T> | undefined;
  stepIndex: number;
  initialData: Partial<T> | undefined;
};

interface IOwnProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  steps: React.ComponentType<any>[];
  initialData: Partial<T> | undefined;
  children: (args: IFlowManagerRenderArgs<T>) => JSX.Element;
}

interface IProps<T> extends IOwnProps<T>, IWithMessageBus {}

export enum ApplianceStep {
  HOME,
  AMATEUR_STATUS,
  EXEMPTION,
  FINAL_EXEMPTION,
  LOCAL_EXEMPTION,
  LOCATION,
  LOCAL_LOCATION,
  FINAL_LOCATION,
  ELIGIBILITY,
  REVIEW,
}

export class FlowManagerComponent<T> extends React.Component<IProps<T>, IState<T>>
  implements IApplianceStepManager<T> {
  static currentStep: Subject<ApplianceStep> = new Subject();

  state: IState<T> = {
    undoStack: [],
    redoStack: [],
    data: undefined,
    currentStep: 0,
  };

  static getDerivedStateFromProps(props: IOwnProps<unknown>, state: IState<unknown>) {
    if (!state.data && props.initialData) {
      return {
        data: props.initialData,
      };
    }
    return null;
  }

  updateData(data: Partial<T>) {
    this.applyState(
      {
        data: { ...this.state.data, ...data },
        currentStep: this.state.currentStep,
      },
      false
    );
  }

  goBack(data?: Partial<T>): void {
    const { undoStack, redoStack } = this.state;
    const prevState = undoStack.pop();
    if (!prevState) {
      throw new Error('Undo stack is empty');
    }
    redoStack.push({
      currentStep: this.state.currentStep,
      data: data || this.state.data,
    });
    this.applyState(prevState);
  }

  goNext(data: Partial<T>, targetIndex?: number): void {
    const { undoStack, redoStack } = this.state;

    const currentState = {
      data,
      currentStep: this.state.currentStep,
    };
    undoStack.push(currentState);

    const newStep = targetIndex != null ? targetIndex : this.state.currentStep + 1;
    const redoPop = redoStack.pop();
    if (redoPop && isEqual(this.state.initial, currentState) && redoPop.currentStep === newStep) {
      this.applyState(redoPop);
    } else {
      redoStack.splice(0, redoStack.length);
      this.applyState({
        currentStep: newStep,
        data,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jumpToComponent(data: Partial<T>, targetStepIndex: number): void {
    const { undoStack, redoStack } = this.state;
    const stateStack = [...undoStack, ...redoStack];
    undoStack.splice(0, undoStack.length);
    redoStack.splice(0, redoStack.length);
    const updatedData = { ...data, qualifyingSites: [], waitList: [] };
    stateStack.forEach((s) => {
      if (s.currentStep === targetStepIndex) {
        this.applyState({
          currentStep: targetStepIndex,
          data: updatedData,
        });
      } else if (s.currentStep < targetStepIndex) {
        undoStack.push(s);
      } else {
        redoStack.push(s);
      }
    });

    if (stateStack.length === targetStepIndex) {
      this.applyState({
        currentStep: targetStepIndex,
        data: updatedData,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jumpTo(data: Partial<T>, component: React.ComponentType<any>): void {
    const targetStepIndex = this.props.steps.findIndex((c) => c === component);
    const currentStep = this.state.currentStep;
    if (targetStepIndex > currentStep) {
      this.goNext(data, targetStepIndex);
    } else {
      console.error('JumpTo cannot jump backwards. Doing goNext to next step');
      this.goNext(data);
    }
  }

  render() {
    return (
      <>
        {this.props.children({
          manager: this,
          data: this.state.data,
          stepIndex: this.state.currentStep,
          initialData: this.props.initialData,
        })}
      </>
    );
  }

  private applyState(state: IFlowState<T>, updateInitial = true) {
    this.setState(
      {
        initial: updateInitial ? state : this.state.initial,
        ...state,
      },
      () => {
        if (!this.state.data) {
          return;
        }

        this.props.message$.next({
          type: 'newApplicationResponseModel',
          payload: { ...this.state.data },
        });
      }
    );
  }
}

export const FlowManager = <T extends {}>(props: IOwnProps<T>) => {
  const messageBus = useMessageBus();

  return <FlowManagerComponent {...props} message$={messageBus} />;
};
